import { styled } from '@f8n-frontend/stitches';
import { Fragment, ReactNode } from 'react';

import { abbreviateValue } from 'utils/formatters';

import FrameGrid from './FrameGrid';
import Pulse from './Pulse';
import Badge from './base/Badge';
import Body from './base/Body';
import Flex from './base/Flex';
import { H2Heading } from './base/Heading';
import Skeleton from './base/Skeleton';

type WorldGridSectionProps = {
  liveCount: number;
  cta?: ReactNode;
  heading: string;
  children: ReactNode;
};

function WorldGridSection(props: WorldGridSectionProps) {
  const { liveCount, heading, children, cta } = props;
  return (
    <WorldGridSectionRoot>
      <Body>
        <Flex css={{ alignItems: 'center', gap: '$3', paddingBottom: '$6' }}>
          <H2Heading size={5} weight="medium">
            {heading}
          </H2Heading>
          {liveCount > 0 && (
            <Badge variant="outline">
              <Pulse color="$live" />
              {abbreviateValue(liveCount)}
              {` `}
              live
            </Badge>
          )}
        </Flex>
        {children}
        {cta && <CtaWrapper>{cta}</CtaWrapper>}
      </Body>
    </WorldGridSectionRoot>
  );
}

type WorldGridSectionSkeletonProps = {
  heading: WorldGridSectionProps['heading'];
  maxColumns: 3 | 4;
  itemSkeleton: ReactNode;
};

function WorldGridSectionSkeleton(props: WorldGridSectionSkeletonProps) {
  const { heading, maxColumns, itemSkeleton } = props;

  return (
    <WorldGridSectionRoot>
      <Body>
        <Flex css={{ alignItems: 'center', gap: '$3', paddingBottom: '$6' }}>
          <Skeleton.Text>
            <H2Heading size={5} weight="medium">
              {heading}
            </H2Heading>
          </Skeleton.Text>
          <Skeleton.Block
            css={{ width: 60, height: 20, borderRadius: '$round' }}
            variant="outline"
          />
        </Flex>
        <FrameGrid.Root maxColumns={maxColumns}>
          {[...Array(maxColumns)].map((_, index) => (
            <Fragment key={index}>{itemSkeleton}</Fragment>
          ))}
        </FrameGrid.Root>
      </Body>
    </WorldGridSectionRoot>
  );
}

WorldGridSection.Skeleton = WorldGridSectionSkeleton;
export default WorldGridSection;

const WorldGridSectionRoot = styled('section', {
  paddingY: '$8',
  borderBottom: '1px solid $black5',

  '@bp1': {
    paddingY: '$9',

    '&:first-of-type': {
      paddingTop: '$8',
    },
  },
});

const CtaWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '$8',
});
