import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';

import * as Generated from 'gql/api/queries/world-drops.generated';
import { apiPaginator } from 'utils/react-query';

import { ApiPaginationPageParam } from 'types/Api';
import { FetcherReturnType } from 'types/utils';

async function getDropsByWorld(variables: Generated.ApiWorldDropsVariables) {
  const fetchDropsByWorld = Generated.useWorldDrops.fetcher(variables);
  const response = await fetchDropsByWorld();

  if (!response) {
    throw new Error('No drops found');
  }

  return response.worldDrops;
}

export type DropsByWorld = FetcherReturnType<typeof getDropsByWorld>;

const getDropsByWorldQueryKey = (
  variables: Generated.ApiWorldDropsVariables
) => {
  return ['drops-by-world', variables];
};

export default function useDropsByWorld(
  variables: Generated.ApiWorldDropsVariables,
  options: Pick<
    UseInfiniteQueryOptions<
      DropsByWorld,
      Error,
      DropsByWorld,
      DropsByWorld,
      ReturnType<typeof getDropsByWorldQueryKey>,
      ApiPaginationPageParam
    >,
    'enabled' | 'refetchOnWindowFocus' | 'placeholderData'
  > = {}
) {
  return useInfiniteQuery({
    ...options,
    queryKey: getDropsByWorldQueryKey(variables),
    queryFn: ({ pageParam }) => {
      return getDropsByWorld({ ...variables, page: pageParam.page });
    },
    initialPageParam: apiPaginator.initialPageParam,
    getNextPageParam: (lastPage) => {
      return apiPaginator.getNextPageParam(lastPage.drops);
    },
  });
}
