import { EllipsisIcon, SwapIcon, TrashIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';

import { createClassSelector } from 'utils/styles';

import Button from './base/Button';
import DropdownMenu from './base/DropdownMenu';

export type ContentBlockProps = {
  children: React.ReactNode;
  isEditable?: boolean;
  onEdit: () => void;
  onRemove: () => void;
};

export const HOVER_BUTTON_CLASSNAME = 'hover-button';
export const HOVER_BUTTON_SELECTOR = createClassSelector(
  HOVER_BUTTON_CLASSNAME
);

const TOUCH_BUTTON_CLASSNAME = 'touch-button';
const TOUCH_BUTTON_SELECTOR = createClassSelector(TOUCH_BUTTON_CLASSNAME);

function ContentBlock(props: ContentBlockProps) {
  return (
    <ContentBlockWrapper isEditable={props.isEditable}>
      <ContentBlockControls>
        <ControlButton
          className={HOVER_BUTTON_CLASSNAME}
          onClick={props.onEdit}
        >
          Edit
        </ControlButton>
        <ControlButton
          className={HOVER_BUTTON_CLASSNAME}
          icon="standalone"
          onClick={props.onRemove}
        >
          <TrashIcon />
        </ControlButton>
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
            <ControlButton className={TOUCH_BUTTON_CLASSNAME} icon="standalone">
              <EllipsisIcon />
            </ControlButton>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content align="end" side="bottom">
              <DropdownMenu.Item type="button" onClick={props.onEdit} icon>
                <SwapIcon />
                Edit
              </DropdownMenu.Item>
              <DropdownMenu.Item type="button" onClick={props.onRemove} icon>
                <TrashIcon />
                Remove
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </ContentBlockControls>
      {props.children}
    </ContentBlockWrapper>
  );
}

export const ContentBlockControls = styled('div', {
  position: 'absolute',
  right: -6,
  top: 0,
  display: 'flex',
  gap: '$1',
  transform: 'translateY(-50%)',
  zIndex: 1,
});

export const ControlButton = styled(Button);
ControlButton.defaultProps = {
  size: 0,
  variant: 'secondary',
};

export const ContentBlockWrapper = styled('div', {
  borderRadius: '$3',
  backgroundColor: 'transparent',
  border: '1px solid transparent',
  transition: 'all $2 $ease',
  position: 'relative',

  outline: 'none',

  [HOVER_BUTTON_SELECTOR]: {
    opacity: 0,
    transition: 'opacity $2 $ease',
  },

  '&:focus': {
    backgroundColor: '$black2',
    borderColor: '$black5',
  },

  variants: {
    isFocused: {
      true: {
        backgroundColor: '$black2',
        borderColor: '$black5',
      },
    },

    isEditable: {
      true: {
        '@hover': {
          $$spacing: '$space$6',
          margin: '-$$spacing',
          padding: '$$spacing',

          [TOUCH_BUTTON_SELECTOR]: {
            display: 'none',
          },

          [`${ContentBlockControls}`]: {
            right: '$$spacing',
          },

          '&:hover': {
            backgroundColor: '$black2',
            borderColor: '$black5',

            [HOVER_BUTTON_SELECTOR]: {
              opacity: 1,
            },
          },
        },

        '@noHover': {
          [HOVER_BUTTON_SELECTOR]: {
            display: 'none',
          },
        },
      },
      false: {
        [TOUCH_BUTTON_SELECTOR]: {
          display: 'none',
        },
        [HOVER_BUTTON_SELECTOR]: {
          display: 'none',
        },
      },
    },
  },
});

const ContentBlockEmpty = styled('div', {
  backgroundColor: '$black2',
  border: '1px solid $black5',
  borderRadius: '$3',
  padding: '$7',
});

ContentBlock.Empty = ContentBlockEmpty;
export default ContentBlock;
