import { CheckIcon, LockIcon, RemoveIcon, WarningIcon } from '@f8n/icons';
import { useMutation } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import { ReactNode } from 'react';
import { useLocation } from 'react-use';

import Box from 'components/base/Box';
import Button from 'components/base/Button';
import Flex from 'components/base/Flex';
import InputField from 'components/base/InputField';
import RadioGroup from 'components/base/RadioGroup';
import Text from 'components/base/Text';

import { useModerateWorld } from 'gql/api/mutations/moderate-world.generated';
import { getQueryDataSetterForQueryKey } from 'hooks/queries/shared';
import { setWorldModerationAirtableProxy } from 'queries/admin/world';
import { worldCache } from 'utils/cache';

import { ModerationStatus } from 'types/Moderation';
import { World } from 'types/World';
import { Maybe } from 'types/utils';

interface ModerateWorldProps {
  world: World;
  currentUserPublicKey: string;
}

type ModerateWorldFormValues = {
  moderationStatus: ModerationStatus;
  moderationFrom?: Maybe<string>;
  id: number;
};

export default function ModerateWorldPane(props: ModerateWorldProps) {
  const { world, currentUserPublicKey } = props;
  const { id, moderationFrom, moderationStatus } = world;

  const setWorld = getQueryDataSetterForQueryKey<World>(
    worldCache.bySlug(world.slug)
  );
  const { href } = useLocation();
  const moderateWorld = useModerateWorld();

  const { mutateAsync: moderationStatusMutation } = useMutation({
    mutationFn: setWorldModerationAirtableProxy,
  });

  const handleSetStatus = async (values: ModerateWorldFormValues) => {
    if (
      window.confirm(
        `Are you sure you want to change the status of this gallery to: ${values.moderationStatus}?`
      )
    ) {
      await moderateWorld.mutateAsync({
        moderationStatus: values.moderationStatus,
        moderationFrom: values.moderationFrom,
        id,
      });
      await moderationStatusMutation({
        id,
        moderationStatus: values.moderationStatus,
        adminAddress: currentUserPublicKey,
        url: href ?? '',
      });

      // Update local cache
      setWorld(() => ({
        ...world,
        ...values,
      }));
    }
  };

  const isLoading = moderateWorld.isPending;

  return (
    <Box>
      <Formik<ModerateWorldFormValues>
        initialValues={{
          moderationStatus,
          moderationFrom,
          id,
        }}
        onSubmit={handleSetStatus}
      >
        <Form>
          <ModerationStatusOptions />
          <Button
            size={0}
            disabled={isLoading}
            type="submit"
            variant="danger"
            css={{ marginTop: '$6', width: '100%' }}
          >
            {isLoading ? 'Saving…' : 'Save'}
          </Button>
        </Form>
      </Formik>
    </Box>
  );
}

function ModerationStatusOptions() {
  const { setFieldValue, initialValues } =
    useFormikContext<ModerateWorldFormValues>();

  return (
    <RadioGroup.Root
      defaultValue={initialValues.moderationStatus}
      aria-label="Moderation status"
      onValueChange={(value) => {
        setFieldValue('moderationStatus', value);
      }}
    >
      <Option
        name="moderationStatus"
        value="ACTIVE"
        title="Public"
        icon={<CheckIcon size={0} />}
        description="This page will be publicly viewable across Foundation. This is the
default state for pages on Foundation."
      />
      <Option
        name="moderationStatus"
        value="UNDER_REVIEW"
        title="Under review"
        icon={<WarningIcon size={0} />}
        description=" This page will be taken down and replaced with an ‘Under Review’
message. It will only be accessible via direct link."
      />
      <Option
        name="moderationStatus"
        icon={<LockIcon size={0} />}
        value="TAKEDOWN_REQUESTED"
        title="DMCA Complaint"
        description="This page will be taken down and replaced with a DMCA
message. It will only be accessible via direct link."
      />
      <Option
        name="moderationStatus"
        value="SUSPENDED"
        title="Remove"
        icon={<RemoveIcon size={0} />}
        description="
This page will be taken down and replaced with a ‘Permanently
Removed’ message. It will only be accessible via direct link.
"
      />
    </RadioGroup.Root>
  );
}

interface OptionProps {
  name: string;
  value: string;
  title: string;
  description: string;
  icon: ReactNode;
}

function Option(props: OptionProps) {
  const { name, value, title, description, icon } = props;
  const { setFieldValue } = useFormikContext();

  return (
    <Box
      as="label"
      htmlFor={value}
      css={{
        display: 'block',
        paddingBottom: '$6',
        cursor: 'pointer',
        '&:not(:last-child)': {
          borderBottom: '1px solid $black10',
          marginBottom: '$6',
        },
      }}
    >
      <Flex css={{ marginBottom: '$3', gap: '$6', alignItems: 'flex-start' }}>
        <Box css={{ width: 24 }}>
          <RadioGroup.Radio value={value} id={value} name={name} />
        </Box>
        <Flex css={{ flexDirection: 'column', gap: '$2' }}>
          <Flex css={{ alignItems: 'center', gap: '$2' }}>
            {icon}
            <Text size={2} weight="semibold">
              {title}
            </Text>
          </Flex>
          <Text color="dim" size={1}>
            {description}
          </Text>
          {value === 'TAKEDOWN_REQUESTED' && (
            <Box css={{ marginTop: '$3' }}>
              <InputField
                label="Complaint submitted by"
                id="moderationFrom"
                placeholder=""
                size={0}
                type="text"
                error={undefined}
                touched={false}
                onChange={(e) =>
                  setFieldValue('moderationFrom', e.target.value)
                }
              />
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}
