import Footer from 'components/footers/Footer';
import Header from 'components/headers/Header';

import { PageColorMode, PageType } from 'types/page';

import PageHead from './PageHead';

interface PageProps {
  children: React.ReactNode;
  headerMode?: PageColorMode;
  url?: string;
  description?: string;
  title?: string | null;
  absolute?: boolean;
  isLight?: boolean;
  image?: string;
  type?: PageType | undefined;
  noHeader?: boolean;
  noFooter?: boolean;
  footerMode?: PageColorMode;
}

/** @deprecated because this component is overloaded. */
export default function Page(props: PageProps) {
  const {
    headerMode = 'LIGHT',
    children,
    absolute = false,
    description,
    title,
    image,
    type,
    noHeader = false,
    noFooter = false,
    footerMode,
  } = props;

  return (
    <>
      <PageHead description={description} title={title} image={image} />
      {!noHeader && (
        <Header
          absolute={absolute}
          mode={headerMode}
          type={type || 'MINIMAL'}
        />
      )}
      {children}
      {!noFooter && <Footer footerMode={footerMode} type={type} />}
    </>
  );
}
