import Flex from 'components/base/Flex';
import Skeleton from 'components/base/Skeleton';
import { MarketAvailabilityContainer } from 'components/filters/MarketAvailabilityFilter';

type FiltersSkeletonProps = {
  buttons?: 1 | 4;
};

export default function FiltersSkeleton(props: FiltersSkeletonProps) {
  const { buttons = 4 } = props;

  return (
    <Flex
      css={{
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <MarketAvailabilityContainer>
        <Skeleton.Button css={{ width: 133 }} size={0} variant="fill" />
        {buttons === 4 && (
          <>
            <Skeleton.Button css={{ width: 109 }} size={0} variant="fill" />
            <Skeleton.Button css={{ width: 145 }} size={0} variant="fill" />
            <Skeleton.Button css={{ width: 138 }} size={0} variant="fill" />
          </>
        )}
      </MarketAvailabilityContainer>
      <Skeleton.Button css={{ width: 158 }} size={0} variant="fill" />
    </Flex>
  );
}
