import { styled } from '@f8n-frontend/stitches';
import { format, parse, parseJSON } from 'date-fns';

import Box from 'components/base/Box';
import Heading from 'components/base/Heading';
import InfiniteScrollButton from 'components/feed/InfiniteScrollButton';
import { MomentCard } from 'components/moments/MomentCard';

import {
  useInfiniteMomentsByExhibition,
  ApiMomentsByExhibition,
} from 'gql/api/queries/moments-by-exhibition.generated';
import { extractNestedPaginatedData, apiPaginator } from 'utils/react-query';

const dateFormat = 'yyyy-MM';

type ApiMomentByExhibition = ApiMomentsByExhibition['moments']['items'][number];

type GroupedMoment = Record<string, ApiMomentByExhibition[]>;

const groupMomentsByMonths = (
  moments: ApiMomentByExhibition[]
): GroupedMoment => {
  return moments.reduce((acc, moment) => {
    const date = parseJSON(moment.startsAt);

    const key = format(date, dateFormat);

    return {
      ...acc,
      [key]: [...(acc[key] || []), moment],
    };
  }, {} as GroupedMoment);
};

type MomentsTabProps = {
  momentsCount: number;
  world: {
    id: number;
    slug: string;
  };
};

export function MomentsTab(props: MomentsTabProps) {
  const { world, momentsCount } = props;

  const momentsByExhibition = useInfiniteMomentsByExhibition(
    { exhibitionId: world.id, perPage: 12 },
    {
      getNextPageParam: (lastPage) => {
        return apiPaginator.getNextPageParam(lastPage.moments);
      },
      initialPageParam: apiPaginator.initialPageParam,
    }
  );

  if (momentsByExhibition.isLoading) {
    return (
      <Box
        css={{
          paddingTop: '$7',
        }}
      >
        <MomentByMonthContainer>
          <Box>
            <Heading
              size={{ '@initial': 3, '@bp1': 5 }}
              weight="medium"
              css={{
                textIndent: '-9999px',
                backgroundColor: '$black5',
                maxWidth: 120,
                borderRadius: '$1',
              }}
            >
              —
            </Heading>
          </Box>
          <MomentsInMonthContainer>
            {Array.from({ length: Math.min(momentsCount, 8) }).map(
              (_, index) => {
                return <MomentCard.Skeleton key={index} />;
              }
            )}
          </MomentsInMonthContainer>
        </MomentByMonthContainer>
      </Box>
    );
  }

  if (!momentsByExhibition.isSuccess) {
    return null;
  }

  const moments = extractNestedPaginatedData(
    momentsByExhibition.data,
    'moments'
  );

  if (moments.totalItemsCount === 0) {
    return null;
  }

  const momentsGroupedByMonth = groupMomentsByMonths(moments.items);

  return (
    <>
      <CalendarContainer>
        {Object.keys(momentsGroupedByMonth).map((key) => {
          const parsedDate = parse(key, dateFormat, Date.now());

          const items = momentsGroupedByMonth[key];

          /**
           * this should never happen, but if it does, we don't want to render
           */
          if (!items) return null;

          return (
            <MomentByMonthContainer key={key}>
              <Heading size={{ '@initial': 3, '@bp1': 5 }} weight="medium">
                {format(parsedDate, 'MMMM')}
              </Heading>

              <MomentsInMonthContainer>
                {items.map((moment) => {
                  return (
                    <MomentCard
                      key={moment.id}
                      moment={moment}
                      world={world}
                      contributors={moment.creators.items.slice(0, 5)}
                      contributorsCount={moment.creators.totalItems}
                    />
                  );
                })}
              </MomentsInMonthContainer>
            </MomentByMonthContainer>
          );
        })}
      </CalendarContainer>
      {/* Infinite scroll is outside of contaner, to make :last-of-type styling selectors work */}
      <InfiniteScrollButton
        handleNextPage={momentsByExhibition.fetchNextPage}
        hasNextPage={momentsByExhibition.hasNextPage}
        isFetching={momentsByExhibition.isFetching}
      />
    </>
  );
}

const CalendarContainer = styled('div', {
  paddingTop: '$7',
  marginBottom: '$7',
});

const MomentByMonthContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$5',
  paddingBottom: '$6',
  marginBottom: '$6',
  borderBottom: 'solid 1px $black5',

  '&:last-child': {
    marginBottom: 0,
    paddingBottom: 0,
    borderBottom: 'none',
  },

  '@bp1': {
    display: 'grid',
    gap: '$6',
    gridTemplateColumns: '1fr 3fr',
    paddingBottom: '$7',
    marginBottom: '$7',
  },
});

const MomentsInMonthContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$3',
});
