import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import * as Generated from 'gql/api/queries/exhibition-featured-items.generated';
import { getFirstValue } from 'utils/helpers';

import { FetcherReturnType } from 'types/utils';

type Variables = Generated.ApiExhibitionFeaturedItemsVariables;

async function getWorldShowcase(variables: Variables) {
  const fetchWorldShowcaseItem =
    Generated.useExhibitionFeaturedItems.fetcher(variables);
  const response = await fetchWorldShowcaseItem();

  return getFirstValue(response.exhibitionFeaturedItems.items);
}

export type WorldShowcaseItem = FetcherReturnType<typeof getWorldShowcase>;

export default function useWorldShowcaseItem(
  variables: Variables,
  options: Pick<
    UseQueryOptions<WorldShowcaseItem, Error>,
    'placeholderData' | 'refetchOnWindowFocus'
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: ['world-featured-item', variables],
    queryFn: () => getWorldShowcase(variables),
  });
}
