import { styled } from '@f8n-frontend/stitches';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { match } from 'ts-pattern';
import type { Address } from 'viem';

import Box from 'components/base/Box';
import Heading from 'components/base/Heading';
import Modal from 'components/base/Modal';
import Tabs from 'components/base/Tabs';
import VirtualisedUserListEmptyState from 'components/modals/virtualized/VirtualisedUserListEmptyState';
import VirtualisedUserListLoading from 'components/modals/virtualized/VirtualisedUserListLoading';
import VirtualizedUserList from 'components/modals/virtualized/VirtualizedUserList';
import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import useTrackView from 'hooks/analytics/use-track-view';
import useWorldCollectors from 'hooks/queries/api/use-world-collectors';
import useWorldCreators from 'hooks/queries/api/use-world-creators';
import { useTabbedModal } from 'hooks/use-tabbed-modal';
import { formatNumber } from 'utils/formatters';

export type WorldRelatedWalletsModalTab = 'creators' | 'collectors';

export function useWorldRelatedWalletsModal(
  initialTab: WorldRelatedWalletsModalTab
) {
  return useTabbedModal<WorldRelatedWalletsModalTab>(initialTab);
}

type WorldRelatedWalletsModalProps = {
  creatorsCount: number;
  collectorsCount: number;
  open: boolean;
  tab: WorldRelatedWalletsModalTab;
  onClose(): void;
  onChangeTab(tab: WorldRelatedWalletsModalTab): void;
  id: number;
  slug: string;
  takeRateInBasisPoints: number;
};

const Wrapper = styled(Box, {
  marginX: '$3',
  marginBottom: '$3',
  paddingTop: '$4',
  paddingBottom: '$5',
  borderBottom: '1px solid $black5',
});

export default function WorldRelatedWalletsModal(
  props: WorldRelatedWalletsModalProps
) {
  const {
    tab,
    open,
    creatorsCount,
    collectorsCount,
    onChangeTab,
    onClose,
    id,
  } = props;

  const trackView = useTrackView();

  useEffect(() => {
    if (!open) return;

    match({ tab })
      .with({ tab: 'creators' }, () => {
        trackView({ name: 'viewed_world_creators' });
      })
      .with({ tab: 'collectors' }, () => {
        trackView({ name: 'viewed_world_collectors' });
      });
  }, [open, tab]);

  const auth = useAuth();

  const currentUserPublicKey = hasPublicKey(auth) ? auth.publicKey : null;

  const closeModal = () => {
    onClose();
  };

  return (
    <Modal.Root
      open={open}
      onOpenChange={(open) => {
        if (open === false) {
          closeModal();
        }
      }}
    >
      <Tabs.Root
        value={tab || undefined}
        onValueChange={(value) =>
          onChangeTab(value as WorldRelatedWalletsModalTab)
        }
      >
        <Modal.Content
          header={
            <Modal.Header>
              <Tabs.List>
                <Tabs.Trigger value="creators">
                  Creators{' '}
                  <Tabs.Count>{formatNumber(creatorsCount)}</Tabs.Count>
                </Tabs.Trigger>
                <Tabs.Trigger value="collectors">
                  Collectors{' '}
                  <Tabs.Count>{formatNumber(collectorsCount)}</Tabs.Count>
                </Tabs.Trigger>
              </Tabs.List>
            </Modal.Header>
          }
          size={0}
          height="normal"
          footer={null}
        >
          <Tabs.Content value="creators">
            <CreatorsTab
              id={id}
              enabled={open}
              currentUserPublicKey={currentUserPublicKey}
            />
          </Tabs.Content>
          <Tabs.Content value="collectors">
            <CollectorsTab
              id={id}
              enabled={open}
              currentUserPublicKey={currentUserPublicKey}
            />
          </Tabs.Content>
        </Modal.Content>
      </Tabs.Root>
    </Modal.Root>
  );
}

interface UserTabProps {
  id: number;
  enabled: boolean;
  currentUserPublicKey: Address | null;
}

function CreatorsTab(props: UserTabProps) {
  const { id, enabled, currentUserPublicKey } = props;

  const {
    data,
    fetchNextPage,
    isLoading,
    hasNextPage = false,
    isFetchingNextPage,
  } = useWorldCreators(
    {
      id,
      currentUserPublicKey,
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    }
  );

  const queryClient = useQueryClient();

  if (isLoading || !data) {
    return <VirtualisedUserListLoading />;
  }

  const users = data.pages.flatMap((page) => page.items);

  if (users.length === 0) {
    return <NoCreators />;
  }

  return (
    <VirtualizedUserList
      title={
        <Wrapper>
          <Heading>Creators of NFTs in this gallery</Heading>
        </Wrapper>
      }
      users={users}
      onFollowUpdate={() => {
        queryClient.invalidateQueries({
          queryKey: useWorldCreators.getKey({ id }),
        });
      }}
      handleNextPage={() => fetchNextPage()}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
    />
  );
}

function CollectorsTab(props: UserTabProps) {
  const { id, enabled, currentUserPublicKey } = props;
  const {
    data,
    fetchNextPage,
    isLoading,
    hasNextPage = false,
    isFetchingNextPage,
  } = useWorldCollectors(
    {
      id,
      currentUserPublicKey,
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    }
  );

  const queryClient = useQueryClient();

  if (isLoading || !data) {
    return <VirtualisedUserListLoading />;
  }

  const users = data.pages.flatMap((page) => page.items);

  if (users.length === 0) {
    return <NoCollectors />;
  }

  return (
    <VirtualizedUserList
      title={
        <Wrapper>
          <Heading>Minters and collectors of NFTs in this gallery</Heading>
        </Wrapper>
      }
      users={users}
      onFollowUpdate={() => {
        queryClient.invalidateQueries();
      }}
      handleNextPage={() => fetchNextPage()}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
    />
  );
}

function NoCreators() {
  return (
    <VirtualisedUserListEmptyState
      heading="No listings yet"
      subheading="Once a listing has been made in the gallery, the creator will appear in this list."
    />
  );
}

function NoCollectors() {
  return (
    <VirtualisedUserListEmptyState
      heading="No collectors yet"
      subheading="Once an NFT is minted or purchased, the collector will appear in this list."
    />
  );
}
