import { isPast, parseJSON } from 'date-fns';

import { formatDateTime } from 'utils/dates/dates';

import { PreviewMediaAsset } from 'types/media';

import { useFrameGridContext } from './FrameGrid';
import FrameGridItem, { FrameGridItemSelectableConfig } from './FrameGridItem';
import FrameGridItemDetails from './FrameGridItemDetails';
import Media from './Media';
import Pulse from './Pulse';
import Box from './base/Box';
import { EventDate } from './base/EventDate';
import Flex from './base/Flex';
import Text from './base/Text';

type ManageWorldMomentGridItemProps = {
  media: PreviewMediaAsset | null;
  startsAt: string;
  name: string;
  href?: string;
};

/** @deprecated migrate to using MomentGridItem instead */
function ManageWorldMomentGridItemBase(props: ManageWorldMomentGridItemProps) {
  const { media, startsAt, name, href } = props;

  const startsAtDate = parseJSON(startsAt);
  const isOpen = isPast(startsAtDate);

  const getMedia = () => {
    if (!media) {
      return null;
    }

    return (
      <Box>
        <Box css={{ position: 'absolute', padding: '$2' }}>
          <EventDate color="ghost" timestamp={startsAt} />
        </Box>
        <Media
          css={{
            aspectRatio: '1/1',
          }}
          src={media.src}
        />
      </Box>
    );
  };

  return (
    <FrameGridItem
      href={href}
      media={getMedia()}
      details={
        <MomentGridItemDetails
          isOpen={isOpen}
          name={name}
          startsAtDate={startsAtDate}
        />
      }
    />
  );
}

function MomentGridItemDetails(props: {
  isOpen: boolean;
  name: string;
  startsAtDate: Date;
}) {
  const { isOpen, name, startsAtDate } = props;
  const frameGridContext = useFrameGridContext();
  const isSelectableGrid = frameGridContext.type === 'SELECTABLE';

  return (
    <FrameGridItemDetails.Root
      css={{ gap: '$1' }}
      isSelectableGrid={isSelectableGrid}
    >
      <FrameGridItemDetails.Heading>{name}</FrameGridItemDetails.Heading>
      <FrameGridItemDetails.StatusText>
        {isOpen ? (
          <Flex css={{ alignItems: 'center', gap: '$1' }}>
            <Pulse />
            Open now
          </Flex>
        ) : (
          <Text color="dim">{formatDateTime(startsAtDate)}</Text>
        )}
      </FrameGridItemDetails.StatusText>
    </FrameGridItemDetails.Root>
  );
}

/** @deprecated migrate to using MomentGridItem instead */
function ManageWorldMomentGridItemSelectable(
  props: Omit<ManageWorldMomentGridItemProps, 'href'> &
    FrameGridItemSelectableConfig
) {
  return (
    <FrameGridItem.Selectable {...props}>
      <ManageWorldMomentGridItemBase {...props} />
    </FrameGridItem.Selectable>
  );
}

/** @deprecated migrate to using MomentGridItem instead */
const ManageWorldMomentGridItem = Object.assign(ManageWorldMomentGridItemBase, {
  Selectable: ManageWorldMomentGridItemSelectable,
});

export default ManageWorldMomentGridItem;
