import { useQueryClient } from '@tanstack/react-query';
import { useSignMessage } from 'wagmi';

import { AuthConnected } from 'contexts/auth/types';

import useConnectWalletSession from 'hooks/web3/wallet/use-connect-wallet-session';
import report from 'lib/report';
import { connectedUserCache, userCacheKey } from 'utils/cache';
import { isAnyTrue } from 'utils/helpers';

import { AuthToken } from 'types/auth';

/**
 * Warning: The backend depends on this exact string being signed in the wallet.
 * Be careful when changing this.
 */
const MESSAGE_TO_SIGN_DO_NOT_CHANGE = `Please sign this message to connect to Foundation.`;

export default function useSignAuthMessage(props: {
  auth: AuthConnected;
  onSign?: () => void;
}) {
  const { auth, onSign } = props;

  const queryClient = useQueryClient();
  const signMessage = useSignMessage();

  const connectWalletSession = useConnectWalletSession({
    onSuccess: async (data) => {
      // After the user connects and signs for the first time we store the token data in the cache
      queryClient.setQueryData(connectedUserCache.authToken, data.token);
      /**
       * refetch the connected user so that useAuth has a user in its cache
       * this avoids getting state === 'unknown'
       */
      await queryClient.refetchQueries({
        queryKey: userCacheKey.byPublicKey(auth.publicKey),
      });
    },
  });

  const requestSignature = async () => {
    try {
      const message = MESSAGE_TO_SIGN_DO_NOT_CHANGE;
      const signature = await signMessage.signMessageAsync({ message });

      // If there is no signature because of rejecting sign return early
      if (!signature) {
        return;
      }

      const token: AuthToken = `${btoa(message)}.${signature}`;

      await connectWalletSession.mutateAsync({
        token,
        connectorId: auth.connectorId || 'unknown',
      });

      if (onSign) {
        onSign();
      }
    } catch (error) {
      report(error);
    }
  };

  const isSigning = isAnyTrue([
    signMessage.isPending,
    connectWalletSession.isPending,
  ]);

  return {
    requestSignature,
    isSigning,
  };
}
