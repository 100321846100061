import { keepPreviousData } from '@tanstack/react-query';

import Empty from 'components/Empty';
import FrameGrid from 'components/FrameGrid';
import FrameGridInfiniteSkeletonLoader from 'components/FrameGridInfiniteSkeletonLoader';
import NftGridItem from 'components/NftGridItem';
import Box from 'components/base/Box';
import Flex from 'components/base/Flex';
import NftsSort from 'components/filters/NftsSort';
import MarketAvailabilityFilter from 'components/filters/api/MarketAvailabilityFilter';
import FilterLayout from 'components/profiles/tabs/FilterLayout';
import FiltersSkeleton from 'components/profiles/tabs/FiltersSkeleton';

import { useNftFilters } from 'hooks/filters/use-nft-filters';
import useWorldListings from 'hooks/queries/api/use-world-listings';
import { getFirstValue, notEmptyOrNil } from 'utils/helpers';
import { mapApiMediaToPreviewMediaAsset } from 'utils/media-preview';
import { extractNestedPaginatedData } from 'utils/react-query';

type ListingsTabProps = {
  listingsCount: number;
  worldId: number;
};

const MAX_COLUMNS = 4;

export default function ListingsTab(props: ListingsTabProps) {
  const { worldId: id, listingsCount } = props;
  const LISTINGS_TAB_CACHE_KEY = `LISTINGS_TAB_${id}`;

  const { filters, resetMaketAvailabilityFilter } = useNftFilters(
    LISTINGS_TAB_CACHE_KEY
  );

  const listingsQuery = useWorldListings(
    {
      id,
      marketAvailability: filters?.marketAvailability || null,
      sort: filters?.sortOrder || null,
      accountAddress: filters.creatorPublicKey,
      perPage: 24,
    },
    {
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
    }
  );

  if (listingsQuery.isLoading) {
    return (
      <FilterLayout filters={<FiltersSkeleton buttons={1} />}>
        <FrameGrid.Root maxColumns={4}>
          {Array.from({ length: Math.min(listingsCount, 8) }).map(
            (_, index) => {
              return <NftGridItem.Skeleton key={index} />;
            }
          )}
        </FrameGrid.Root>
      </FilterLayout>
    );
  }

  if (!listingsQuery.isSuccess) {
    return null;
  }

  const { items: nfts, totalItemsCount } = extractNestedPaginatedData(
    listingsQuery.data,
    'nfts'
  );

  const firstPage = getFirstValue(listingsQuery.data.pages);

  const marketAvailabilityCounts = firstPage?.marketAvailabilityCounts;

  return (
    <FilterLayout
      filters={
        <Flex
          css={{
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '$2',
          }}
        >
          {marketAvailabilityCounts ? (
            <MarketAvailabilityFilter
              cacheKey={LISTINGS_TAB_CACHE_KEY}
              counts={marketAvailabilityCounts}
              variant="world"
            />
          ) : null}
          <Box css={{ marginLeft: 'auto' }}>
            <NftsSort cacheKey={LISTINGS_TAB_CACHE_KEY} />
          </Box>
        </Flex>
      }
    >
      {notEmptyOrNil(nfts) ? (
        <>
          <FrameGrid.Root maxColumns={MAX_COLUMNS}>
            {nfts.map((nft) => {
              const media = nft.media
                ? mapApiMediaToPreviewMediaAsset(nft.media)
                : null;

              const optimizedMedia = media
                ? NftGridItem.optimizeMedia(media, {
                    maxColumns: MAX_COLUMNS,
                  })
                : null;

              return (
                <NftGridItem
                  key={nft.id}
                  nft={nft}
                  media={optimizedMedia}
                  filterContext={filters?.marketAvailability || undefined}
                />
              );
            })}

            {listingsQuery.hasNextPage && (
              <FrameGridInfiniteSkeletonLoader
                fetchNextPage={listingsQuery.fetchNextPage}
                loadedItems={nfts.length}
                maxColumns={MAX_COLUMNS}
                skeleton={<NftGridItem.Skeleton />}
                totalItems={totalItemsCount}
              />
            )}
          </FrameGrid.Root>
        </>
      ) : (
        <Empty.WithFilters
          message="Adjust the selected filters to see more NFTs."
          onClear={() => resetMaketAvailabilityFilter()}
        />
      )}
    </FilterLayout>
  );
}
