import { ComponentProps } from '@stitches/react';

import Button, { ButtonProps } from 'components/base/Button';
import DropdownMenu from 'components/base/DropdownMenu';
import IconV2 from 'components/base/IconV2';

import Meatballs from 'assets/icons/meatballs.svg';

type ContentProps = ComponentProps<typeof DropdownMenu.Content>;

type MeatballDropdownProps = {
  children: React.ReactNode;
  align?: ContentProps['align'];
  side?: ContentProps['side'];
  buttonCss?: ButtonProps['css'];
  buttonSize?: ButtonProps['size'];
  buttonVariant?: ButtonProps['variant'];
};

export default function MeatballDropdown(props: MeatballDropdownProps) {
  const {
    children,
    align,
    side = 'bottom',
    buttonSize = 0,
    buttonVariant = 'ghost',
    buttonCss = {},
  } = props;

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <Button
          aria-label="Menu"
          css={buttonCss}
          icon="standalone"
          size={buttonSize}
          variant={buttonVariant}
        >
          <IconV2 icon={Meatballs} size={0} />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content align={align} side={side}>
          {children}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
