import { HelpIcon } from '@f8n/icons';
import { darkMode, styled } from '@f8n-frontend/stitches';
import { VariantProps } from '@stitches/react';

import FauxInput from 'components/FauxInput';
import { Input, InputPrefix, InputSuffix } from 'components/base/InputV3Base';

import Text from './Text';
import Tooltip from './Tooltip';

type FieldVariants = VariantProps<typeof FieldRoot>;

type FieldProps = {
  error?: string | React.ReactNode | undefined;
  touched?: boolean;
  htmlFor?: string;
  label?: string | React.ReactNode;
  disabled?: boolean;
  mono?: FieldVariants['mono'];
  required?: boolean;
  size?: FieldVariants['size'];
  tip?: string | React.ReactNode;
  tooltip?: string;
  variant?: FieldVariants['variant'];
};

type FieldInternalProps = FieldProps & {
  children: React.ReactNode;
};

function FieldBase(props: FieldInternalProps) {
  const {
    children,
    disabled,
    error,
    htmlFor,
    label,
    mono,
    required = false,
    size,
    tip,
    tooltip,
    touched,
    variant,
  } = props;

  const hasError = Boolean(error && touched);

  const getBelowField = () => {
    if (hasError) {
      return <FieldError>{error}</FieldError>;
    }

    if (tip) {
      return <FieldTip>{tip}</FieldTip>;
    }

    return null;
  };

  return (
    <FieldRoot
      disabled={disabled}
      hasError={hasError}
      mono={mono}
      size={size}
      variant={variant}
    >
      {label && (
        <AboveTheField>
          <FieldLabel htmlFor={htmlFor}>{label}</FieldLabel>
          {tooltip && (
            <Tooltip content={tooltip} size={0}>
              <Tooltip.Icon>
                <HelpIcon size={0} />
              </Tooltip.Icon>
            </Tooltip>
          )}
          {!required && <FieldHint>Optional</FieldHint>}
        </AboveTheField>
      )}
      {children}
      {getBelowField()}
    </FieldRoot>
  );
}

const AboveTheField = styled('div', {
  display: 'flex',
  alignItems: 'baseline',
  gap: '$1',

  '& svg': {
    display: 'block',
  },
});

const FieldTip = styled(Text, {
  marginTop: '$3',
});
FieldTip.defaultProps = {
  color: 'dim',
  size: 1,
};

const FieldLabel = styled('label', {
  color: '$black100',
  fontWeight: '$medium',
  lineHeight: '$0',
  fontSize: '$1',
});

const FieldHint = styled(Text, {
  fontWeight: '$regular',
  marginLeft: 'auto',
  lineHeight: '$0',
});
FieldHint.defaultProps = {
  color: 'dim',
  size: 1,
};

const FieldError = styled('div', {
  color: '$red4',
  fontWeight: '$regular',
  background: '$red0',
  marginTop: '$3',
  fontSize: '$1',
  lineHeight: '$2',
});

const FieldRoot = styled('div', {
  width: '100%',
  variants: {
    size: {
      0: {
        [`${AboveTheField}`]: {
          marginBottom: '$2',
        },

        [`${InputPrefix}`]: {
          fontSize: '$1',
          height: '$formElement0',
          borderBottomLeftRadius: '$1',
          borderTopLeftRadius: '$1',

          // Right padding is bigger to account for the negative margin below
          paddingLeft: '$3',
          paddingRight: '$4',

          // Negative margin big enough to offset the input's border radius
          marginRight: '-$1',
        },
        [`${InputSuffix}`]: {
          fontSize: '$3',
          paddingLeft: '$2',
          paddingRight: '$3',
          borderTopRightRadius: '$1',
          borderBottomRightRadius: '$1',
          svg: {
            width: '$icon0',
            height: '$icon0',
            display: 'block',
            color: 'currentColor',
          },
        },
        [`${FieldError}`]: {
          borderRadius: '$1',
          paddingY: '$1',
          paddingX: '$2',
        },
        [`${Input}`]: {
          borderRadius: '$1',
          fontSize: '$1',
          height: '$formElement0',
          paddingX: '$3',
        },
      },
      1: {
        [`${AboveTheField}`]: {
          marginBottom: '$2',
        },

        [`${InputPrefix}`]: {
          fontSize: '$2',
          height: '$formElement1',
          borderBottomLeftRadius: '$2',
          borderTopLeftRadius: '$2',

          // Right padding is bigger to account for the negative margin below
          paddingLeft: '$4',
          paddingRight: '$6',

          // Negative margin big enough to offset the input's border radius
          marginRight: '-$2',
        },
        [`${InputSuffix}`]: {
          fontSize: '$3',
          paddingLeft: '$2',
          paddingRight: '$4',
          borderTopRightRadius: '$2',
          borderBottomRightRadius: '$2',
          svg: {
            width: '$icon1',
            height: '$icon1',
            display: 'block',
            color: 'currentColor',
          },
        },
        [`${FieldError}`]: {
          borderRadius: '$2',
          paddingY: '$2',
          paddingX: '$3',
        },
        [`${Input}`]: {
          borderRadius: '$2',
          fontSize: '$2',
          height: '$formElement1',
          paddingX: '$4',
        },
        [`${FauxInput}`]: {
          height: '$formElement1',
          fontSize: '$1',
          paddingX: '$4',
        },
      },
      2: {
        [`${AboveTheField}`]: {
          marginBottom: '$2',
        },

        [`${InputPrefix}`]: {
          fontSize: '$2',
          height: '$formElement2',
          borderTopLeftRadius: '$2',
          borderBottomLeftRadius: '$2',

          // Right padding is bigger to account for the negative margin below
          paddingLeft: '$4',
          paddingRight: '$6',

          // Negative margin big enough to offset the input's border radius
          marginRight: '-$2',
        },
        [`${InputSuffix}`]: {
          fontSize: '$4',
          paddingLeft: '$3',
          paddingRight: '$5',
          borderTopRightRadius: '$2',
          borderBottomRightRadius: '$2',
          svg: {
            width: '$icon2',
            height: '$icon2',
            display: 'block',
            color: 'currentColor',
          },
        },
        [`${FieldError}`]: {
          borderRadius: '$2',
          paddingY: '$2',
          paddingX: '$3',
        },
        [`${Input}`]: {
          borderRadius: '$2',
          fontSize: '$2',
          height: '$formElement2',
          paddingX: '$4',
        },
      },
    },
    variant: {
      base: {
        [`${Input}`]: {
          '&:focus-visible': {
            outline: 'none',
          },
        },
      },
      primary: {
        [`${Input}`]: {
          color: '$black100',
          borderColor: '$black10',
          backgroundColor: '$white100',
          '@hover': {
            '&:hover': {
              borderColor: '$black20',
            },
          },
          '&:focus': {
            borderColor: '$black100',
            outline: '4px solid $black30',
          },
          '&::placeholder': {
            color: '$black40',
          },

          '&:focus, &[data-active=true]': {
            '&::placeholder': {
              color: '$black40',
            },
          },

          [darkMode]: {
            '&::placeholder': {
              color: '$black80',
            },

            '&:focus, &[data-active=true]': {
              '&::placeholder': {
                color: '$black60',
              },
            },
          },
        },
      },
    },
    mono: {
      true: {
        [`${Input}`]: {
          fontFamily: '$mono',
          textTransform: 'uppercase',
        },
      },
      false: {
        [`${Input}`]: {
          fontFamily: '$body',
        },
      },
    },
    disabled: {
      true: {
        [`${InputPrefix}`]: {
          backgroundColor: '$black10',
          borderColor: 'transparent',
        },
        [`${Input}`]: {
          backgroundColor: '$black5-solid',
          borderColor: 'transparent',
          color: '$black60',
          '@hover': {
            '&:hover': {
              borderColor: 'transparent',
            },
          },
        },
        [`${InputSuffix}`]: {
          backgroundColor: 'transparent',
        },
      },
    },
    hasError: {
      true: {
        [`${Input}`]: {
          borderColor: '$red3',
          '@hover': {
            '&:hover': {
              borderColor: '$red3',
            },
          },
          '&:focus': {
            borderColor: '$red3',
            outline: '4px solid $red1',
          },
        },
        [`${InputPrefix}`]: {
          color: '$red4',
          borderColor: 'transparent',
          backgroundColor: '$red0',
        },
      },
    },
  },
  compoundVariants: [
    /** The mono variant needs bigger font-size */
    {
      mono: true,
      size: 0,
      css: {
        [`${Input}`]: {
          fontSize: '$2',
        },
      },
    },
    {
      mono: true,
      size: 1,
      css: {
        [`${Input}`]: {
          fontSize: '$3',
        },
      },
    },
    {
      mono: true,
      size: 2,
      css: {
        [`${Input}`]: {
          fontSize: '$4',
        },
      },
    },
  ],
  defaultVariants: {
    size: 1,
    mono: false,
    variant: 'primary',
  },
});

const Field = Object.assign(FieldBase, {
  Root: FieldRoot,
  Error: FieldError,
  Tip: FieldTip,
});

export type { FieldVariants, FieldProps };
export default Field;
