import { AdminIcon, UsersIcon } from '@f8n/icons';

import { ButtonProps } from 'components/base/Button';
import DropdownMenu from 'components/base/DropdownMenu';
import MeatballDropdown from 'components/dropdown/MeatballDropdown';
import { isAdmin, isMyAddress } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import NotAllowedIcon from 'assets/icons/not-allowed.svg';
import useModal from 'hooks/use-modal';

import { UserLight } from 'types/Account';

interface WorldMenuProps {
  buttonVariant?: ButtonProps['variant'];
  curator: UserLight;
  onViewCreators(): void;
}

export default function WorldMenu(props: WorldMenuProps) {
  const { buttonVariant, curator, onViewCreators } = props;
  const auth = useAuth();

  const isCurator = isMyAddress(auth, curator.publicKey);
  const isCurrentUserAdmin = isAdmin(auth);

  const { setModal } = useModal();

  return (
    <MeatballDropdown buttonVariant={buttonVariant} buttonSize={0}>
      <DropdownMenu.Item type="button" onClick={onViewCreators}>
        <UsersIcon />
        View creators
      </DropdownMenu.Item>
      {isCurrentUserAdmin && (
        <DropdownMenu.Item
          type="button"
          onClick={() => setModal({ type: 'ADMIN_TOOLS' })}
        >
          <AdminIcon />
          Admin tools
        </DropdownMenu.Item>
      )}
      {!isCurator && (
        <DropdownMenu.Item
          type="button"
          onClick={() => setModal({ type: 'REPORT' })}
        >
          <NotAllowedIcon />
          Report
        </DropdownMenu.Item>
      )}
    </MeatballDropdown>
  );
}
