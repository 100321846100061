import { styled } from '@f8n-frontend/stitches';
import { VariantProps } from '@stitches/react';
import { FunctionComponent, SVGAttributes } from 'react';

import { UnsafeAny } from 'types/utils';

export type IconEl = FunctionComponent<SVGAttributes<SVGElement>>;
export interface IconV2Props {
  css?: UnsafeAny;
  icon: IconEl;
  size?: VariantProps<typeof IconContainer>['size'];
}

export default function IconV2(props: IconV2Props) {
  const { icon: IconComponent, css, size } = props;
  return (
    <IconContainer size={size} css={{ ...css }}>
      <IconComponent />
    </IconContainer>
  );
}

const IconContainer = styled('div', {
  svg: {
    display: 'block',
    position: 'relative',
  },
  variants: {
    size: {
      0: {
        svg: {
          width: 'auto',
          height: '$icon0',
        },
      },
      1: {
        svg: {
          width: 'auto',
          height: '$icon1',
        },
      },
      2: {
        svg: {
          width: 'auto',
          height: '$icon2',
        },
      },
      3: {
        svg: {
          width: 'auto',
          height: '$icon3',
        },
      },
      4: {
        svg: {
          width: 'auto',
          height: '$icon4',
        },
      },
    },
  },
  defaultVariants: {
    size: 1,
  },
});
