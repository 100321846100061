import { styled } from '@f8n-frontend/stitches';

import Box from 'components/base/Box';

type FilterLayoutProps = {
  children: React.ReactNode;
  filters?: React.ReactNode;
};

export default function FilterLayout(props: FilterLayoutProps) {
  const { children, filters } = props;

  return (
    <Box>
      {filters && (
        <FilterBar
          css={{
            paddingY: '$6',
          }}
        >
          {filters}
        </FilterBar>
      )}
      <Box
        css={{
          flexGrow: 1,
          paddingY: '$6',
          paddingTop: filters ? 0 : undefined,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

const FilterBar = styled('div', {
  paddingY: '$6',
});
