import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';

import {
  ApiExhibitionCollectors,
  ApiExhibitionCollectorsVariables,
  useExhibitionCollectors as useExhibitionCollectorsBaseHook,
} from 'gql/api/queries/exhibition-collectors.generated';
import { apiPaginator } from 'utils/react-query';

import { ApiPaginationPageParam } from 'types/Api';

type WorldCollectors = {
  totalItems: number;
  page: number;
  totalPages: number;
  items: ApiExhibitionCollectors['exhibitionCollectorStats']['items'][number]['user'][];
};

async function getWorldCollectors(
  variables: ApiExhibitionCollectorsVariables
): Promise<WorldCollectors> {
  const fetchWorldCollectors =
    useExhibitionCollectorsBaseHook.fetcher(variables);
  const response = await fetchWorldCollectors();
  const items = response.exhibitionCollectorStats.items.map(
    (
      item: ApiExhibitionCollectors['exhibitionCollectorStats']['items'][number]
    ) => ({
      ...item.user,
      isFollowingUser: item.isFollowingUser,
    })
  );

  return {
    ...response.exhibitionCollectorStats,
    items,
  };
}

const getKey = (variables: ApiExhibitionCollectorsVariables) => {
  return ['world-collectors', variables] as const;
};

/**  @description optionally pass currentUserPublicKey to receive isFollowing data for a user */
export default function useWorldCollectors(
  variables: ApiExhibitionCollectorsVariables,
  options: Pick<
    UseInfiniteQueryOptions<
      WorldCollectors,
      Error,
      WorldCollectors,
      WorldCollectors,
      ReturnType<typeof getKey>,
      ApiPaginationPageParam
    >,
    'refetchOnWindowFocus' | 'enabled'
  > = {}
) {
  return useInfiniteQuery({
    ...options,
    queryKey: getKey(variables),
    queryFn: ({ pageParam }) => {
      return getWorldCollectors({
        page: pageParam.page,
        perPage: 8,
        ...variables,
      });
    },
    getNextPageParam: apiPaginator.getNextPageParam,
    initialPageParam: apiPaginator.initialPageParam,
  });
}
