import { PlusIcon } from '@f8n/icons';
import { keepPreviousData } from '@tanstack/react-query';

import ContentBlock from 'components/ContentBlock';
import DropGridItem from 'components/DropGridItem';
import EditionGridItem from 'components/EditionGridItem';
import FrameGrid from 'components/FrameGrid';
import NftGridItem from 'components/NftGridItem';
import WorldGridSection from 'components/WorldGridSection';
import Body from 'components/base/Body';
import Box from 'components/base/Box';
import Button from 'components/base/Button';
import Flex from 'components/base/Flex';
import { useWorldManageShowcaseData } from 'components/modals/WorldManageShowcaseModal';
import ShowcaseItem from 'components/showcase/ShowcaseItem';
import useAnalytics from 'contexts/analytics/useAnalytics';
import useNotifications from 'state/stores/notifications';

import { useRemoveWorldShowcaseItem } from 'gql/api/mutations/remove-world-showcase-item.generated';
import useWorldOverview from 'hooks/queries/api/use-world-overview';
import useWorldShowcaseItem, {
  WorldShowcaseItem,
} from 'hooks/queries/api/use-world-showcase-item';
import useModal from 'hooks/use-modal';
import { mapDropSaleConfigurationToDropSale } from 'schemas/parse/drop-sale';
import { mapEditionSaleConfigurationToEditionSale } from 'schemas/parse/edition-sale';
import { getCollectionFilter } from 'utils/inputs';
import { mapApiNftMediaToShowcaseMedia } from 'utils/media';
import { mapApiMediaToPreviewMediaAsset } from 'utils/media-preview';

import { World, WorldStatsWithCreators } from 'types/World';

import WorldMenu from './WorldMenu';
import WorldShareModal from './WorldShareModal';

interface LandingTabProps {
  worldId: number;
  isWorldTeamMember: boolean;
  /** cached world stats — for loading UI */
  initialWorldStats: WorldStatsWithCreators;
  /** latest world stats — for enriched UI */
  worldStats: WorldStatsWithCreators;
  viewCreators(): void;
  viewDrops(): void;
  viewEditions(): void;
  viewListings(): void;
  viewSales(): void;
  world: World;
}

const DROPS_SECTION_HEADING = 'Drops';
const EDITIONS_SECTION_HEADING = 'Editions';
const NFTS_SECTION_HEADING = 'Listings';
const SOLD_SECTION_HEADING = 'Sold NFTs';

export default function LandingTab(props: LandingTabProps) {
  const {
    worldId,
    isWorldTeamMember,
    initialWorldStats,
    worldStats,
    viewDrops,
    viewEditions,
    viewListings,
    viewSales,
    world,
  } = props;
  const {
    availableNftCount,
    liveAuctionCount,
    editionCount,
    liveEditionCount,
    dropCount,
    liveDropCount,
    soldListingCount,
  } = worldStats;

  const overviewQuery = useWorldOverview(
    { id: worldId },
    {
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
    }
  );

  const analytics = useAnalytics();
  const modal = useModal();
  const notifications = useNotifications();

  const showcaseQuery = useWorldShowcaseItem(
    { id: worldId },
    {
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
    }
  );
  const showcaseModalQuery = useWorldManageShowcaseData({
    worldId,
    enabled: isWorldTeamMember,
  });

  const removeShowcaseItem = useRemoveWorldShowcaseItem();

  if (overviewQuery.isLoading || showcaseQuery.isLoading) {
    return (
      <Box>
        {/* TODO: add loading state for the overview, conditionally shown based on cache */}
        {initialWorldStats.availableNftCount > 0 && (
          <WorldGridSection.Skeleton
            heading={NFTS_SECTION_HEADING}
            maxColumns={4}
            itemSkeleton={<NftGridItem.Skeleton />}
          />
        )}
        {initialWorldStats.dropCount > 0 && (
          <WorldGridSection.Skeleton
            heading={DROPS_SECTION_HEADING}
            maxColumns={3}
            itemSkeleton={<DropGridItem.Skeleton />}
          />
        )}
        {initialWorldStats.editionCount > 0 && (
          <WorldGridSection.Skeleton
            heading={EDITIONS_SECTION_HEADING}
            maxColumns={3}
            itemSkeleton={<EditionGridItem.Skeleton />}
          />
        )}
        {initialWorldStats.soldListingCount > 0 && (
          <WorldGridSection.Skeleton
            heading={SOLD_SECTION_HEADING}
            maxColumns={4}
            itemSkeleton={<NftGridItem.Skeleton />}
          />
        )}
      </Box>
    );
  }

  const overview = overviewQuery.data;
  const listings = overview?.listings.items ?? [];

  const showManageShowcaseModal = () => {
    modal.setModal({
      type: 'MANAGE_WORLD_SHOWCASE',
      worldId,
      chainId: world.chainId,
    });
  };
  const removeShowcase = () => {
    const item = showcaseQuery.data;

    if (!item) return;

    const onRemoveSuccess = () => {
      analytics.track({
        name: 'removed_world_showcase_item',
      });

      showcaseQuery.refetch().then(() => {
        notifications.show.success({
          message: 'Spotlight removed',
        });
      });
    };

    switch (item.__typename) {
      case 'DropCollection': {
        return removeShowcaseItem
          .mutateAsync({
            worldId,
            featuredItem: {
              collection: getCollectionFilter(item),
            },
          })
          .then(onRemoveSuccess);
      }
      case 'EditionCollection': {
        return removeShowcaseItem
          .mutateAsync({
            featuredItem: {
              collection: getCollectionFilter(item),
            },
            worldId,
          })
          .then(onRemoveSuccess);
      }
      case 'MarketNft': {
        return removeShowcaseItem
          .mutateAsync({
            featuredItem: {
              nft: {
                chainId: item.chainId,
                contractAddress: item.collectionContractAddress,
                tokenId: item.tokenId,
              },
            },
            worldId,
          })
          .then(onRemoveSuccess);
      }
      case 'Moment': {
        return removeShowcaseItem
          .mutateAsync({
            featuredItem: {
              moment: {
                id: item.momentId,
              },
            },
            worldId,
          })
          .then(onRemoveSuccess);
      }
    }
  };

  return (
    <Box>
      <ShowcaseSection
        isEditable={
          isWorldTeamMember && hasWorldManageShowcaseOptions(showcaseModalQuery)
        }
        item={showcaseQuery.isSuccess ? showcaseQuery.data || null : null}
        onEdit={showManageShowcaseModal}
        onRemove={removeShowcase}
      />
      {availableNftCount > 0 && (
        <WorldGridSection
          heading={NFTS_SECTION_HEADING}
          liveCount={liveAuctionCount}
          cta={
            <Button variant="outline" onClick={() => viewListings()}>
              View all listings
            </Button>
          }
        >
          <FrameGrid.Root maxColumns={4}>
            {listings.map((nft) => {
              const media = nft.media
                ? mapApiMediaToPreviewMediaAsset(nft.media)
                : null;

              const optimizedMedia = media
                ? NftGridItem.optimizeMedia(media, {
                    maxColumns: 4,
                  })
                : null;

              return (
                <NftGridItem key={nft.id} nft={nft} media={optimizedMedia} />
              );
            })}
          </FrameGrid.Root>
        </WorldGridSection>
      )}
      {dropCount > 0 && (
        <WorldGridSection
          heading={DROPS_SECTION_HEADING}
          liveCount={liveDropCount}
          cta={
            <Button variant="outline" onClick={() => viewDrops()}>
              View all drops
            </Button>
          }
        >
          <FrameGrid.Root css={{ maxWidth: '$container' }}>
            {overview?.drops.map((drop) => {
              return (
                <DropGridItem
                  key={drop.id}
                  drop={drop}
                  sale={mapDropSaleConfigurationToDropSale(
                    drop.saleConfiguration
                  )}
                />
              );
            })}
          </FrameGrid.Root>
        </WorldGridSection>
      )}
      {editionCount > 0 && (
        <WorldGridSection
          heading={EDITIONS_SECTION_HEADING}
          liveCount={liveEditionCount}
          cta={
            <Button variant="outline" onClick={() => viewEditions()}>
              View all editions
            </Button>
          }
        >
          <FrameGrid.Root css={{ maxWidth: '$container' }}>
            {overview?.editions.map((edition) => {
              if (!edition.editionSale) return null;

              return (
                <EditionGridItem
                  key={edition.id}
                  edition={edition}
                  sale={mapEditionSaleConfigurationToEditionSale(
                    edition.editionSale
                  )}
                  version="legacy"
                />
              );
            })}
          </FrameGrid.Root>
        </WorldGridSection>
      )}
      {soldListingCount > 0 && (
        <WorldGridSection
          heading={SOLD_SECTION_HEADING}
          liveCount={0}
          cta={
            <Button variant="outline" onClick={() => viewSales()}>
              View all sales
            </Button>
          }
        >
          <FrameGrid.Root maxColumns={4} css={{ maxWidth: '$container' }}>
            {overview?.soldNfts.map((nft) => {
              const mediaAsset = mapApiMediaToPreviewMediaAsset(nft.media);

              const media = mediaAsset
                ? NftGridItem.optimizeMedia(mediaAsset, {
                    maxColumns: 4,
                  })
                : null;

              return (
                <NftGridItem.Sold
                  key={`${nft.contractAddress}-${nft.tokenId}`}
                  nft={nft}
                  media={media}
                />
              );
            })}
          </FrameGrid.Root>
        </WorldGridSection>
      )}
      <Box
        css={{
          paddingY: '$9',
          borderBottom: '1px solid $black5',
          '@bp2': {
            display: 'none',
          },
        }}
      >
        <Body>
          <Flex
            css={{
              alignItems: 'center',
              justifyContent: 'center',
              gap: '$3',
            }}
          >
            <WorldShareModal
              slug={world.slug}
              curator={world.curator}
              buttonVariant="raised"
            />
            <WorldMenu
              curator={world.curator}
              onViewCreators={() => props.viewCreators()}
              buttonVariant="raised"
            />
          </Flex>
        </Body>
      </Box>
    </Box>
  );
}

type ShowcaseSectionProps = {
  item: WorldShowcaseItem | null;
  isEditable: boolean;
  onEdit: () => void;
  onRemove: () => void;
};

function ShowcaseSection(props: ShowcaseSectionProps) {
  const { item, isEditable, onEdit, onRemove } = props;

  if (!item && isEditable) {
    return (
      <Body css={{ marginTop: '$7' }}>
        <ContentBlock.Empty
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button onClick={onEdit} variant="secondary" icon>
            <span>Add Spotlight</span>
            <PlusIcon />
          </Button>
        </ContentBlock.Empty>
      </Body>
    );
  }

  if (!item) return null;

  const getShowcaseUi = () => {
    switch (item.__typename) {
      case 'MarketNft':
        return (
          <ShowcaseItem.Nft
            nft={{
              ...item,
              name: item.nftName ?? '',
              contractAddress: item.collectionContractAddress,
              media: mapApiNftMediaToShowcaseMedia(item.media),
              description: item.description ?? '',
              saleStartsAt: item.nftSaleStartsAt,
            }}
            context="WORLD"
          />
        );
      case 'DropCollection':
        return (
          <ShowcaseItem.Drop
            drop={item}
            sale={mapDropSaleConfigurationToDropSale(item.saleConfiguration)}
            context="WORLD"
          />
        );
      case 'EditionCollection': {
        if (!item.editionSale) {
          throw new Error('No saleConfiguration');
        }

        const sale = mapEditionSaleConfigurationToEditionSale(item.editionSale);

        if (sale.type === 'TIMED_EDITION') {
          return (
            <ShowcaseItem.TimedEdition
              edition={{
                ...item,
                // TODO: deprecate
                assetUrl: item.editionCollectionAssetUrl as string,
                // TODO: deprecate
                mimeType: item.editionMimeType as string,
                description: item.description ?? '',
                id: item.editionCollectionId,
              }}
              sale={sale}
              context="WORLD"
            />
          );
        } else {
          return (
            <ShowcaseItem.LimitedEdition
              edition={{
                ...item,
                contractAddress: item.contractAddress,
                // TODO (types): deprecate
                assetUrl: item.editionCollectionAssetUrl as string,
                // TODO (types): deprecate
                mimeType: item.editionMimeType as string,
                description: item.description ?? '',
                id: item.editionCollectionId,
              }}
              sale={sale}
              context="WORLD"
            />
          );
        }
      }
      case 'Moment':
        return (
          <ShowcaseItem.MomentConnected
            context="WORLD"
            moment={{ id: item.momentId, ...item }}
          />
        );
    }
  };

  return (
    <Box
      css={{
        paddingY: '$7',
        borderBottom: '1px solid $black5',
        '@bp1': {
          paddingY: '$9',
        },
      }}
    >
      <Body>
        <ContentBlock
          isEditable={isEditable}
          onEdit={onEdit}
          onRemove={onRemove}
        >
          {getShowcaseUi()}
        </ContentBlock>
      </Body>
    </Box>
  );
}

function hasWorldManageShowcaseOptions(
  query: ReturnType<typeof useWorldManageShowcaseData>
): boolean {
  const { drops, editions, listings } = query;

  if (drops.isSuccess && drops.data.pages.length > 0) return true;
  if (editions.isSuccess && editions.data.pages.length > 0) return true;
  if (listings.isSuccess && listings.data.pages.length > 0) return true;

  return false;
}
