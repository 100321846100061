import { ModeratedModerationStatus } from 'types/Moderation';
import { Maybe } from 'types/utils';

import DMCANotice from './DMCANotice';
import WarningBlock from './WarningBlock';
import WarningTermsLink from './WarningTermsLink';

interface WorldWarningBlockProps {
  moderationStatus: ModeratedModerationStatus;
  moderationFrom?: Maybe<string>;
}

export default function WorldWarningBlock(props: WorldWarningBlockProps) {
  const { moderationStatus, moderationFrom } = props;

  switch (moderationStatus) {
    case 'SUSPENDED': {
      return (
        <WarningBlock
          title="This gallery has been removed."
          description={
            <>
              This gallery was found to be in violation of the Foundation{' '}
              <WarningTermsLink /> and has been removed.
            </>
          }
          status="SUSPENDED"
        />
      );
    }
    case 'UNDER_REVIEW': {
      return (
        <WarningBlock
          title="This gallery is under review."
          description={
            <>
              This gallery is currently under review by the Foundation team to
              ensure it has not violated the Foundation <WarningTermsLink />.
            </>
          }
          status="UNDER_REVIEW"
        />
      );
    }
    case 'TAKEDOWN_REQUESTED': {
      return (
        <DMCANotice
          title="This gallery has received a DMCA takedown notice."
          moderationFrom={moderationFrom || null}
        />
      );
    }
  }
}
