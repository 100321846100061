import { keepPreviousData } from '@tanstack/react-query';

import EditionGridItem from 'components/EditionGridItem';
import FrameGrid from 'components/FrameGrid';
import Flex from 'components/base/Flex';
import InfiniteScrollButton from 'components/feed/InfiniteScrollButton';
import EditionsTabFilter from 'components/filters/api/EditionsTabFilter';
import FilterLayout from 'components/profiles/tabs/FilterLayout';
import FiltersSkeleton from 'components/profiles/tabs/FiltersSkeleton';

import { useEditionFilters } from 'hooks/filters/use-edition-filters';
import useEditionsByWorld from 'hooks/queries/api/use-editions-by-world';
import { mapEditionSaleConfigurationToEditionSale } from 'schemas/parse/edition-sale';
import { getFirstValue } from 'utils/helpers';
import { extractNestedPaginatedData } from 'utils/react-query';

type EditionsTabProps = {
  worldId: number;
};

export default function EditionsTab(props: EditionsTabProps) {
  const { worldId } = props;

  const EDITIONS_TAB_CACHE_KEY = `EDITIONS_TAB_${worldId}`;
  const { filters } = useEditionFilters(EDITIONS_TAB_CACHE_KEY);

  const { data, isLoading, hasNextPage, isFetching, fetchNextPage } =
    useEditionsByWorld(
      {
        id: worldId,
        status: filters?.status || null,
      },
      {
        placeholderData: keepPreviousData,
        refetchOnWindowFocus: false,
      }
    );

  const { items: editions } = extractNestedPaginatedData(data, 'editions');

  const counts = getFirstValue(data?.pages)?.editionCounts;

  if (isLoading) {
    return (
      <FilterLayout filters={<FiltersSkeleton />}>
        <FrameGrid.Root>
          {[...Array(6)].map((_, index) => (
            <EditionGridItem.Skeleton key={index} />
          ))}
        </FrameGrid.Root>
      </FilterLayout>
    );
  }

  return (
    <>
      <FilterLayout
        filters={
          counts ? (
            <Flex css={{ alignItems: 'center', flexWrap: 'wrap', gap: '$2' }}>
              <EditionsTabFilter
                cacheKey={EDITIONS_TAB_CACHE_KEY}
                counts={counts}
              />
            </Flex>
          ) : null
        }
      >
        <FrameGrid.Root>
          {editions.map((edition) => {
            if (!edition.saleConfiguration) {
              return null;
            }

            return (
              <EditionGridItem
                key={edition.id}
                edition={edition}
                sale={mapEditionSaleConfigurationToEditionSale(
                  edition.saleConfiguration
                )}
                version="legacy"
              />
            );
          })}
        </FrameGrid.Root>
        <InfiniteScrollButton
          handleNextPage={fetchNextPage}
          isFetching={isFetching}
          hasNextPage={hasNextPage}
        />
      </FilterLayout>
    </>
  );
}
