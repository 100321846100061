import Flex from 'components/base/Flex';
import Text from 'components/base/Text';

interface LabelWrapperProps {
  label?: string;
  isOptional?: boolean;
  name?: string;
}

/**
 *
 * @deprecated Use Field instead
 */
export default function LabelWrapper(props: LabelWrapperProps) {
  const { label, isOptional = false, name } = props;
  if (label || isOptional) {
    return (
      <Flex css={{ justifyContent: 'space-between', marginBottom: '$3' }}>
        {label && (
          <Text
            as="label"
            htmlFor={name}
            size={2}
            weight="semibold"
            css={{ color: '$black100' }}
          >
            {label}
          </Text>
        )}
        {isOptional && (
          <Text size={1} weight="regular" color="dim">
            Optional
          </Text>
        )}
      </Flex>
    );
  }
  return null;
}
