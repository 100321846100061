import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';

import * as Generated from 'gql/api/queries/world-editions.generated';
import { apiPaginator } from 'utils/react-query';

import { ApiPaginationPageParam } from 'types/Api';
import { FetcherReturnType } from 'types/utils';

async function getEditionsByWorld(
  variables: Generated.ApiWorldEditionsVariables
) {
  const fetchEditionsByWorld = Generated.useWorldEditions.fetcher(variables);
  const response = await fetchEditionsByWorld();

  return response.worldEditions;
}

export type EditionsByWorld = FetcherReturnType<typeof getEditionsByWorld>;

const getEditionsByWorldQueryKey = (
  variables: Generated.ApiWorldEditionsVariables
) => {
  return ['editions-by-world', variables] as const;
};

export default function useEditionsByWorld(
  variables: Generated.ApiWorldEditionsVariables,
  options: Pick<
    UseInfiniteQueryOptions<
      EditionsByWorld,
      Error,
      EditionsByWorld,
      EditionsByWorld,
      ReturnType<typeof getEditionsByWorldQueryKey>,
      ApiPaginationPageParam
    >,
    'enabled' | 'placeholderData' | 'refetchOnWindowFocus'
  > = {}
) {
  return useInfiniteQuery({
    ...options,
    queryKey: getEditionsByWorldQueryKey(variables),
    queryFn: ({ pageParam }) => {
      return getEditionsByWorld({ ...variables, page: pageParam.page });
    },
    initialPageParam: apiPaginator.initialPageParam,
    getNextPageParam: (lastPage) => {
      return apiPaginator.getNextPageParam(lastPage.editions);
    },
  });
}
