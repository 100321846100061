import ButtonWithCount from 'components/base/ButtonWithCount';

import { ApiEditionStatus } from 'gql/api/types-api.generated';
import { useEditionFilters } from 'hooks/filters/use-edition-filters';
import { abbreviateValue } from 'utils/formatters';

import { EditionCounts } from 'types/Edition';

type EditionsTabFilterProps = {
  cacheKey: string;
  counts: EditionCounts;
};

type Filter = {
  value: ApiEditionStatus;
  label: string;
  apiValue: string;
};

const filterOptions: Filter[] = [
  {
    value: 'OPEN',
    label: 'Now minting',
    apiValue: 'openCount',
  },
  {
    value: 'SCHEDULED',
    label: 'Minting soon',
    apiValue: 'scheduledCount',
  },
  {
    value: 'ENDED',
    label: 'Ended',
    apiValue: 'endedCount',
  },
];

export default function EditionsTabFilter(props: EditionsTabFilterProps) {
  const { cacheKey, counts } = props;
  const { filters, setFilters } = useEditionFilters(cacheKey);

  return (
    <>
      {filterOptions.map((availabilityMode) => {
        const count = counts?.[availabilityMode.apiValue] || 0;

        return (
          <ButtonWithCount.Root
            key={availabilityMode.value}
            disabled={!count}
            size={0}
            type="button"
            variant="outline"
            pressed={filters?.status === availabilityMode.value}
            onClick={() => {
              setFilters({ status: availabilityMode.value });
            }}
          >
            {availabilityMode.label}
            <ButtonWithCount.Count>
              {abbreviateValue(count)}
            </ButtonWithCount.Count>
          </ButtonWithCount.Root>
        );
      })}
    </>
  );
}
