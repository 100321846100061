import { UseQueryOptions } from '@tanstack/react-query';

import {
  useUserSocialVerificationsByService,
  UserSocialVerificationsByService,
  UserSocialVerificationsByServiceVariables,
} from 'gql/hasura/queries/user-social-verifications-by-service.generated';
import { isQueryEnabled } from 'hooks/queries/shared';
import { getFirstValue, isAllTrue } from 'utils/helpers';

export function useSocialVerificationByService(
  variables: UserSocialVerificationsByServiceVariables,
  options: Pick<
    UseQueryOptions<
      UserSocialVerificationsByService,
      Error,
      UserSocialVerificationsByService['socialVerifications'][number]
    >,
    'enabled' | 'refetchInterval'
  > = {}
) {
  return useUserSocialVerificationsByService(variables, {
    ...options,
    select: (data) => getFirstValue(data.socialVerifications),
    enabled: isAllTrue([...Object.values(variables), isQueryEnabled(options)]),
  });
}
