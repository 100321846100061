import { styled } from '@f8n-frontend/stitches';

import Badge from 'components/base/Badge';
import Body from 'components/base/Body';
import Box from 'components/base/Box';
import Skeleton from 'components/base/Skeleton';
import Text from 'components/base/Text';
import UserDetails from 'components/users/UserDetails';

import { useWorldTeamUsers } from 'gql/api/queries/world-team-users.generated';
import { useWorldTokenUri } from 'hooks/web3/use-world-token-uri';
import { parseNftJson } from 'utils/assets';
import { filterAndSortTeamUsersByRole } from 'utils/worlds';

import { WorldOverview } from 'types/World';

import WorldDescription from './WorldDescription';
import WorldNft from './WorldNft';

export function AboutTab(props: {
  description: string | null;
  world: WorldOverview;
}) {
  const world = props.world;
  const { chainId, id: worldId } = world;

  const worldTokenUri = useWorldTokenUri({ chainId, worldId });

  const worldTeamUsers = useWorldTeamUsers({ worldId });

  const getNftSection = () => {
    if (worldTokenUri.error) {
      return null;
    } else if (!worldTokenUri.data) {
      return (
        <Box>
          <SectionHeading>Ownership</SectionHeading>
          <Skeleton.Block
            css={{
              aspectRatio: '1/1',
              borderRadius: '$2',
            }}
          />
        </Box>
      );
    } else {
      const parsedNft = parseNftJson(worldTokenUri.data);
      return (
        <Box>
          <SectionHeading>Ownership</SectionHeading>
          <WorldNft type="owner" world={props.world} nft={parsedNft} />
        </Box>
      );
    }
  };

  const renderUsers = () => {
    if (worldTeamUsers.data) {
      /**
       * sort admins to the top of the list
       */
      const sortedTeamUsers = filterAndSortTeamUsersByRole({
        teamUsers: worldTeamUsers.data.worldTeamUsers,
        ownerPublicKey: world.curator.publicKey,
      });

      return sortedTeamUsers.map((user) => (
        <UserRowContainer key={user.publicKey}>
          <UserDetails user={user} variant="list-item" />
        </UserRowContainer>
      ));
    } else {
      return null;
    }
  };

  return (
    <Body
      css={{
        maxWidth: '640px',
        display: 'flex',
        flexDirection: 'column',
        gap: '$7',
        paddingY: '$7',
        '@bp1': {
          gap: '$9',
          paddingY: '$9',
        },
      }}
    >
      {props.description && (
        <WorldDescription size="large" description={props.description} />
      )}

      <Box>
        <SectionHeading>Team</SectionHeading>
        <UserRowContainer>
          <UserDetails user={world.curator} variant="list-item" />
          <Badge variant="purple" size={0}>
            Owner
          </Badge>
        </UserRowContainer>
        {renderUsers()}
      </Box>

      {getNftSection()}
    </Body>
  );
}

const SectionHeading = styled(Text, {
  marginBottom: '$2',
});

SectionHeading.defaultProps = {
  size: {
    '@initial': 3,
    '@bp1': 4,
  },
};

const UserRowContainer = styled('div', {
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingY: '$4',
  gap: '$3',
  maxWidth: '100%',
  borderTop: '1px solid $black5',
});
