import { ModerationStatus } from 'types/Moderation';

export interface SetWorldModerationAirtableProxyProps {
  id: number;
  moderationStatus: ModerationStatus;
  adminAddress: string;
  url: string;
}

export async function setWorldModerationAirtableProxy({
  id,
  moderationStatus,
  adminAddress,
  url,
}: SetWorldModerationAirtableProxyProps): Promise<{
  done: boolean;
}> {
  const res = await fetch('/api/admin/moderate-world', {
    method: 'POST',
    body: JSON.stringify({ id, moderationStatus, adminAddress, url }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (res.ok) {
    return await res.json();
  }
  throw new Error('An error occurred at this endpoint');
}
