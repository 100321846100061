import { ChevronDownIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';

const FauxInputDropdownChevronContainer = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'transform $1 $ease',
});

/**
 * Looks like an input, but isn't.
 * Typically used a trigger for custom DropdownMenus, or other form inputs.
 */
const FauxInput = styled('div', {
  gap: '$3',
  display: 'flex',
  alignItems: 'center',
  border: '1px solid $black10',
  justifyContent: 'space-between',
  position: 'relative',
  background: '$white100',
  boxShadow: 'none',

  fontSize: '$2',
  paddingLeft: '$5',
  paddingRight: '10px',
  borderRadius: '$2',
  height: '$formElement2',
  width: '100%',

  fontFamily: 'inherit',
  fontWeight: '$medium',
  overflow: 'hidden',

  transition: 'border $1 $ease, box-shadow $1 $ease, outline $1 $ease',

  'button:focus &, &:focus-visible': {
    borderColor: '$black100',
    outline: '4px solid $black30',
  },

  'button[data-state=open] &': {
    borderColor: '$black100',
    boxShadow: '$regular0, inset 0px 0px 0px 1px $colors$black100',
  },

  '&[data-state=open]': {
    borderColor: '$black100',
    boxShadow: '$regular0, inset 0px 0px 0px 1px $colors$black100',
  },

  '@hover': {
    '&:hover': {
      borderColor: '$black20',
    },
  },

  [`& ${FauxInputDropdownChevronContainer}`]: {
    position: 'absolute',
    right: '$4',
  },

  [`&[data-state=open] ${FauxInputDropdownChevronContainer}`]: {
    transform: 'rotate(180deg)',
  },

  variants: {
    disabled: {
      true: {
        backgroundColor: '$black5',
        border: 'none',
        pointerEvents: 'none',
      },
    },
    variant: {
      normal: {},
      placeholder: {
        color: '$black40',
      },
    },
  },
});

function FauxInputDropdownChevron() {
  return (
    <FauxInputDropdownChevronContainer>
      <ChevronDownIcon />
    </FauxInputDropdownChevronContainer>
  );
}

export default Object.assign(FauxInput, {
  Chevron: FauxInputDropdownChevron,
});
