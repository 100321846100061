import { useRouter } from 'next/router';
import { useCallback } from 'react';

import useAnalytics from 'contexts/analytics/useAnalytics';
import useAuth from 'contexts/auth/useAuth';

import { ViewedEventBase } from 'types/Analytics';

/**
 * Use this helper to track a view event.
 * A view event is any surface (i.e. a modal, tab, or page).
 */
export default function useTrackView() {
  const analytics = useAnalytics();
  const auth = useAuth();
  const router = useRouter();

  return useCallback(
    (event: ViewedEventBase) => {
      analytics.track({
        ...event,
        authState: auth.state,
        pathname: router.pathname,
      });
    },
    [auth.state, router.pathname]
  );
}
