import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';

import * as Generated from 'gql/api/queries/world-nfts.generated';
import { apiPaginator } from 'utils/react-query';

import { ApiPaginationPageParam } from 'types/Api';
import { FetcherReturnType } from 'types/utils';

type WorldListings = FetcherReturnType<typeof selectWorldListings>;

export const selectWorldListings = (data: Generated.ApiWorldNfts) => {
  const nfts = data.nftsSearch.nfts;
  const marketCounts = data.nftsSearch.counts.marketAvailabilityCounts;

  return {
    nfts,
    marketAvailabilityCounts: {
      LIVE_AUCTION: marketCounts['LIVE_AUCTION'] || 0,
      RESERVE_NOT_MET: marketCounts['RESERVE_NOT_MET'] || 0,
      HAS_ACTIVE_OFFER: marketCounts['HAS_ACTIVE_OFFER'] || 0,
      HAS_ACTIVE_BUY_NOW: marketCounts['HAS_ACTIVE_BUY_NOW'] || 0,
    },
  };
};

async function getWorldListings(
  variables: Generated.ApiWorldNftsVariables
): Promise<WorldListings> {
  const fetchWorldListings = Generated.useWorldNfts.fetcher(variables);
  const response = await fetchWorldListings();

  if (!response) {
    throw new Error('No listings found');
  }

  return selectWorldListings(response);
}

const getWorldListingsQueryKey = (
  variables: Generated.ApiWorldNftsVariables
) => {
  return ['WorldListings', variables] as const;
};

export default function useWorldListings(
  variables: Generated.ApiWorldNftsVariables,
  options: Pick<
    UseInfiniteQueryOptions<
      WorldListings,
      Error,
      WorldListings,
      WorldListings,
      ReturnType<typeof getWorldListingsQueryKey>,
      ApiPaginationPageParam
    >,
    'placeholderData' | 'refetchOnWindowFocus'
  > = {}
) {
  return useInfiniteQuery({
    ...options,
    queryKey: getWorldListingsQueryKey(variables),
    queryFn: ({ pageParam }) => {
      return getWorldListings({
        ...variables,
        page: pageParam.page,
      });
    },
    getNextPageParam: (lastPage) => {
      return apiPaginator.getNextPageParam(lastPage.nfts);
    },
    initialPageParam: apiPaginator.initialPageParam,
  });
}
