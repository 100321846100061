import { ICON_SIZE_MAP } from '@f8n/icons/dist/helpers/size';
import { styled } from '@f8n-frontend/stitches';

import Badge, { BadgeProps } from 'components/base/Badge';

import { CONTRACT_ICONS } from 'lib/icons';

import { ContractCategory, ContractType } from 'types/Collection';

type ContractBadgeProps = {
  contractType: ContractType | ContractCategory;
  symbol: string;
  size?: BadgeProps['size'];
  variant?: BadgeProps['variant'];
};

export default function ContractBadge(props: ContractBadgeProps) {
  const { contractType } = props;

  const Icon = CONTRACT_ICONS[contractType];

  return (
    <ContractBadgeRoot mono size={props.size} variant={props.variant} uppercase>
      {Icon && (
        <>
          <Icon />
          <IconDivider />
        </>
      )}
      <Symbol>{props.symbol}</Symbol>
    </ContractBadgeRoot>
  );
}

const IconDivider = styled('div', {
  width: '1px',
  backgroundColor: 'currentColor',
  opacity: 0.2,
});

const ContractBadgeRoot = styled(Badge, {
  minWidth: 0,
  variants: {
    size: {
      0: {
        [`${IconDivider}`]: {
          height: '10px',
        },
        '& svg': {
          minWidth: ICON_SIZE_MAP[0],
        },
      },
      1: {
        [`${IconDivider}`]: {
          height: '10px',
        },
        '& svg': {
          minWidth: ICON_SIZE_MAP[0],
        },
      },
      2: {
        [`${IconDivider}`]: {
          height: '12px',
        },
        '& svg': {
          minWidth: '14px',
        },
      },
      micro: {},
    },
    variant: {
      primary: {},
      outline: {},
      blue: {},
      new: {},
      light: {
        [`${IconDivider}`]: {
          opacity: 0.2,
        },
      },
      blur: {},
      white: {},
      purple: {},
      pink: {},
      orange: {},
      red: {},
      gold: {},
      green: {},
      admin: {},
      strong: {},
      subtle: {},
    },
  },
});

const Symbol = styled('span', {
  truncate: true,
});
