import React, { Fragment } from 'react';

import InfiniteScroll from './InfiniteScroll';

type FrameGridInfiniteSkeletonLoaderProps = {
  fetchNextPage(): void;
  loadedItems: number;
  maxColumns: 2 | 3 | 4 | 5;
  skeleton: React.ReactNode;
  totalItems: number | undefined;
};

/**
 * Should be rendered inside a FrameGrid.Root component.
 */
function FrameGridInfiniteSkeletonLoader(
  props: FrameGridInfiniteSkeletonLoaderProps
) {
  return (
    <>
      {Array.from({
        length: getInfiniteScrollSkeletonCount({
          totalItems: props.totalItems ?? 8,
          loadedItems: props.loadedItems,
          maxColumns: props.maxColumns,
        }),
      }).map((_, index) => {
        if (index === 0) {
          return (
            <InfiniteScroll
              key={index}
              handleInView={() => {
                props.fetchNextPage();
              }}
            >
              {props.skeleton}
            </InfiniteScroll>
          );
        }

        return <Fragment key={index}>{props.skeleton}</Fragment>;
      })}
    </>
  );
}

export function getInfiniteScrollSkeletonCount(options: {
  totalItems: number;
  loadedItems: number;
  maxColumns: number;
}) {
  const { loadedItems, maxColumns, totalItems } = options;

  // All loaded. No need for skeletons
  if (loadedItems === totalItems) return 0;

  // Should never happen, but added just in case
  if (loadedItems > totalItems) return 0;

  // max of 2 rows containing only skeletons (on desktop)
  const emptySkeletonRowItems = maxColumns * 2;

  // Number of items that still need to be loaded
  const remainingItems = totalItems - loadedItems;

  if (remainingItems < emptySkeletonRowItems) {
    return remainingItems;
  }

  // confusingly, this will will be 0 if the last row is full
  const itemsInLastRowOrZero = loadedItems % maxColumns;
  const isLastRowFull = itemsInLastRowOrZero === 0;

  if (isLastRowFull) {
    return Math.min(emptySkeletonRowItems, remainingItems);
  } else {
    const extraItems = maxColumns - itemsInLastRowOrZero;
    return Math.min(emptySkeletonRowItems + extraItems, remainingItems);
  }
}

export default FrameGridInfiniteSkeletonLoader;
