import { styled } from '@f8n-frontend/stitches';
import { ComponentProps } from '@stitches/react';
import React from 'react';

type InputProps = ComponentProps<typeof Input>;

type InputV3BaseProps = InputProps & {
  id: string;
  prefix?: string;
  suffix?: string | React.ReactNode;
};

// TODO: rename as Input and remove old Inputs
const InputV3Base = React.forwardRef<HTMLInputElement, InputV3BaseProps>(
  function InputV3Base(props, ref) {
    const { disabled, id, prefix, suffix, ...rest } = props;
    return (
      <Container>
        {prefix && <InputPrefix htmlFor={id}>{prefix}</InputPrefix>}
        <Input disabled={disabled} id={id} ref={ref} {...rest} />
        {suffix && <InputSuffix disabled={disabled}>{suffix}</InputSuffix>}
      </Container>
    );
  }
);

const Container = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
});

const InputPrefix = styled('label', {
  color: '$black60',
  fontWeight: '$regular',
  background: '$black5',
  border: '1px solid $black5',

  display: 'flex',
  alignItems: 'center',
});

const InputSuffix = styled('div', {
  color: '$black100',
  fontWeight: '$medium',
  background: '$white100',

  position: 'absolute',
  top: 1,
  right: 1,
  bottom: 1,

  display: 'flex',
  alignItems: 'center',
  pointerEvents: 'none',

  variants: {
    disabled: {
      true: {
        backgroundColor: '$black5',
      },
    },
  },
});

const Input = styled('input', {
  flex: 1,
  paddingY: 0,
  width: '100%',
  appearance: 'none',
  truncate: true,

  border: '1px solid transparent',
  boxSizing: 'border-box',
  fontWeight: '$regular',
  textDecoration: 'none',

  willChange: 'transform',
  transition:
    'background-color $1 $ease, border $1 $ease, box-shadow $1 $ease, color $1 $ease, outline $1 $ease',

  '&::-webkit-outer-spin-button': {
    appearance: 'none',
    margin: 0,
  },
  '&::-webkit-inner-spin-button': {
    appearance: 'none',
    margin: 0,
  },
  '&[type=number]': {
    appearance: 'textfield',
  },
});

export type { InputV3BaseProps };
export { InputPrefix, InputSuffix, Input };
export default InputV3Base;
