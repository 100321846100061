import { keepPreviousData } from '@tanstack/react-query';

import DropGridItem from 'components/DropGridItem';
import FrameGrid from 'components/FrameGrid';
import Flex from 'components/base/Flex';
import InfiniteScrollButton from 'components/feed/InfiniteScrollButton';
import DropsTabFilter from 'components/filters/api/DropsTabFilter';
import FilterLayout from 'components/profiles/tabs/FilterLayout';
import FiltersSkeleton from 'components/profiles/tabs/FiltersSkeleton';

import { useDropFilters } from 'hooks/filters/use-drop-filters';
import useDropsByWorld from 'hooks/queries/api/use-drops-by-world';
import { mapDropSaleConfigurationToDropSale } from 'schemas/parse/drop-sale';
import { getFirstValue } from 'utils/helpers';
import { extractNestedPaginatedData } from 'utils/react-query';

interface DropsTabProps {
  worldId: number;
}

export default function DropsTab(props: DropsTabProps) {
  const { worldId } = props;
  const DROPS_TAB_CACHE_KEY = `DROPS_TAB_${worldId}`;
  const { filters } = useDropFilters(DROPS_TAB_CACHE_KEY);

  const { data, isLoading, hasNextPage, fetchNextPage, isFetching } =
    useDropsByWorld(
      {
        id: worldId,
        status: filters?.status || null,
      },
      {
        refetchOnWindowFocus: false,
        placeholderData: keepPreviousData,
      }
    );
  const { items: drops } = extractNestedPaginatedData(data, 'drops');
  const counts = getFirstValue(data?.pages)?.dropCounts;

  if (isLoading) {
    return (
      <FilterLayout filters={<FiltersSkeleton />}>
        <FrameGrid.Root>
          {[...Array(6)].map((_, index) => (
            <DropGridItem.Skeleton key={index} />
          ))}
        </FrameGrid.Root>
      </FilterLayout>
    );
  }

  return (
    <>
      <FilterLayout
        filters={
          counts ? (
            <Flex css={{ alignItems: 'center', flexWrap: 'wrap', gap: '$2' }}>
              <DropsTabFilter cacheKey={DROPS_TAB_CACHE_KEY} counts={counts} />
            </Flex>
          ) : null
        }
      >
        <FrameGrid.Root>
          {drops.map((drop, index) => (
            <DropGridItem
              key={`${drop.id}-${index}`}
              drop={drop}
              sale={mapDropSaleConfigurationToDropSale(drop.saleConfiguration)}
            />
          ))}
        </FrameGrid.Root>
        <InfiniteScrollButton
          handleNextPage={fetchNextPage}
          isFetching={isFetching}
          hasNextPage={hasNextPage}
        />
      </FilterLayout>
    </>
  );
}
