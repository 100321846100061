import Markdown from 'components/base/Markdown';
import Text from 'components/base/Text';

export default function WorldDescription(props: {
  description: string;
  size: 'small' | 'large';
}) {
  const isLarge = props.size === 'large';
  return (
    <Text
      size={
        isLarge
          ? {
              '@initial': 3,
              '@bp1': 4,
            }
          : 2
      }
      lineHeight={
        isLarge
          ? {
              '@initial': 1,
              '@bp1': 2,
            }
          : 3
      }
      css={{
        a: {
          color: 'inherit',
        },
        '& p': {
          marginBottom: '1rem',
        },
        '& p:empty': {
          display: 'none',
        },
        '& p:last-of-type': {
          marginBottom: 0,
        },
      }}
    >
      <Markdown>{props.description}</Markdown>
    </Text>
  );
}
