import { toPx } from '@f8n/tokens';
import { keyframes, styled } from '@f8n-frontend/stitches';
import { VariantProps } from '@stitches/react';
import React from 'react';

import Heading from 'components/base/Heading';

import { AVATAR_RADII } from 'utils/avatar';
import { getMonth, getDay } from 'utils/dates/dates';

import Text from './Text';

type DateBodyVariants = VariantProps<typeof DateBody>;

type EventDateProps = {
  timestamp: string;
  size?: 1 | 2 | 3;
  color?: DateBodyVariants['color'];
};

const SUBHEADING_SIZE_MAP = {
  1: 0,
  2: 0,
  3: 1,
} as const;

const HEADING_SIZE_MAP = {
  1: 4,
  2: 5,
  3: 5,
} as const;

export function EventDate(props: EventDateProps) {
  const { timestamp, color = 'white', size = 2 } = props;

  const date = (
    <DateBody size={size} color={color}>
      <Heading size={SUBHEADING_SIZE_MAP[size]} color="dim" uppercase>
        {getMonth(timestamp)}
      </Heading>
      <Heading size={HEADING_SIZE_MAP[size]}>{getDay(timestamp)}</Heading>
    </DateBody>
  );

  return date;
}

function AnimatedDateLive() {
  return (
    <LiveDatePulseContainer>
      <LiveDatePulseText weight="semibold" uppercase>
        Live
      </LiveDatePulseText>
      <LiveDateInnerBox />
      <LiveDateOuterBox />
    </LiveDatePulseContainer>
  );
}

EventDate.Live = AnimatedDateLive;

const DateBody = styled('div', {
  display: 'flex',
  lineHeight: '$0',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  variants: {
    color: {
      black: {
        backgroundColor: '$black100',
        color: '$white100',
        [`& ${Heading}`]: {
          color: '$white100',
        },
      },
      white: {
        backgroundColor: '$black5',
        color: '$black100',
        [`& ${Heading} > *first-child`]: {
          color: '$black70',
        },
      },
      ghost: {
        backgroundColor: '$black40',
        backdropFilter: 'blur(10px)',
        color: '$black100',
        [`& ${Heading}`]: {
          color: '$white100',
        },
      },
    },
    size: {
      3: {
        gap: '6px',
        width: '72px',
        height: '72px',
        borderRadius: '$4',
      },
      2: {
        gap: '4px',
        width: '64px',
        height: '64px',
        borderRadius: '$3',
      },
      1: {
        gap: '2px',
        width: '54px',
        height: '54px',
        borderRadius: '$3',
      },
    },
  },
});

DateBody.defaultProps = {
  color: 'white',
  size: 2,
};

const LiveDatePulseContainer = styled('div', {
  position: 'relative',
  width: '$avatar6',
  height: '$avatar6',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const LiveDatePulseText = styled(Text, {
  letterSpacing: 0.5,
  position: 'absolute',
  zIndex: 1,
  color: '$white100',
});

const outerPulse = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translate(-50%, -50%) scale(0.1)',
  },
  '50%': {
    opacity: 0.6,
  },
  '100%': {
    opacity: 0,
    transform: 'translate(-50%, -50%) scale(1)',
  },
});

const LiveDateOuterBox = styled('div', {
  position: 'absolute',
  width: '105%',
  height: '105%',
  backgroundColor: '$black100',
  top: '50%',
  left: '50%',
  borderRadius: toPx(AVATAR_RADII[8]),
  opacity: 0,
  animation: `${outerPulse} 1.6s ease-out infinite`,
});

const LiveDateInnerBox = styled('div', {
  position: 'absolute',
  width: '$avatar5',
  height: '$avatar5',
  backgroundColor: '$black100',
  borderRadius: toPx(AVATAR_RADII[6]),
});
