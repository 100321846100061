import { onGridPx } from '@f8n/tokens';
import { styled } from '@f8n-frontend/stitches';
import { ReactNode } from 'react';

import Background from 'components/Background';
import Box from 'components/base/Box';
import Flex from 'components/base/Flex';
import Overlay from 'components/base/Overlay';
import WorldRelatedWalletsModal, {
  WorldRelatedWalletsModalTab,
} from 'components/modals/WorldRelatedWalletsModal';

import useWorldModalTabAmounts from 'hooks/queries/api/use-world-modal-tab-amounts';
import { createCanvasBackground } from 'utils/styles';

import { UserLight } from 'types/Account';
import { Maybe } from 'types/utils';

import WorldMenu from './WorldMenu';
import WorldShareModal from './WorldShareModal';

interface WorldHeroContainerProps {
  coverImage: Maybe<string>;
  children: ReactNode;
  worldId: number;
  slug: string;
  curator: UserLight;
  open: boolean;
  tab: WorldRelatedWalletsModalTab;
  showModalTab(tab: WorldRelatedWalletsModalTab): void;
  onClose(): void;
  takeRateInBasisPoints: number;
}

export default function WorldHeroContainer(props: WorldHeroContainerProps) {
  const {
    coverImage,
    children,
    worldId,
    slug,
    curator,
    open,
    tab,
    showModalTab,
    onClose,
    takeRateInBasisPoints,
  } = props;

  const worldModalTabAmounts = useWorldModalTabAmounts({
    id: worldId,
    slug,
  });

  const creatorsCount = worldModalTabAmounts.data
    ? worldModalTabAmounts.data.creatorsCount
    : 0;

  const collectorsCount = worldModalTabAmounts.data
    ? worldModalTabAmounts.data.collectorsCount
    : 0;

  return (
    <Wrapper>
      <WorldRelatedWalletsModal
        creatorsCount={creatorsCount}
        collectorsCount={collectorsCount}
        open={open}
        tab={tab}
        id={worldId}
        slug={slug}
        onClose={onClose}
        onChangeTab={showModalTab}
        takeRateInBasisPoints={takeRateInBasisPoints}
      />
      {coverImage && (
        <Overlay
          css={{
            zIndex: -1,
            minHeight: 'inherit',
          }}
        />
      )}
      <WorldBackground
        css={
          coverImage
            ? { backgroundImage: `url(${coverImage})` }
            : { ...createCanvasBackground({}) }
        }
      />
      <InnerWrapper>{children}</InnerWrapper>
      <Box
        css={{
          display: 'none',
          maxWidth: '$container',
          left: '50%',
          transform: 'translateX(-50%)',
          position: 'absolute',
          width: '100%',
          '@bp2': {
            display: 'block',
          },
        }}
      >
        <Flex
          css={{
            position: 'absolute',
            zIndex: 3,
            right: 0,
            bottom: 0,
            transform: `translateY(-${onGridPx(6)})`,
            paddingRight: '$6',
            gap: '$3',
          }}
        >
          <WorldShareModal slug={slug} curator={curator} buttonVariant="blur" />
          <WorldMenu
            curator={curator}
            onViewCreators={() => showModalTab('creators')}
            buttonVariant="blur"
          />
        </Flex>
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled(Flex, {
  position: 'relative',
  alignItems: 'center',
  minHeight: 523,
  '@bp1': {
    alignItems: 'flex-end',
  },
});

const InnerWrapper = styled(Flex, {
  alignItems: 'center',
  flexDirection: 'column',
  minWidth: 0,
  zIndex: 1,
  paddingX: '$3',
  marginX: 'auto',
  textAlign: 'center',
  flexGrow: 1,
  marginTop: '$9',
  '@bp0': {
    paddingX: '$6',
    paddingY: '$8',
    maxWidth: 960,
  },
});

const WorldBackground = styled(Background, {
  minHeight: 'inherit',
});
