import ButtonWithCount from 'components/base/ButtonWithCount';

import { useNftFilters } from 'hooks/filters/use-nft-filters';
import { abbreviateValue } from 'utils/formatters';

import { MarketAvailability } from 'types/MarketAvailability';
import { UnsafeJsonBlob } from 'types/utils';

type MarketAvailabilityVariant = 'all' | 'world';

type MarketAvailabilityFilterProps = {
  cacheKey: string;
  counts: UnsafeJsonBlob;
  variant?: MarketAvailabilityVariant;
};

export default function MarketAvailabilityFilter(
  props: MarketAvailabilityFilterProps
) {
  const { cacheKey, counts, variant = 'all' } = props;
  const { filters, setFilters } = useNftFilters(cacheKey);
  const filterOptions = variantFilterMap[variant];

  return (
    <>
      {filterOptions.map((availabilityMode) => {
        const count = counts?.[availabilityMode.value] || 0;
        return (
          <ButtonWithCount.Root
            key={availabilityMode.value}
            disabled={!count}
            size={0}
            type="button"
            variant="outline"
            pressed={filters?.marketAvailability === availabilityMode.value}
            onClick={() => {
              setFilters({ marketAvailability: availabilityMode.value });
            }}
          >
            {availabilityMode.label}
            <ButtonWithCount.Count>
              {abbreviateValue(count)}
            </ButtonWithCount.Count>
          </ButtonWithCount.Root>
        );
      })}
    </>
  );
}

type Filter = {
  value: MarketAvailability;
  label: string;
};

const LIVE_AUCTION_FILTER = {
  value: 'LIVE_AUCTION' as const,
  label: 'Live Auction',
};

const BUY_NOW_FILTER = {
  value: 'HAS_ACTIVE_BUY_NOW' as const,
  label: 'Buy Now',
};

const RESERVE_FILTER = {
  value: 'RESERVE_NOT_MET' as const,
  label: 'Reserve',
};

const OFFER_FILTER = {
  value: 'HAS_ACTIVE_OFFER' as const,
  label: 'Offer',
};

// TODO: simplify filters since there's no difference b/w all & worlds
const variantFilterMap: Record<MarketAvailabilityVariant, Filter[]> = {
  all: [LIVE_AUCTION_FILTER, BUY_NOW_FILTER, RESERVE_FILTER, OFFER_FILTER],
  world: [LIVE_AUCTION_FILTER, BUY_NOW_FILTER, RESERVE_FILTER, OFFER_FILTER],
};
