import { styled } from '@f8n-frontend/stitches';
import { isPast, parseJSON } from 'date-fns';
import NextLink from 'next/link';
import { ReactNode } from 'react';

import Box from 'components/base/Box';
import { EventDate } from 'components/base/EventDate';
import Flex from 'components/base/Flex';
import Heading from 'components/base/Heading';
import Skeleton from 'components/base/Skeleton';
import Text from 'components/base/Text';
import UserStack from 'components/follows/UserStack';

import { ApiMomentFragment } from 'gql/api/api-fragments.generated';
import { getDpr, optimizeAsset } from 'utils/imgix';
import { getPath } from 'utils/router';
import { pluralizeWord } from 'utils/strings';

import { UserLight, UserMicro } from 'types/Account';

type MomentCardProps = {
  world: {
    slug: string;
  };
  moment: ApiMomentFragment;
  contributors: UserMicro[];
  contributorsCount: number;
};

export function MomentCard(props: MomentCardProps) {
  const { moment, world, contributors, contributorsCount } = props;

  const momentDate = parseJSON(moment.startsAt);

  const optimizedPosterUrl = optimizeAsset(moment.posterUrl, {
    q: 70,
    'max-w': 720,
    'max-h': 720,
    fit: 'fill',
    auto: undefined,
    dpr: getDpr(),
  });

  return (
    <MomentCardScaffolding
      date={
        <EventDate
          size={1}
          color={isPast(momentDate) ? 'black' : 'white'}
          timestamp={moment.startsAt}
        />
      }
      name={
        <Heading
          size={{ '@initial': 3, '@bp0': 5 }}
          weight="medium"
          lineHeight={0}
        >
          {moment.name}
        </Heading>
      }
      contributors={
        contributors.length > 0 ? (
          <MomentCardContributors
            contributors={contributors}
            contributorsCount={contributorsCount}
          />
        ) : null
      }
      media={
        <img
          src={optimizedPosterUrl}
          alt={moment.name}
          style={{
            display: 'block',
            maxHeight: '100%',
            maxWidth: '100%',
          }}
        />
      }
      extra={
        <NextLink
          href={getPath.moment.page({
            worldSlug: world.slug,
            momentId: moment.id,
          })}
          passHref
        >
          <AbsoluteAnchor />
        </NextLink>
      }
    />
  );
}

MomentCard.Skeleton = MomentCardSkeleton;

function MomentCardSkeleton() {
  return (
    <MomentCardScaffolding
      date={
        <Skeleton.Block
          css={{
            height: 54,
            width: 54,
            flexShrink: 0,
            borderRadius: '$3',
          }}
        />
      }
      name={
        <Heading
          size={{ '@initial': 3, '@bp0': 5 }}
          lineHeight={0}
          weight="medium"
          css={{
            width: 320,
            backgroundColor: '$black5',
            height: '100%',
            borderRadius: '$1',
            textIndent: '-9999px',
          }}
        >
          -
        </Heading>
      }
      contributors={<Skeleton.Block />}
      media={<Skeleton.Block css={{ aspectRatio: '0.7897984369 / 1' }} />}
      extra={null}
    />
  );
}

type MomentCardScaffoldingProps = {
  date: ReactNode;
  name: ReactNode;
  media: ReactNode;
  contributors: ReactNode | null;
  extra: ReactNode | null;
};

function MomentCardScaffolding(props: MomentCardScaffoldingProps) {
  return (
    <MomentCardContainer>
      <MomentCardInfoContainer>
        <MomentCardInfo>
          {props.date}
          {props.name}
        </MomentCardInfo>
        {props.contributors && (
          <Box
            css={{
              '@bp0-max': {
                display: 'none',
              },
            }}
          >
            {props.contributors}
          </Box>
        )}
      </MomentCardInfoContainer>
      <MomentCardImageContainer>{props.media}</MomentCardImageContainer>

      {props.contributors && (
        <Box
          css={{
            '@bp0': {
              display: 'none',
            },
          }}
        >
          {props.contributors}
        </Box>
      )}

      {props.extra}
    </MomentCardContainer>
  );
}

function MomentCardContributors(props: {
  contributors: UserMicro[];
  contributorsCount: number;
}) {
  const additionalContributorsCount =
    props.contributorsCount - props.contributors.length;

  return (
    <Box>
      <Text size={1} color="dim">
        {pluralizeWord('Work', props.contributorsCount)} by
      </Text>
      <Flex css={{ paddingTop: '$2' }}>
        <UserStackRow>
          <UserStack
            size={2}
            // TODO: remove type-casting
            users={props.contributors as UserLight[]}
            variant="raised"
          />
          {additionalContributorsCount > 0 && (
            <UserStack.CountBadge count={additionalContributorsCount} />
          )}
        </UserStackRow>
      </Flex>
    </Box>
  );
}

const MomentCardContainer = styled('div', {
  position: 'relative',
  padding: '$4',
  boxShadow: '$regular0',
  transition: 'box-shadow $1 $ease, transform $1 $ease',
  backgroundColor: '$white100',

  width: '100%',
  borderRadius: '$4',
  '@hover': {
    '&:hover': {
      boxShadow: '$regular1',
      transform: 'translateY(-2px)',
    },
    '&:hover + div': {
      zIndex: 2,
    },
    '&:active': {
      boxShadow: '$regular0',
      transform: 'translateY(0)',
    },
  },

  '@bp0': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '$5',
    gap: '$6',
    height: 374,
  },
});

const MomentCardInfoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '$5',
  '@bp0': {
    gap: '$8',
  },
  '@bp1': {
    gap: '$10',
  },
});

const MomentCardInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
});

const MomentCardImageContainer = styled('div', {
  display: 'flex',
  paddingY: '$5',
  justifyContent: 'center',
  '@bp0': {
    flexShrink: 0,
    paddingY: 0,
    width: '35%',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  '@bp1': {
    width: '25%',
  },
});

const AbsoluteAnchor = styled('a', {
  top: 0,
  left: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  position: 'absolute',
});

const UserStackRow = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
});
