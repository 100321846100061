import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import * as Generated from 'gql/api/queries/world-overview.generated';

import { FetcherReturnType } from 'types/utils';

async function getWorldOverview(
  variables: Generated.ApiWorldOverviewVariables
) {
  const fetchWorldOverview = Generated.useWorldOverview.fetcher(variables);
  const response = await fetchWorldOverview();

  return {
    listings: response.listings.nfts,
    drops: response.drops.drops.items,
    editions: response.editions.editions.items,
    soldNfts: response.soldNfts.items,
  };
}

type WorldOverview = FetcherReturnType<typeof getWorldOverview>;

export default function useWorldOverview(
  variables: Generated.ApiWorldOverviewVariables,
  options: Pick<
    UseQueryOptions<WorldOverview, Error>,
    'refetchOnWindowFocus' | 'placeholderData'
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: ['world-overview', variables],
    queryFn: () => getWorldOverview(variables),
  });
}
