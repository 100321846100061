import WorldStat from 'components/WorldStat';
import Dash from 'components/base/Dash';
import Flex from 'components/base/Flex';

import { formatTotalEthValue } from 'utils/formatters';

import { WorldStatsWithCreators } from 'types/World';

type WorldStatsProps = {
  onCreatorsClick(): void;
  onCollectorsClick(): void;
  onSalesClick(): void;
  stats: WorldStatsWithCreators;
};

function WorldStats(props: WorldStatsProps) {
  const { onCreatorsClick, onCollectorsClick, onSalesClick, stats } = props;
  const { creators, totalCreators, totalSales, collectors, totalCollectors } =
    stats;

  return (
    <WorldStat.Root>
      <CreatorsStat
        creators={creators}
        onCreatorsClick={onCreatorsClick}
        totalCreators={totalCreators}
      />
      <CollectorsStat
        collectors={collectors}
        totalCollectors={totalCollectors}
        onCollectorsClick={onCollectorsClick}
      />
      <SalesStat totalSales={totalSales} onSalesClick={onSalesClick} />
    </WorldStat.Root>
  );
}

function CollectorsStat(props: {
  collectors: WorldStatsWithCreators['collectors'];
  totalCollectors: WorldStatsWithCreators['totalCollectors'];
  onCollectorsClick(): void;
}) {
  const { collectors, totalCollectors, onCollectorsClick } = props;

  return (
    <WorldStat.Wrapper clickable={totalCollectors > 0}>
      <WorldStat.ClickableBackground onClick={onCollectorsClick} />
      <WorldStat.Label>Collectors</WorldStat.Label>
      <Flex
        css={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <WorldStat.Value>{totalCollectors}</WorldStat.Value>
        <WorldStat.GroupedUsers users={collectors} />
      </Flex>
    </WorldStat.Wrapper>
  );
}

function CreatorsStat(props: {
  creators: WorldStatsWithCreators['creators'];
  onCreatorsClick(): void;
  totalCreators: WorldStatsWithCreators['totalCreators'];
}) {
  const { onCreatorsClick, creators, totalCreators } = props;

  return (
    <WorldStat.Wrapper clickable={totalCreators > 0}>
      <WorldStat.ClickableBackground onClick={onCreatorsClick} />
      <WorldStat.Label>Creators</WorldStat.Label>
      <Flex
        css={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <WorldStat.Value>{totalCreators}</WorldStat.Value>
        <WorldStat.GroupedUsers users={creators} />
      </Flex>
    </WorldStat.Wrapper>
  );
}

function SalesStat(props: {
  totalSales: WorldStatsWithCreators['totalSales'];
  onSalesClick(): void;
}) {
  const { totalSales, onSalesClick } = props;

  const hasSales = totalSales > 0;

  return (
    <WorldStat.Wrapper clickable={hasSales}>
      <WorldStat.ClickableBackground
        onClick={hasSales ? onSalesClick : undefined}
      />
      <WorldStat.Label>Sales</WorldStat.Label>
      <WorldStat.Value css={{ whiteSpace: 'nowrap' }}>
        {hasSales ? `${formatTotalEthValue(totalSales)} ETH` : <Dash />}
      </WorldStat.Value>
    </WorldStat.Wrapper>
  );
}

export default WorldStats;
