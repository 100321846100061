import ButtonWithCount from 'components/base/ButtonWithCount';

import {
  ApiDropStatus,
  ApiExhibitionDropCounts,
} from 'gql/api/types-api.generated';
import { useDropFilters } from 'hooks/filters/use-drop-filters';
import { abbreviateValue } from 'utils/formatters';

type SupportedCounts = Pick<
  ApiExhibitionDropCounts,
  'openCount' | 'scheduledCount' | 'mintedOutCount' | 'endedCount'
>;

type DropsTabFilterProps = {
  cacheKey: string;
  counts: SupportedCounts;
};

type Filter = {
  value: ApiDropStatus;
  label: string;
  apiValue: keyof SupportedCounts;
};

const filterOptions: Filter[] = [
  {
    value: 'OPEN',
    label: 'Now minting',
    apiValue: 'openCount',
  },
  {
    value: 'SCHEDULED',
    label: 'Minting soon',
    apiValue: 'scheduledCount',
  },
  {
    value: 'MINTED_OUT',
    label: 'Minted out',
    apiValue: 'mintedOutCount',
  },
  {
    value: 'ENDED',
    label: 'Ended',
    apiValue: 'endedCount',
  },
];

export default function DropsTabFilter(props: DropsTabFilterProps) {
  const { cacheKey, counts } = props;
  const { filters, setFilters } = useDropFilters(cacheKey);

  return (
    <>
      {filterOptions.map((availabilityMode) => {
        const count = counts[availabilityMode.apiValue];

        return (
          <ButtonWithCount.Root
            key={availabilityMode.value}
            disabled={!count}
            size={0}
            type="button"
            variant="outline"
            pressed={filters?.status === availabilityMode.value}
            onClick={() => {
              setFilters({ status: availabilityMode.value });
            }}
          >
            {availabilityMode.label}
            <ButtonWithCount.Count>
              {abbreviateValue(count)}
            </ButtonWithCount.Count>
          </ButtonWithCount.Root>
        );
      })}
    </>
  );
}
