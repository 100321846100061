import { useCallback, useEffect, useState } from 'react';
import { useCopyToClipboard } from 'react-use';

interface CopyText {
  handleCopy: (text: string) => void;
  hasCopied: boolean;
}

export default function useCopyText(): CopyText {
  const [, copyToClipboard] = useCopyToClipboard();
  const [hasCopied, setHasCopied] = useState<boolean>(false);

  const handleCopy = useCallback(
    (text: string) => {
      setHasCopied(true);
      copyToClipboard(text);
    },
    [setHasCopied, copyToClipboard]
  );

  useEffect(() => {
    if (!hasCopied) return;

    const timeout = setTimeout(() => {
      setHasCopied(false);
    }, 2500);

    return () => {
      clearTimeout(timeout);
    };
  }, [hasCopied]);

  return { handleCopy, hasCopied };
}
