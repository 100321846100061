import { useInView } from 'react-intersection-observer';

import Box from './base/Box';

interface InfiniteScrollProps {
  handleInView(): void;
  children?: React.ReactNode;
}

export default function InfiniteScroll(props: InfiniteScrollProps) {
  const { children, handleInView } = props;

  const { ref } = useInView({
    threshold: 0.1, // 10% of viewport height
    onChange: (inView) => {
      if (inView) {
        handleInView();
      }
    },
  });

  return <Box ref={ref}>{children}</Box>;
}
