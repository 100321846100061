import { useReadContract } from 'wagmi';

import { Worlds } from 'lib/abis/Worlds';
import { getContractAddress } from 'lib/addresses';
import { ChainId } from 'lib/chains';

export function useWorldTokenUri(variables: {
  chainId: ChainId;
  worldId: number;
}) {
  return useReadContract({
    abi: Worlds,
    address: getContractAddress({
      contractName: 'worlds',
      chainId: variables.chainId,
    }),
    chainId: variables.chainId,
    functionName: 'tokenURI',
    args: [BigInt(variables.worldId)],
  });
}
