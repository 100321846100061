import { ErrorIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import { useEffect } from 'react';
import { P, match } from 'ts-pattern';

import DropGridItem from 'components/DropGridItem';
import EditionGridItem from 'components/EditionGridItem';
import FrameGrid from 'components/FrameGrid';
import FrameGridInfiniteSkeletonLoader from 'components/FrameGridInfiniteSkeletonLoader';
import FrameGridItem from 'components/FrameGridItem';
import ManageWorldMomentGridItem from 'components/ManageWorldMomentGridItem';
import NftGridItem from 'components/NftGridItem';
import SignButton from 'components/auth/SignButton';
import Box from 'components/base/Box';
import Flex from 'components/base/Flex';
import Modal from 'components/base/Modal';
import Skeleton from 'components/base/Skeleton';
import Tabs from 'components/base/Tabs';
import Text from 'components/base/Text';
import useAnalytics from 'contexts/analytics/useAnalytics';
import { hasToken } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import { ManageWorldSpotlightFilter } from 'contexts/manage-world/types';
import { PLEASE_SIGN } from 'copy/auth';
import { WORLD_TAB_LABELS } from 'copy/worlds';
import useNotifications from 'state/stores/notifications';

import {
  ApiMarketNftGridFields,
  ApiMomentFragment,
} from 'gql/api/api-fragments.generated';
import { useAddWorldShowcaseItem } from 'gql/api/mutations/add-world-showcase-item.generated';
import { useInfiniteWorldMoments } from 'gql/api/queries/world-moments.generated';
import useDropsByWorld, {
  DropsByWorld,
} from 'hooks/queries/api/use-drops-by-world';
import useEditionsByWorld, {
  EditionsByWorld,
} from 'hooks/queries/api/use-editions-by-world';
import useWorldListings from 'hooks/queries/api/use-world-listings';
import useWorldShowcaseItem from 'hooks/queries/api/use-world-showcase-item';
import { useQueryEffects } from 'hooks/react-query';
import useModalVisibility from 'hooks/use-modal-visibility';
import { logger } from 'lib/logger';
import report from 'lib/report';
import { mapDropSaleConfigurationToDropSale } from 'schemas/parse/drop-sale';
import { mapEditionSaleConfigurationToEditionSale } from 'schemas/parse/edition-sale';
import { optimizeAsset } from 'utils/imgix';
import { getCollectionFilter, getTokenFilter } from 'utils/inputs';
import { mapApiMediaToPreviewMediaAsset } from 'utils/media-preview';
import { apiPaginator, extractNestedPaginatedData } from 'utils/react-query';

import { ViewedTabEvent } from 'types/Analytics';
import { ModalOptions } from 'types/modal';
import { InfiniteScrollProps } from 'types/react-query';
import { ShowcaseItem } from 'types/spotlight';

const MODAL_KEY = 'MANAGE_WORLD_SHOWCASE';
const MAX_COLUMNS = 3;

type WorldManageShowcaseModalOptions = ModalOptions<typeof MODAL_KEY>;

export default function WorldManageShowcaseModal() {
  const modal = useModalVisibility(MODAL_KEY);

  return (
    <Modal.Root open={modal.open} onOpenChange={modal.onOpenChange}>
      <Modal.Portal>
        <Modal.BlurOverlay />
        <Modal.PositionOverlay>
          <Modal.UnmountListener onUnmount={modal.onUnmount} />
          {modal.config && (
            <WorldManageShowcaseModalWindowConnected
              {...modal.config}
              onSelectItemSuccess={() => modal.onOpenChange()}
            />
          )}
        </Modal.PositionOverlay>
      </Modal.Portal>
    </Modal.Root>
  );
}

type WorldManageShowcaseModalWindowConnectedProps =
  WorldManageShowcaseModalOptions & {
    onSelectItemSuccess: () => void;
  };

function WorldManageShowcaseModalWindowConnected(
  props: WorldManageShowcaseModalWindowConnectedProps
) {
  const { worldId, onSelectItemSuccess } = props;

  const analytics = useAnalytics();
  const auth = useAuth();
  const notifications = useNotifications();

  const queries = useWorldManageShowcaseData({ worldId });
  const showcaseQuery = useWorldShowcaseItem({ id: worldId });
  const addWorldShowcaseItem = useAddWorldShowcaseItem({
    onError: (error) => {
      report(error);
    },
    onSuccess: (data, variables) => {
      const getType = (): ShowcaseItem => {
        switch (data.addWorldFeaturedItem.__typename) {
          case 'DropCollection':
            return 'drop';
          case 'EditionCollection':
            return 'edition';
          case 'MarketNft':
            return 'nft';
          case 'Moment':
            return 'moment';
        }
      };

      const type = getType();

      analytics.track({
        name: 'added_world_showcase_item',
        type,
      });

      logger.log(`world showcase item added [${type}]`, {
        ...variables,
        worldId,
      });

      showcaseQuery.refetch().then(() => {
        onSelectItemSuccess();
        notifications.show.success({
          message: 'Spotlight updated',
        });
      });
    },
  });

  if (!hasToken(auth)) {
    return <WorldManageShowcaseModalWindow state="PLEASE_SIGN" />;
  }

  if (
    queries.drops.isLoading ||
    queries.editions.isLoading ||
    queries.listings.isLoading ||
    queries.moments.isLoading
  ) {
    return <WorldManageShowcaseModalWindow state="LOADING" />;
  }

  if (
    queries.drops.isError &&
    queries.editions.isError &&
    queries.listings.isError &&
    queries.moments.isError
  ) {
    return <WorldManageShowcaseModalWindow state="ERROR" />;
  }

  const drops = extractNestedPaginatedData(queries.drops.data, 'drops');

  const editions = extractNestedPaginatedData(
    queries.editions.data,
    'editions'
  );

  const listings = extractNestedPaginatedData(queries.listings.data, 'nfts');

  const moments = extractNestedPaginatedData(
    queries.moments.data,
    'worldMoments'
  );

  return (
    <WorldManageShowcaseModalWindow
      state="READY"
      drops={{
        items: drops.items,
        totalItems: drops.totalItemsCount,
        query: queries.drops,
      }}
      editions={{
        items: editions.items,
        totalItems: editions.totalItemsCount,
        query: queries.editions,
      }}
      listings={{
        items: listings.items,
        totalItems: listings.totalItemsCount,
        query: queries.listings,
      }}
      moments={{
        items: moments.items,
        totalItems: moments.totalItemsCount,
        query: queries.moments,
      }}
      onSelectItem={(item) => {
        match(item)
          .with({ type: 'collection' }, ({ filter }) => {
            addWorldShowcaseItem.mutate({
              featuredItem: {
                collection: getCollectionFilter(filter),
              },
              worldId,
            });
          })
          .with({ type: P.union('nft', 'token') }, ({ filter }) => {
            addWorldShowcaseItem.mutate({
              featuredItem: {
                token: getTokenFilter(filter),
              },
              worldId,
            });
          })
          .with({ type: 'moment' }, ({ filter }) => {
            addWorldShowcaseItem.mutate({
              featuredItem: {
                moment: {
                  id: filter.id,
                },
              },
              worldId,
            });
          })
          .exhaustive();
      }}
    />
  );
}

type OnSelectItem = (item: ManageWorldSpotlightFilter) => void;

type DropsQuery = ReturnType<typeof useDropsByWorld>;
type EditionsQuery = ReturnType<typeof useEditionsByWorld>;
type ListingsQuery = ReturnType<typeof useWorldListings>;
type MomentsQuery = ReturnType<typeof useInfiniteWorldMoments>;

export type ShowcaseDropItem = DropsByWorld['drops']['items'][number];
export type ShowcaseEditionItem = EditionsByWorld['editions']['items'][number];

type WorldManageShowcaseData = {
  drops: {
    items: ShowcaseDropItem[];
    query: DropsQuery;
    totalItems: number;
  };
  editions: {
    items: ShowcaseEditionItem[];
    query: EditionsQuery;
    totalItems: number;
  };
  listings: {
    items: ApiMarketNftGridFields[];
    query: ListingsQuery;
    totalItems: number;
  };
  moments: {
    items: ApiMomentFragment[];
    query: MomentsQuery;
    totalItems: number;
  };
  onSelectItem: OnSelectItem;
};

type WorldManageShowcaseModalWindowProps =
  | ({ state: 'READY' } & WorldManageShowcaseData)
  | { state: 'LOADING' }
  | { state: 'ERROR' }
  | { state: 'PLEASE_SIGN' };

export function WorldManageShowcaseModalWindow(
  props: WorldManageShowcaseModalWindowProps
) {
  const getBody = () => {
    switch (props.state) {
      case 'PLEASE_SIGN': {
        return <WorldManageShowcasePleaseSign />;
      }

      case 'LOADING': {
        return <WorldManageShowcaseTabsSkeleton />;
      }

      case 'ERROR': {
        return <WorldManageShowcaseErrorState />;
      }

      case 'READY': {
        return (
          <WorldManageShowcaseTabs
            drops={props.drops}
            editions={props.editions}
            listings={props.listings}
            moments={props.moments}
            onSelectItem={props.onSelectItem}
          />
        );
      }
    }
  };

  return (
    <Modal.Window
      header={
        props.state !== 'PLEASE_SIGN' ? (
          <Modal.Header title="Select an item to spotlight" />
        ) : null
      }
      maxWidth={props.state === 'PLEASE_SIGN' ? undefined : 900}
      size={props.state === 'PLEASE_SIGN' ? 2 : 0}
    >
      {getBody()}
    </Modal.Window>
  );
}

const showcaseTabs = ['drops', 'editions', 'listings', 'moments'] as const;
type ShowcaseTabValue = (typeof showcaseTabs)[number];
type ViewedShowcaseTabEvent = ViewedTabEvent<
  'world_showcase_modal',
  ShowcaseTabValue
>;

export function WorldManageShowcaseTabs(props: WorldManageShowcaseData) {
  if (
    !hasItemsToShowcase({
      drops: props.drops.items,
      editions: props.editions.items,
      listings: props.listings.items,
      moments: props.moments.items,
    })
  ) {
    // It's expected that consumers of this component will check this condition, and handle it elsewhere.
    return null;
  }

  return (
    <Tabs.Root
      defaultValue={getFirstTab(props)}
      onValueChange={(value: ShowcaseTabValue) => {
        switch (value) {
          case 'drops':
            props.drops.query.refetch();
            break;
          case 'editions':
            props.editions.query.refetch();
            break;
          case 'listings':
            props.listings.query.refetch();
            break;
          case 'moments':
            props.moments.query.refetch();
            break;
        }
      }}
    >
      <Tabs.Container css={{ paddingX: '$4', paddingY: 0 }}>
        <Tabs.List
          css={{
            '@bp0': {
              paddingY: '$3',
            },
          }}
        >
          {props.drops.items.length > 0 && (
            <Tabs.Trigger value="drops">{WORLD_TAB_LABELS.drops}</Tabs.Trigger>
          )}
          {props.editions.items.length > 0 && (
            <Tabs.Trigger value="editions">
              {WORLD_TAB_LABELS.editions}
            </Tabs.Trigger>
          )}
          {props.listings.items.length > 0 && (
            <Tabs.Trigger value="listings">
              {WORLD_TAB_LABELS.listings}
            </Tabs.Trigger>
          )}
          {props.moments.items.length > 0 && (
            <Tabs.Trigger value="moments">
              {WORLD_TAB_LABELS.moments}
            </Tabs.Trigger>
          )}
        </Tabs.List>
      </Tabs.Container>
      <Box css={{ height: '100%' }}>
        <Tabs.Content value="drops">
          <SelectDropGrid
            fetchNextPage={props.drops.query.fetchNextPage}
            hasNextPage={props.drops.query.hasNextPage}
            isFetching={props.drops.query.isFetching}
            items={props.drops.items}
            onSelectItem={props.onSelectItem}
            totalItems={props.drops.totalItems}
          />
        </Tabs.Content>
        <Tabs.Content value="editions">
          <SelectEditionGrid
            fetchNextPage={props.editions.query.fetchNextPage}
            hasNextPage={props.editions.query.hasNextPage}
            isFetching={props.editions.query.isFetching}
            items={props.editions.items}
            onSelectItem={props.onSelectItem}
            totalItems={props.editions.totalItems}
          />
        </Tabs.Content>
        <Tabs.Content value="listings">
          <SelectListingGrid
            fetchNextPage={props.listings.query.fetchNextPage}
            hasNextPage={props.listings.query.hasNextPage}
            isFetching={props.listings.query.isFetching}
            items={props.listings.items}
            onSelectItem={props.onSelectItem}
            totalItems={props.listings.totalItems}
          />
        </Tabs.Content>
        <Tabs.Content value="moments">
          <SelectMomentGrid
            fetchNextPage={props.moments.query.fetchNextPage}
            hasNextPage={props.moments.query.hasNextPage}
            isFetching={props.moments.query.isFetching}
            items={props.moments.items}
            onSelectItem={props.onSelectItem}
            totalItems={props.moments.totalItems}
          />
        </Tabs.Content>
      </Box>
    </Tabs.Root>
  );
}

function WorldManageShowcaseTabsSkeleton() {
  const VISIBLE_TAB_VALUE = 'skeleton';

  return (
    <Tabs.Root value={VISIBLE_TAB_VALUE}>
      <Tabs.Container css={{ paddingX: '$4', paddingY: 0 }}>
        <Tabs.List
          css={{
            '@bp0': {
              paddingY: '$3',
            },
          }}
        >
          <Tabs.Trigger value="drops" disabled>
            <Skeleton.Text>{WORLD_TAB_LABELS.drops}</Skeleton.Text>
          </Tabs.Trigger>
          <Tabs.Trigger value="editions" disabled>
            <Skeleton.Text>{WORLD_TAB_LABELS.editions}</Skeleton.Text>
          </Tabs.Trigger>
          <Tabs.Trigger value="listings" disabled>
            <Skeleton.Text>{WORLD_TAB_LABELS.listings}</Skeleton.Text>
          </Tabs.Trigger>
        </Tabs.List>
      </Tabs.Container>
      <Box css={{ height: '100%' }}>
        <Tabs.Content value={VISIBLE_TAB_VALUE}>
          <TabScrollArea>
            <FrameGrid.Root maxColumns={MAX_COLUMNS}>
              <FrameGridItem.Skeleton />
              <FrameGridItem.Skeleton />
              <FrameGridItem.Skeleton />
              <FrameGridItem.Skeleton />
              <FrameGridItem.Skeleton />
            </FrameGrid.Root>
          </TabScrollArea>
        </Tabs.Content>
      </Box>
    </Tabs.Root>
  );
}

function WorldManageShowcasePleaseSign() {
  return (
    <Flex
      css={{
        flexDirection: 'column',
        gap: '$4',
      }}
    >
      <Modal.BodyTitle
        title={PLEASE_SIGN.heading}
        description={PLEASE_SIGN.subheading}
      />
      <SignButton />
    </Flex>
  );
}

function WorldManageShowcaseErrorState() {
  return (
    <TabScrollArea>
      <WorldManageShowcaseErrorMessage />
    </TabScrollArea>
  );
}

function WorldManageShowcaseErrorMessage() {
  return (
    <Flex
      css={{
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',

        svg: {
          color: '$red4',
        },
      }}
    >
      <ErrorIcon />
      <Text css={{ marginY: '$2' }} weight="semibold">
        Error loading items
      </Text>
      <Text color="dim">Please refresh the page to try again</Text>
    </Flex>
  );
}

type UseWorldManageShowcaseDataOptions = {
  enabled?: boolean;
  worldId: number;
};

export function useWorldManageShowcaseData(
  options: UseWorldManageShowcaseDataOptions
) {
  const { enabled = true, worldId: id } = options;

  const listingsQuery = useWorldListings({
    id,
    marketAvailability: null,
    sort: 'DEFAULT',
  });

  useQueryEffects(listingsQuery, {
    onError: () => {
      report(new Error('Error loading listings inside showcase modal'));
    },
  });

  const editionsQuery = useEditionsByWorld(
    { id, status: null, accountAddress: null },
    { enabled }
  );

  useQueryEffects(editionsQuery, {
    onError: () => {
      report(new Error('Error loading editions inside showcase modal'));
    },
  });

  const dropsQuery = useDropsByWorld(
    {
      id,
      status: null,
    },
    { enabled }
  );

  useQueryEffects(dropsQuery, {
    onError: () => {
      report(new Error('Error loading drops inside showcase modal'));
    },
  });

  const momentsQuery = useInfiniteWorldMoments(
    { exhibitionId: id },
    {
      enabled,
      getNextPageParam: (lastPage) => {
        return apiPaginator.getNextPageParam(lastPage.worldMoments);
      },
      initialPageParam: apiPaginator.initialPageParam,
    }
  );

  useQueryEffects(momentsQuery, {
    onError: () => {
      report(new Error('Error loading moments inside showcase modal'));
    },
  });

  return {
    drops: dropsQuery,
    editions: editionsQuery,
    listings: listingsQuery,
    moments: momentsQuery,
  };
}

export function hasItemsToShowcase(data: {
  drops: Array<unknown>;
  editions: Array<unknown>;
  listings: Array<unknown>;
  moments: Array<unknown>;
}) {
  return (
    data.drops.length > 0 ||
    data.editions.length > 0 ||
    data.listings.length > 0 ||
    data.moments.length > 0
  );
}

function getFirstTab(
  props: WorldManageShowcaseData
): ShowcaseTabValue | undefined {
  return showcaseTabs.find((tab) => props[tab]['items'].length > 0);
}

type SelectDropGridProps = InfiniteScrollProps & {
  items: WorldManageShowcaseData['drops']['items'];
  onSelectItem: OnSelectItem;
  totalItems: number;
};

function SelectDropGrid(props: SelectDropGridProps) {
  useTrackTab('drops');

  return (
    <TabScrollArea>
      <FrameGrid.Root>
        {props.items.map((drop) => {
          return (
            <DropGridItem.Selectable
              key={drop.id}
              drop={drop}
              onClick={() => {
                props.onSelectItem({
                  type: 'collection',
                  filter: getCollectionFilter(drop),
                });
              }}
              sale={mapDropSaleConfigurationToDropSale(drop.saleConfiguration)}
            />
          );
        })}
        {props.hasNextPage && (
          <FrameGridInfiniteSkeletonLoader
            fetchNextPage={props.fetchNextPage}
            loadedItems={props.items.length}
            maxColumns={MAX_COLUMNS}
            skeleton={<FrameGridItem.Skeleton />}
            totalItems={props.totalItems}
          />
        )}
      </FrameGrid.Root>
    </TabScrollArea>
  );
}

type SelectEditionGridProps = InfiniteScrollProps & {
  items: WorldManageShowcaseData['editions']['items'];
  onSelectItem: OnSelectItem;
  totalItems: number;
};

function SelectEditionGrid(props: SelectEditionGridProps) {
  useTrackTab('editions');

  return (
    <TabScrollArea>
      <FrameGrid.Provider value={{ type: 'SELECTABLE' }}>
        <FrameGrid.Root maxColumns={MAX_COLUMNS}>
          {props.items.map((edition) => {
            if (!edition.saleConfiguration) return null;

            return (
              <EditionGridItem.Selectable
                key={edition.id}
                edition={edition}
                sale={mapEditionSaleConfigurationToEditionSale(
                  edition.saleConfiguration
                )}
                onClick={() => {
                  props.onSelectItem({
                    type: 'collection',
                    filter: getCollectionFilter(edition),
                  });
                }}
                version="legacy"
              />
            );
          })}
          {props.hasNextPage && (
            <FrameGridInfiniteSkeletonLoader
              fetchNextPage={props.fetchNextPage}
              loadedItems={props.items.length}
              maxColumns={MAX_COLUMNS}
              skeleton={<FrameGridItem.Skeleton />}
              totalItems={props.totalItems}
            />
          )}
        </FrameGrid.Root>
      </FrameGrid.Provider>
    </TabScrollArea>
  );
}

type SelectListingGridProps = InfiniteScrollProps & {
  items: WorldManageShowcaseData['listings']['items'];
  onSelectItem: OnSelectItem;
  totalItems: number;
};

function SelectListingGrid(props: SelectListingGridProps) {
  useTrackTab('listings');

  return (
    <TabScrollArea>
      <FrameGrid.Provider value={{ type: 'SELECTABLE' }}>
        <FrameGrid.Root maxColumns={3}>
          {props.items.map((nft) => {
            const media = nft.media
              ? mapApiMediaToPreviewMediaAsset(nft.media)
              : null;

            const optimizedMedia = media
              ? NftGridItem.optimizeMedia(media, {
                  maxColumns: 3,
                })
              : null;

            return (
              <NftGridItem.Selectable
                key={nft.id}
                nft={nft}
                media={optimizedMedia}
                onClick={() => {
                  props.onSelectItem({
                    type: 'nft',
                    filter: getTokenFilter(nft),
                  });
                }}
              />
            );
          })}
          {props.hasNextPage && (
            <FrameGridInfiniteSkeletonLoader
              fetchNextPage={props.fetchNextPage}
              loadedItems={props.items.length}
              maxColumns={MAX_COLUMNS}
              skeleton={<FrameGridItem.Skeleton />}
              totalItems={props.totalItems}
            />
          )}
        </FrameGrid.Root>
      </FrameGrid.Provider>
    </TabScrollArea>
  );
}

type SelectMomentGridProps = InfiniteScrollProps & {
  items: WorldManageShowcaseData['moments']['items'];
  onSelectItem: OnSelectItem;
  totalItems: number;
};

function SelectMomentGrid(props: SelectMomentGridProps) {
  useTrackTab('moments');

  return (
    <TabScrollArea>
      <FrameGrid.Provider value={{ type: 'SELECTABLE' }}>
        <FrameGrid.Root maxColumns={3}>
          {props.items.map((moment: ApiMomentFragment) => {
            const optimizedAsset = optimizeAsset(moment.posterUrl, {
              h: '350',
              fit: 'max',
              auto: 'format,compress',
            });

            return (
              <ManageWorldMomentGridItem.Selectable
                key={moment.id}
                media={{
                  type: 'image',
                  src: optimizedAsset,
                  alt: '',
                }}
                startsAt={moment.startsAt}
                name={moment.name}
                onClick={() => {
                  props.onSelectItem({
                    type: 'moment',
                    filter: {
                      id: moment.id,
                    },
                  });
                }}
              />
            );
          })}
          {props.hasNextPage && (
            <FrameGridInfiniteSkeletonLoader
              fetchNextPage={props.fetchNextPage}
              loadedItems={props.items.length}
              maxColumns={MAX_COLUMNS}
              skeleton={<FrameGridItem.Skeleton />}
              totalItems={props.totalItems}
            />
          )}
        </FrameGrid.Root>
      </FrameGrid.Provider>
    </TabScrollArea>
  );
}

const useTrackTab = (tabName: ShowcaseTabValue) => {
  const analytics = useAnalytics();

  const viewedTabEvent: ViewedShowcaseTabEvent = {
    name: 'viewed_world_showcase_modal_tab',
    tabName,
  };

  useEffect(() => {
    analytics.track(viewedTabEvent);
  }, []);
};

const TabScrollArea = styled('div', {
  height: '60vh',
  [`${FrameGrid.Root}`]: {
    padding: '$5',
  },
});
