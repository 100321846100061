import ContractBadge from 'components/ContractBadge';
import Avatar from 'components/base/Avatar';
import Flex from 'components/base/Flex';
import Heading from 'components/base/Heading';
import Text from 'components/base/Text';

import { CreatedCollection } from 'hooks/queries/api/use-created-collections';
import { buildCollectionSymbol } from 'utils/collections';
import { formatNumber } from 'utils/formatters';
import { pluralizeWord } from 'utils/strings';

type CollectionSummaryProps = {
  collectionImageUrl: string | null;
  collectionName: string;
  subheading: React.ReactNode;
  size: 1 | 2;
};

function CollectionSummary(props: CollectionSummaryProps) {
  const { collectionImageUrl, collectionName, subheading, size } = props;
  return (
    <Flex
      css={{
        gap: '$3',
        alignItems: 'center',
      }}
    >
      <Avatar imageUrl={collectionImageUrl} shape="square" size={5} />
      <Flex
        css={{
          flexDirection: 'column',
          gap: '$1',
          minWidth: 0,
        }}
      >
        <Heading size={size} ellipsis>
          {collectionName}
        </Heading>
        {subheading}
      </Flex>
    </Flex>
  );
}

type CollectionSummaryStatsProps = {
  collection: CreatedCollection;
};

function CollectionSummaryStats(props: CollectionSummaryStatsProps) {
  const { collection } = props;
  const { collectorCount, collectionImage, name } = collection;

  return (
    <CollectionSummary
      collectionImageUrl={collectionImage}
      collectionName={name}
      subheading={
        <Flex css={{ gap: '$2', alignItems: 'center' }}>
          <ContractBadge
            size={0}
            variant="outline"
            contractType={props.collection.contractType}
            symbol={buildCollectionSymbol(props.collection)}
          />
          {collectorCount > 0 && (
            <Text size={1} color="dim">
              {formatNumber(collectorCount)}{' '}
              {pluralizeWord('collector', collectorCount)}
            </Text>
          )}
        </Flex>
      }
      size={2}
    />
  );
}

CollectionSummary.Stats = CollectionSummaryStats;
export default CollectionSummary;
