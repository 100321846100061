import { useReducer } from 'react';

type State<Tab extends string> = {
  open: boolean;
  tab: Tab;
};

type Action<Tab extends string> =
  | { type: 'show'; tab: Tab }
  | { type: 'close' };

function tabbedModalReducer<Tab extends string>(
  state: State<Tab>,
  action: Action<Tab>
): State<Tab> {
  switch (action.type) {
    case 'show':
      return { open: true, tab: action.tab };
    case 'close':
      // tab is intentionally not closed when closing modal
      // must remain open to prevent collapse of modal upon closing
      return { open: false, tab: state.tab };
    default:
      return state;
  }
}

export function useTabbedModal<Tab extends string>(initialTab: Tab) {
  // eslint-disable-next-line prettier/prettier
  return useReducer(tabbedModalReducer<Tab>, {
    open: false,
    tab: initialTab,
  });
}
