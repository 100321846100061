import { when } from 'ramda';

import { CollectionMicro, buildCollectionPath } from './collections';
import { notEmptyOrNil } from './helpers';

export const appendHandle = when(notEmptyOrNil, (handle) => `@${handle}`);

const createShareURL = (path: string): string => {
  return new URL(path, 'https://foundation.app').href;
};

interface BuildClaimTweetArgs {
  artworkName: string;
  creatorName: string;
  usernameOrAddress: string;
  twitterUsername: string;
}

// tweet at the end of the claim flow
export const buildClaimTweet = ({
  artworkName,
  creatorName,
  usernameOrAddress,
  twitterUsername,
}: BuildClaimTweetArgs): string => {
  return `I just added ${artworkName} by ${
    twitterUsername || creatorName
  } to my collection on @foundation! 🌐

${createShareURL(usernameOrAddress)}`;
};

interface BuildArtworkTweetArgs {
  creatorName: string;
  artworkPath: string;
  twitterUsername: string;
}

// tweet for sharing an artwork from an artwork page
export const buildArtworkTweet = ({
  creatorName,
  artworkPath,
  twitterUsername,
}: BuildArtworkTweetArgs): string => {
  return `Check out this NFT by ${
    twitterUsername || creatorName
  } on @foundation! 🌐

${createShareURL(artworkPath)}`;
};

interface BuildCollectionTweetArgs {
  creatorName: string;
  collectionPath: string;
}

export const buildCollectionTweet = ({
  creatorName,
  collectionPath,
}: BuildCollectionTweetArgs): string => {
  return `Check out this collection by ${creatorName} on @foundation! 🌐

${createShareURL(collectionPath)}`;
};

interface BuildWorldTweetArgs {
  creatorName: string;
  worldPath: string;
}

export const buildWorldTweet = ({
  creatorName,
  worldPath,
}: BuildWorldTweetArgs): string => {
  return `Check out this gallery by ${creatorName} on @foundation! 🌐

${createShareURL(worldPath)}`;
};

interface BuildCollectionDropTweetArgs {
  collectionName: string;
  collectionPath: string;
  status: 'started' | 'revealed';
}

const buildCollectionDropTweet = ({
  collectionName,
  collectionPath,
  status,
}: BuildCollectionDropTweetArgs): string => {
  return `${collectionName} ${
    status === 'started' ? 'is now minting' : 'just revealed'
  } on @foundation! 🌐

${createShareURL(collectionPath)}`;
};

interface BuildMintTweetArgs {
  collectionName: string;
  collectionPath: string;
}

export const buildMintTweet = ({
  collectionName,
  collectionPath,
}: BuildMintTweetArgs): string => {
  return `I just minted from ${collectionName} on @foundation! 🌐

${createShareURL(collectionPath)}`;
};

interface BuildVerifyTweetArgs {
  creatorAddress: string;
  profilePath: string;
}

export const buildVerifyTweet = ({
  creatorAddress,
  profilePath,
}: BuildVerifyTweetArgs): string => {
  return `I’m on @foundation 🌐

${creatorAddress}

${createShareURL(profilePath)}`;
};

export const getDropTwitterShareText = (
  collectionData: CollectionMicro & {
    name: string;
  },
  status: 'started' | 'revealed'
): string => {
  const collectionPath = buildCollectionPath(collectionData);

  return buildCollectionDropTweet({
    collectionName: collectionData.name,
    collectionPath,
    status,
  });
};
