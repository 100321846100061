import { styled } from '@f8n-frontend/stitches';

import WorldSalesStat from 'components/WorldSalesStat';

import { abbreviateValue, formatTotalEthValue } from 'utils/formatters';

import { WorldStatsWithCreators } from 'types/World';

type SalesStatsSectionProps = {
  worldStats: Pick<
    WorldStatsWithCreators,
    'mintCount' | 'totalSales' | 'soldListingCount'
  >;
};

export default function SalesStatsSection(props: SalesStatsSectionProps) {
  const { worldStats } = props;

  const wideOnMobile =
    worldStats.mintCount > 0 &&
    worldStats.soldListingCount > 0 &&
    worldStats.totalSales > 0;

  return (
    <SalesStatsSectionWrapper>
      {worldStats.totalSales > 0 && (
        <WorldSalesStat
          value={`${formatTotalEthValue(worldStats.totalSales)} ETH`}
          title="Total sales"
          wide={wideOnMobile}
        />
      )}
      {worldStats.mintCount > 0 && (
        <WorldSalesStat
          value={abbreviateValue(worldStats.mintCount)}
          title="NFTs minted"
        />
      )}
      {worldStats.soldListingCount > 0 && (
        <WorldSalesStat
          value={abbreviateValue(worldStats.soldListingCount)}
          title="NFTs sold"
        />
      )}
    </SalesStatsSectionWrapper>
  );
}

const SalesStatsSectionWrapper = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$3',
  '@bp1': {
    gap: '$4',
  },
});
