import { styled } from '@f8n-frontend/stitches';
import { ComponentProps } from '@stitches/react';

import DarkMode from './DarkMode';
import Text from './base/Text';

type WrapperProps = ComponentProps<typeof Wrapper>;

interface WorldSalesStatProps {
  value: string | number;
  title: string;
  wide?: WrapperProps['wide'];
}

const Wrapper = styled('div', {
  background: '$black100',
  borderRadius: '$4',
  padding: '$4',
  flex: '1 1 0px',
  '@bp1': {
    padding: '$7',
  },
  variants: {
    wide: {
      true: {
        '@bp0-max': {
          width: '100%',
          flex: 'auto',
        },
      },
    },
  },
});

export default function WorldSalesStat(props: WorldSalesStatProps) {
  const { value, title, wide } = props;
  return (
    <Wrapper wide={wide}>
      <DarkMode>
        <Text
          color="dim"
          weight="medium"
          size={2}
          css={{ whiteSpace: 'nowrap' }}
        >
          {title}
        </Text>
        <Text
          weight="medium"
          size={{ '@bp1': 7, '@initial': 4 }}
          css={{ whiteSpace: 'nowrap' }}
        >
          {value}
        </Text>
      </DarkMode>
    </Wrapper>
  );
}
