import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { AuthorizeResponse, connectWalletSession } from 'lib/auth';

import { AuthToken } from 'types/auth';

interface ConnectWalletSessionVariables {
  token: AuthToken;
  connectorId: string;
}

// TODO: update wallet user cache or refetch session
export default function useConnectWalletSession(
  options?: UseMutationOptions<
    AuthorizeResponse,
    Error,
    ConnectWalletSessionVariables
  >
) {
  return useMutation({
    ...options,
    mutationFn: async (variables) => {
      const { token, connectorId } = variables;
      return await connectWalletSession({ token, connectorId });
    },
  });
}
