import { styled } from '@f8n-frontend/stitches';
import NextLink from 'next/link';
import React from 'react';

import ItemDetails from 'components/ItemDetails';
import Box from 'components/base/Box';
import Heading from 'components/base/Heading';
import Link from 'components/base/Link';
import Text from 'components/base/Text';
import UserAvatar from 'components/base/UserAvatar';
import ProfileHoverCard from 'components/profiles/ProfileHoverCard';

import { getUsernameOrAddressInfo } from 'utils/helpers';
import { getPath } from 'utils/router';

import { UserLight } from 'types/Account';

import UserText from './UserText';

type UserDetailsProps = {
  user: UserLight;
} & ({ variant?: 'stack' } | { variant: 'list-item' });

export default function UserDetails(props: UserDetailsProps) {
  const { user } = props;
  const { nameOrUsername, hasName, userPath } = getUsernameOrAddressInfo(user);

  const getUi = () => {
    if (props.variant === 'list-item') {
      return (
        <UserDetailsLink
          css={{
            maxWidth: 'max-content',
            flexGrow: 0,
          }}
        >
          <ItemDetails.Root>
            <ItemDetails.Media>
              <UserAvatar
                size={4}
                imageUrl={user.profileImageUrl}
                publicKey={user.publicKey}
              />
            </ItemDetails.Media>
            <ItemDetails.Details>
              {hasName && (
                <Text
                  size={{ '@initial': 1, '@bp0': 2 }}
                  weight="medium"
                  ellipsis
                >
                  {nameOrUsername}
                </Text>
              )}
              <UserText
                color="dim"
                variant="prefer-username"
                size={{ '@initial': hasName ? 0 : 1, '@bp0': hasName ? 1 : 2 }}
                user={user}
                weight="medium"
              />
            </ItemDetails.Details>
          </ItemDetails.Root>
        </UserDetailsLink>
      );
    }

    return (
      <UserDetailsLink
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minWidth: 0,
          textAlign: 'center',
          maxWidth: 'max-content',
        }}
      >
        <Box css={{ marginBottom: '$2' }}>
          <UserAvatar
            size={5}
            imageUrl={user.profileImageUrl}
            publicKey={user.publicKey}
          />
        </Box>
        {hasName && (
          <Heading size={3} lineHeight={1} weight="semibold" color="strong">
            {nameOrUsername}
          </Heading>
        )}
        <UserText variant="prefer-username" user={user} />
      </UserDetailsLink>
    );
  };

  return (
    // TODO: decouple the hover card + link from this component
    <ProfileHoverCard publicKey={user.publicKey}>
      <NextLink href={getPath.profile.page(userPath)} passHref prefetch={false}>
        {getUi()}
      </NextLink>
    </ProfileHoverCard>
  );
}

const UserDetailsLink = styled(Link, {
  '&:hover': {
    [`& ${Heading}, & ${Text}`]: {
      color: '$black70 !important',
    },
  },
});
