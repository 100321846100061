import { styled } from '@f8n-frontend/stitches';

import Body from 'components/base/Body';
import Box from 'components/base/Box';
import Flex from 'components/base/Flex';
import Grid from 'components/base/Grid';
import Text from 'components/base/Text';
import ExternalLink from 'components/links/ExternalLink';

import { helpCenterPaths } from 'utils/router';

import { ModerationFrom } from 'types/Moderation';

import ModerationIcon from './ModerationIcon';

const NoticeContainer = styled(Flex, {
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: 480,
  marginX: 'auto',
  flex: 1,
});

const NoticeHeading = styled(Text, {
  textAlign: 'center',
});

NoticeHeading.defaultProps = {
  size: { '@initial': 4, '@bp1': 5 },
  weight: 'semibold',
};

const NoticeText = styled(Text, {
  marginX: 'auto',
  textAlign: 'center',
});

const NoticeIssuedBy = styled(Box, {
  backgroundColor: '$black10',
  paddingX: '$3',
  paddingY: '$2',
  fontFamily: '$body',
  fontWeight: '$semibold',
  borderRadius: '$2',
});

interface DMCANoticeProps {
  moderationFrom: ModerationFrom;
  title: string;
}

export default function DMCANotice(props: DMCANoticeProps) {
  const { moderationFrom, title } = props;

  return (
    <Body css={{ display: 'flex', flex: 1, alignItems: 'center' }}>
      <NoticeContainer>
        <Grid css={{ gap: '$5' }}>
          <Grid
            css={{
              gap: '$5',
              justifyContent: 'center',
              svg: {
                height: 44,
                width: 44,
                marginX: 'auto',
                color: '$black50',
              },
            }}
          >
            <ModerationIcon status="TAKEDOWN_REQUESTED" />
            <NoticeHeading>{title}</NoticeHeading>
          </Grid>
          <Grid css={{ gap: '$7' }}>
            {moderationFrom && (
              <>
                <Grid css={{ gap: '$4' }}>
                  <NoticeText>Complaint submitted by</NoticeText>
                  <NoticeIssuedBy css={{ marginX: 'auto' }}>
                    {moderationFrom}
                  </NoticeIssuedBy>
                </Grid>
              </>
            )}
            <Flex css={{ justifyContent: 'center' }}>
              <ExternalLink
                rel="noopener noreferrer"
                target="_blank"
                href={helpCenterPaths.aboutDMCA}
                color="dim"
                hasUnderline
              >
                Learn about the DMCA process
              </ExternalLink>
            </Flex>
          </Grid>
        </Grid>
      </NoticeContainer>
    </Body>
  );
}
