import { styled } from '@f8n-frontend/stitches';

const Card = styled('div', {
  backgroundColor: '$white100',
  boxShadow: '$regular0',
  transition: 'box-shadow $1 ease, transform $1 $ease',
  willChange: 'transform',
  borderRadius: '$2',

  variants: {
    isInteractive: {
      true: {
        '@hover': {
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '$regular1',
          },
          '&:active': {
            transform: 'translateY(0)',
            boxShadow: '$regular0',
          },
        },
      },
    },
  },
});

export default Card;
