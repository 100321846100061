import { ShareIcon } from '@f8n/icons';

import Button, { ButtonProps } from 'components/base/Button';
import Text from 'components/base/Text';
import ShareModal from 'components/modals/v2/ShareModal';

import { UserLight } from 'types/Account';

interface WorldShareModalProps {
  slug: string;
  curator: UserLight;
  buttonVariant?: ButtonProps['variant'];
}
export default function WorldShareModal(props: WorldShareModalProps) {
  const { slug, curator, buttonVariant = 'raised' } = props;
  return (
    <ShareModal
      shareType="world"
      slug={slug}
      creator={curator}
      trigger={
        <Button variant={buttonVariant} size={0} icon>
          <ShareIcon />
          <Text weight="semibold">Share</Text>
        </Button>
      }
    />
  );
}
