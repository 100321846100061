import React from 'react';

import Field, { FieldProps } from 'components/base/Field';
import InputV3Base, { InputV3BaseProps } from 'components/base/InputV3Base';

export type InputFieldProps = Omit<InputV3BaseProps, 'size'> &
  Omit<FieldProps, 'htmlFor'>;

const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
  function InputField(props, ref) {
    const {
      disabled,
      error,
      id,
      label,
      mono,
      prefix,
      required,
      size,
      suffix,
      tooltip,
      touched,
      variant,
      ...rest
    } = props;

    return (
      <Field
        disabled={disabled}
        error={error}
        htmlFor={id}
        label={label}
        mono={mono}
        required={required}
        size={size}
        tooltip={tooltip}
        touched={touched}
        variant={variant}
      >
        <InputV3Base
          disabled={disabled}
          id={id}
          prefix={prefix}
          suffix={suffix}
          ref={ref}
          {...rest}
        />
      </Field>
    );
  }
);

export default InputField;
