import { ComponentProps } from '@stitches/react';
import { useField } from 'formik';
import { InputHTMLAttributes, ReactNode } from 'react';

import Box from 'components/base/Box';
import InputV2 from 'components/base/InputV2';
import Text from 'components/base/Text';

import { notEmptyOrNil } from 'utils/helpers';

import { PartialPick } from 'types/utils';

import LabelWrapper from './LabelWrapper';

type StitchesInputProps = ComponentProps<typeof InputV2>;
type InputProps = InputHTMLAttributes<HTMLInputElement>;

type RequiredInputProps = Pick<InputProps, 'name'>;
type OptionalInputProps = PartialPick<
  InputProps,
  'placeholder' | 'required' | 'type' | 'disabled'
>;

interface TextFieldProps extends RequiredInputProps, OptionalInputProps {
  css?: StitchesInputProps['css'];
  label?: string;
  meta?: ReactNode;
  appearance?: 'minimal' | 'regular';
  inputRef?: React.MutableRefObject<HTMLInputElement>;
}

/**
 * @deprecated - use TextFieldV2 instead
 */
export default function TextFieldV2<T extends string | number>(
  props: TextFieldProps
) {
  const {
    inputRef,
    name,
    placeholder,
    label,
    // leverage the pre-existing 'required' prop
    // from inputs to control the optional state
    required = true,
    // ability override the input type (e.g. number)
    type = 'text',
    // placeholder prop for now but can be overriden by formik
    disabled = false,
    // allow overriding css to be passed to the input
    css,
    // allow custom meta component to be passed in
    meta: metaComponent,

    appearance = 'regular',
  } = props;

  // @ts-expect-error null-checks
  const [field, meta] = useField<T>(name);

  const isInvalid = notEmptyOrNil(meta.error) && meta.touched;

  const isOptional = !required && appearance === 'regular';

  return (
    <Box css={css}>
      <LabelWrapper name={name} label={label} isOptional={isOptional} />
      <Box css={{ position: 'relative' }}>
        <InputV2
          // Gets rid of the up and down arrows if type is number
          css={{
            appearance: 'none',
            '&::-webkit-outer-spin-button': {
              appearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              appearance: 'none',
            },
          }}
          ref={inputRef}
          isInvalid={isInvalid}
          placeholder={placeholder}
          type={type}
          required={required}
          disabled={disabled}
          {...field}
        />
        {metaComponent}
      </Box>

      {isInvalid && (
        <Text
          size={1}
          weight="semibold"
          css={{ color: '$red3', paddingTop: '$3' }}
        >
          {meta.error}
        </Text>
      )}
    </Box>
  );
}
