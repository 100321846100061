import { ModeratedModerationStatus, ModerationStatus } from 'types/Moderation';
import { Maybe } from 'types/utils';

type ModerationStatusTextOptions = {
  isWorldTeamMember: boolean;
  moderationFrom: Maybe<string>;
};

type ModerationStatusText = Record<ModeratedModerationStatus, string>;

const DMCA_BLOCK_TITLE = 'DMCA Takedown Notice';

const getPrefix = (
  options: Pick<ModerationStatusTextOptions, 'isWorldTeamMember'>
) => (options.isWorldTeamMember ? 'Your' : 'This');

export const WORLD_MODERATION_BLOCK_TITLE: ModerationStatusText = {
  SUSPENDED: 'Gallery Suspended',
  UNDER_REVIEW: 'Gallery Under Review',
  TAKEDOWN_REQUESTED: DMCA_BLOCK_TITLE,
};

export const getWorldModerationBannerText = (
  options: ModerationStatusTextOptions
): ModerationStatusText => {
  const { isWorldTeamMember, moderationFrom } = options;
  const prefix = getPrefix({ isWorldTeamMember });

  return {
    SUSPENDED: `${prefix} gallery has been removed.`,
    UNDER_REVIEW: `${prefix} gallery is under review.`,
    TAKEDOWN_REQUESTED: moderationFrom
      ? `${prefix} gallery has received a DMCA takedown notice from ${moderationFrom}.`
      : `${prefix} gallery has received a DMCA takedown notice.`,
  };
};

type ModerationStatusCopyMap = Record<ModerationStatus, string>;

export const MODERATION_STATUS_LABEL: ModerationStatusCopyMap = {
  ACTIVE: 'Active',
  UNDER_REVIEW: 'Under review',
  TAKEDOWN_REQUESTED: 'Takedown requested',
  SUSPENDED: 'Suspended',
};

export const MODERATION_STATUS_DESCRIPTION: ModerationStatusCopyMap = {
  ACTIVE:
    'This page will be publicly viewable across Foundation. This is the default state for pages on Foundation.',
  UNDER_REVIEW:
    'This page will be taken down and replaced with an ‘Under Review’ message. It will only be accessible via direct link.',
  TAKEDOWN_REQUESTED:
    'This page will be taken down and replaced with a DMCA message. It will only be accessible via direct link.',
  SUSPENDED:
    'This page will be taken down and replaced with a ‘Permanently Removed’ message. It will only be accessible via direct link.',
};
