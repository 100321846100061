import Button from 'components/base/Button';
import { useAssumeConnectedAuth } from 'contexts/auth/useAuth';

import useSignAuthMessage from 'hooks/use-sign-auth-message';

type SignButtonProps = {
  initialText?: string;
  onSign?: () => void;
};

export default function SignButton(props: SignButtonProps) {
  const { initialText = 'Continue', onSign } = props;
  const auth = useAssumeConnectedAuth();

  const signAuthMessage = useSignAuthMessage({
    auth,
    onSign,
  });

  return (
    <Button.Loader
      onClick={() => signAuthMessage.requestSignature()}
      isLoading={signAuthMessage.isSigning}
      loadingLabel="Sign message in wallet"
      size={2}
    >
      {initialText}
    </Button.Loader>
  );
}
