import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';

import {
  ApiExhibitionCreatorsVariables,
  useExhibitionCreators as useExhibitionCreatorsBaseHook,
} from 'gql/api/queries/exhibition-creators.generated';
import { apiPaginator } from 'utils/react-query';

import { ApiPaginationPageParam } from 'types/Api';
import { FetcherReturnType } from 'types/utils';

async function getWorldCreators(variables: ApiExhibitionCreatorsVariables) {
  const fetchWorldCreators = useExhibitionCreatorsBaseHook.fetcher(variables);
  const data = await fetchWorldCreators();
  const items = data.exhibitionCreatorStats.items.map((item) => ({
    ...item.user,
    isFollowingUser: item.isFollowingUser,
  }));
  return { ...data.exhibitionCreatorStats, items };
}

type WorldCreators = FetcherReturnType<typeof getWorldCreators>;

export default function useWorldCreators(
  variables: ApiExhibitionCreatorsVariables,
  options: Pick<
    UseInfiniteQueryOptions<
      WorldCreators,
      Error,
      WorldCreators,
      WorldCreators,
      ReturnType<typeof useWorldCreators.getKey>,
      ApiPaginationPageParam
    >,
    'enabled' | 'refetchOnWindowFocus'
  > = {}
) {
  return useInfiniteQuery({
    ...options,
    queryKey: useWorldCreators.getKey(variables),
    queryFn: ({ pageParam }) => {
      return getWorldCreators({
        page: pageParam.page,
        perPage: 8,
        ...variables,
      });
    },
    initialPageParam: apiPaginator.initialPageParam,
    getNextPageParam: apiPaginator.getNextPageParam,
  });
}

useWorldCreators.getKey = (variables: ApiExhibitionCreatorsVariables) => [
  'WorldCreators',
  variables,
];
