import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { ApiDropStatus } from 'gql/api/types-api.generated';

const INITIAL_DROP_FILTERS = {
  status: null,
};

type DropFiltersApi = {
  filters: DropFilters;
  setFilters(filters: Partial<DropFilters>): void;
};

type DropFilters = {
  status: ApiDropStatus | null;
};

export function useDropFilters(
  id: string,
  options?: UseQueryOptions<DropFilters, Error>
): DropFiltersApi {
  const queryClient = useQueryClient();
  const cacheKey = useDropFilters.getKey(id);

  const { data: filters = INITIAL_DROP_FILTERS } = useQuery<DropFilters, Error>(
    {
      ...options,
      enabled: false,
      initialData: INITIAL_DROP_FILTERS,
      queryKey: cacheKey,
    }
  );

  const setFilters: DropFiltersApi['setFilters'] = (nextState) => {
    queryClient.setQueryData<DropFilters>(
      cacheKey,
      (prevState: DropFilters) => {
        const filters = { ...prevState, ...nextState };

        let status = prevState.status;
        if (nextState.status === undefined) {
          status = prevState.status;
        } else if (
          nextState.status === null ||
          nextState.status === prevState.status
        ) {
          status = null;
        } else {
          status = nextState.status;
        }

        return {
          ...filters,
          status,
        };
      }
    );
  };

  return {
    filters,
    setFilters,
  };
}

useDropFilters.getKey = (id: string) => ['DropFilters', id];
