import { styled, config } from '@f8n-frontend/stitches';
import NextLink from 'next/link';
import useMedia from 'react-use/lib/useMedia';

import Badge from 'components/base/Badge';
import Box from 'components/base/Box';
import Flex from 'components/base/Flex';
import Link from 'components/base/Link';
import Skeleton from 'components/base/Skeleton';
import Text from 'components/base/Text';
import UserTag from 'components/users/UserTag';

import { getPath } from 'utils/router';

import { UserMicro } from 'types/Account';
import { CollectionFilter } from 'types/Collection';

import CollectionSummary from './CollectionSummary';

const LeaderboardTable = styled('div', {
  border: 'solid 1px $black10',
  boxShadow: '$soft0',
  borderRadius: '$3',
  width: '100%',
  '>div': {
    borderBottom: 'solid 1px $black10',
  },
  '>div:first-child': {
    borderTopLeftRadius: '$3',
    borderTopRightRadius: '$3',
  },
  '>div:last-child': {
    borderBottomLeftRadius: '$3',
    borderBottomRightRadius: '$3',
    borderBottom: 'none',
  },
});

export type LeaderboardCollection = CollectionFilter & {
  name: string;
  creator: UserMicro;
  assetUrl: string | null;
};

interface LeaderboardItemProps {
  collection: LeaderboardCollection;
  ranking: number;
  values: {
    primary: string;
    secondary: string;
  };
}

function LeaderboardItem(props: LeaderboardItemProps) {
  const { collection, ranking, values } = props;

  const isBpXs = useMedia(config.media['bp0-max']);

  return (
    <LeaderboardItemContainer>
      <Badge variant="pink">#{ranking}</Badge>
      <Box
        css={{
          minWidth: 0,
          flexGrow: 1,
        }}
      >
        <NextLink href={getPath.mint.page(collection)} passHref>
          <Link>
            <CollectionSummary
              collectionImageUrl={collection.assetUrl}
              collectionName={collection.name}
              subheading={
                <UserTag
                  nameVariant="prefer-username"
                  type="avatar-text"
                  user={collection.creator}
                />
              }
              size={isBpXs ? 1 : 2}
            />
          </Link>
        </NextLink>
      </Box>
      <Flex
        css={{
          flexDirection: 'column',
          alignItems: 'flex-end',
          flexShrink: 0,
        }}
      >
        <Text
          weight="semibold"
          size={{
            '@initial': 2,
            '@bpxs': 1,
          }}
          ellipsis
        >
          {values.primary}
        </Text>
        <Text
          color="dim"
          size={{
            '@initial': 1,
            '@bpxs': 0,
          }}
        >
          {values.secondary}
        </Text>
      </Flex>
    </LeaderboardItemContainer>
  );
}

const LeaderboardItemContainer = styled('div', {
  display: 'flex',
  gap: '$3',
  alignItems: 'center',
  padding: '$5',
  minWidth: 0,
  width: '100%',

  '@bp0-max': {
    padding: '$4',
  },
});

type LeaderboardSkeletonProps = {
  rowCount: number;
};

function LeaderboardSkeleton(props: LeaderboardSkeletonProps) {
  return (
    <LeaderboardTable>
      {Array.from({ length: props.rowCount }).map((_, index) => {
        return (
          <LeaderboardItemContainer key={index}>
            <Skeleton.Block
              css={{
                width: '33px',
                height: '23px',
                borderRadius: '$round',
                backgroundColor: '$pink0',
              }}
            />
            <Skeleton.Avatar size={5} shape="square" />
            <Flex css={{ flexDirection: 'column', gap: '$2' }}>
              <Skeleton.Block css={{ width: '160px', height: '18px' }} />
              <Flex css={{ alignItems: 'center', gap: '$2' }}>
                <Skeleton.Avatar size={1} />
                <Skeleton.Block css={{ width: '80px', height: '14px' }} />
              </Flex>
            </Flex>
          </LeaderboardItemContainer>
        );
      })}
    </LeaderboardTable>
  );
}

const Leaderboard = {
  Item: LeaderboardItem,
  Table: LeaderboardTable,
  Skeleton: LeaderboardSkeleton,
};

export default Leaderboard;
