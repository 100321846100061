import { keyframes, styled } from '@f8n-frontend/stitches';

import Media from './Media';

const fadeIn = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
});

const MediaContainer = styled('div', {
  display: 'flex',
});

const DetailsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',

  minWidth: 0,

  '& > *': {
    minWidth: 0,
  },
});

const RootContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$3',
  minWidth: 0,
  position: 'relative',

  [`${Media}`]: {
    //TODO (moments-custom-sort): prevent this animtion from running while dragging
    animation: `${fadeIn} $transitions$3 $transitions$ease forwards`,
  },

  [`${Media.Root}`]: {
    width: 48,
    height: 48,
    borderRadius: '$2',
    backgroundColor: '$black5',
  },

  [`${Media.Tint}`]: {
    width: 'inherit',
    height: 'inherit',
  },

  [`${MediaContainer} svg`]: {
    color: '$white100',
    width: 14,
    height: 14,
  },

  '@bp0': {
    gap: '$3',

    [`${Media.Root}`]: {
      width: 56,
      height: 56,
    },
  },
});

const ItemDetails = {
  Root: RootContainer,
  Media: MediaContainer,
  Details: DetailsContainer,
};

export default ItemDetails;
