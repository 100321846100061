import { ExternalIcon } from '@f8n/icons';
import { minutesToSeconds } from 'date-fns';
import {
  GetStaticPathsResult,
  GetStaticPropsContext,
  GetStaticPropsResult,
} from 'next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { z } from 'zod';

import ContentBlock from 'components/ContentBlock';
import DarkMode from 'components/DarkMode';
import Empty from 'components/Empty';
import Page from 'components/Page';
import Pulse from 'components/Pulse';
import ModerationBanner from 'components/admin/ModerationBanner';
import Body from 'components/base/Body';
import Box from 'components/base/Box';
import Button from 'components/base/Button';
import Heading from 'components/base/Heading';
import Image from 'components/base/Image';
import Tabs from 'components/base/Tabs';
import WorldLogo from 'components/base/WorldLogo';
import ReportModal from 'components/modals/ReportModal';
import WorldManageShowcaseModal from 'components/modals/WorldManageShowcaseModal';
import {
  useWorldRelatedWalletsModal,
  WorldRelatedWalletsModalTab,
} from 'components/modals/WorldRelatedWalletsModal';
import AdminToolsModal from 'components/modals/admin-tools/AdminToolsModal';
import ModerateWorldPane from 'components/modals/admin-tools/ModerateWorldPane';
import WorldWarningBlock from 'components/trust-safety/WorldWarningBlock';
import { AboutTab } from 'components/worlds/AboutTab';
import DropsTab from 'components/worlds/DropsTab';
import EditionsTab from 'components/worlds/EditionsTab';
import LandingTab from 'components/worlds/LandingTab';
import ListingsTab from 'components/worlds/ListingsTab';
import { MomentsTab } from 'components/worlds/MomentsTab';
import SalesTab from 'components/worlds/SalesTab';
import WorldHeroContainer from 'components/worlds/WorldHeroContainer';
import WorldStats from 'components/worlds/WorldStats';
import useAnalytics from 'contexts/analytics/useAnalytics';
import { hasPublicKey, isAdmin } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import {
  getWorldModerationBannerText,
  WORLD_MODERATION_BLOCK_TITLE,
} from 'copy/moderation';
import { WORLD_TAB_LABELS } from 'copy/worlds';

import useWorldRole from 'hooks/queries/api/use-world-role';
import useWorldStats, {
  getWorldStats,
} from 'hooks/queries/api/use-world-stats';
import useWorldBySlug, {
  getWorldBySlug,
} from 'hooks/queries/use-world-by-slug';
import { NOT_FOUND } from 'lib/constants';
import { setDeepLinkUrlParams } from 'utils/deep-link';
import { optimizeAsset, optimizeMetaImage } from 'utils/imgix';
import { isFlaggedForModeration } from 'utils/moderation';
import { helpCenterPaths } from 'utils/router';
import { absoluteUrl } from 'utils/seo';
import { serverQuery } from 'utils/server';

import { ViewedTabEvent } from 'types/Analytics';
import { World, WorldStatsWithCreators } from 'types/World';

type WorldPageProps = {
  world: World;
  worldStats: WorldStatsWithCreators;
};

/** Needs to be a hard coded array of string to work with Zod */
const worldDeepLinkableTab = [
  'home',
  'exhibitions',
  'listings',
  'editions',
  'drops',
  'sales',
  'about',
] as const;

/** Extracts the hard coded strings above into named values */
const [
  homeTabValue,
  momentsTabValue,
  listingsTabValue,
  editionsTabValue,
  dropsTabValue,
  salesTabValue,
  aboutTabValue,
] = worldDeepLinkableTab;

const deepLinkValueEnum = z.enum(worldDeepLinkableTab);

const routerSchema = z.object({
  isReady: z.literal(true),
  query: z.object({
    tab: deepLinkValueEnum,
  }),
});

type DeepLinkWorldTab = (typeof worldDeepLinkableTab)[number];
type WorldTabValue = DeepLinkWorldTab;

export default function WorldPage(props: WorldPageProps) {
  const { world: initialWorld, worldStats: initialWorldStats } = props;

  const worldQuery = useWorldBySlug(
    { slug: initialWorld.slug },
    { initialData: initialWorld }
  );

  const world = worldQuery.data || initialWorld;

  const auth = useAuth();
  const currentUserPublicKey = hasPublicKey(auth) ? auth.publicKey : '';
  const isCurrentUserAdmin = isAdmin(auth);

  const {
    coverUrl: coverImage,
    curator,
    name,
    description,
    id: worldId,
    imageUrl: logo,
    slug,
    moderationFrom,
    moderationStatus,
    takeRateInBasisPoints,
  } = world;

  const statsQuery = useWorldStats(
    {
      id: worldId,
    },
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    }
  );
  const worldStats = statsQuery.data ?? initialWorldStats;

  const worldRoleQuery = useWorldRole({
    publicKey: currentUserPublicKey,
    worldId: world.id,
  });

  const worldRole = worldRoleQuery.isSuccess ? worldRoleQuery.data : null;

  const isWorldTeamMember = worldRole !== null;

  const tabs = useWorldTabs({
    worldStats,
  });

  const router = useRouter();

  const parsedTabSchema = routerSchema.safeParse(router);

  // Handle deep linking to tabs
  useEffect(() => {
    if (!router.isReady) return;
    if (!parsedTabSchema.success) return;

    const deepLinkTabValue = parsedTabSchema.data.query.tab;
    if (deepLinkTabValue === tabs.value) return;

    if (tabs.enabledTabValues.includes(deepLinkTabValue)) {
      tabs.setValue(deepLinkTabValue);
    }
  }, [
    tabs.stringifiedEnabledTabValues,
    router.isReady,
    parsedTabSchema.success,
  ]);

  const viewListings = () => tabs.setValue('listings');
  const viewDrops = () => tabs.setValue('drops');
  const viewEditions = () => tabs.setValue('editions');
  const viewSales = () => tabs.setValue('sales');
  const isModerated = isFlaggedForModeration(moderationStatus);

  const isTeamMemberOrAdmin = isCurrentUserAdmin || isWorldTeamMember;
  const showFullScreenModerationBlock = isModerated && !isTeamMemberOrAdmin;
  const showModerationBanner = isModerated && isTeamMemberOrAdmin;
  const getMetaImage = () => {
    // Custom meta image for specific worlds that we create
    switch (world.id) {
      // designed-by-foundation
      case 510: {
        return absoluteUrl('/designed-by-foundation-opengraph.png');
      }
    }
    return coverImage ? optimizeMetaImage(coverImage) : undefined;
  };

  const [creatorsAllowlistModalState, dispatchCreatorsAllowlistModal] =
    useWorldRelatedWalletsModal('creators');

  const showModalTab = (tab: WorldRelatedWalletsModalTab) => {
    dispatchCreatorsAllowlistModal({ type: 'show', tab });
  };

  if (showFullScreenModerationBlock) {
    return (
      <Page title={WORLD_MODERATION_BLOCK_TITLE[moderationStatus]}>
        <WorldWarningBlock
          moderationStatus={moderationStatus}
          moderationFrom={moderationFrom}
        />
      </Page>
    );
  }

  const renderTabs = () => {
    if (tabs.enabledTabs.length > 0) {
      return (
        <Tabs.Root
          value={tabs.value}
          onValueChange={(value) => tabs.setValue(value as WorldTabValue)}
        >
          <Tabs.EnableScrollToTop value={tabs.value} />
          <Tabs.Container
            css={{
              background: '$white90',
              backdropFilter: 'saturate(150%) blur(20px) brightness(1.5)',
            }}
          >
            <Body>
              <Tabs.List
                centered={{
                  '@initial': false,
                  '@bp0': true,
                }}
              >
                {tabs.enabledTabs.map((tab) => {
                  return (
                    <Tabs.Trigger value={tab.value} key={tab.value}>
                      {tab.isLive && <Pulse color="$live" />}
                      {tab.label}
                    </Tabs.Trigger>
                  );
                })}
              </Tabs.List>
            </Body>
          </Tabs.Container>
          <Box>
            <Tabs.Content css={{ flexGrow: 1 }} value={homeTabValue}>
              <LandingTab
                worldId={worldId}
                isWorldTeamMember={isWorldTeamMember}
                initialWorldStats={initialWorldStats}
                worldStats={worldStats}
                viewCreators={() => showModalTab('creators')}
                viewDrops={() => viewDrops()}
                viewEditions={() => viewEditions()}
                viewListings={() => viewListings()}
                viewSales={() => viewSales()}
                world={world}
              />
            </Tabs.Content>
            <Tabs.Content css={{ flexGrow: 1 }} value={listingsTabValue}>
              <Body>
                <ListingsTab
                  listingsCount={worldStats.availableNftCount}
                  worldId={worldId}
                />
              </Body>
            </Tabs.Content>
            <Tabs.Content css={{ flexGrow: 1 }} value={momentsTabValue}>
              <Body>
                <MomentsTab
                  momentsCount={worldStats.momentCount}
                  world={world}
                />
              </Body>
            </Tabs.Content>

            <Tabs.Content css={{ flexGrow: 1 }} value={editionsTabValue}>
              <Body>
                <EditionsTab worldId={worldId} />
              </Body>
            </Tabs.Content>
            <Tabs.Content css={{ flexGrow: 1 }} value={dropsTabValue}>
              <Body>
                <DropsTab worldId={worldId} />
              </Body>
            </Tabs.Content>
            <Tabs.Content css={{ flexGrow: 1 }} value={salesTabValue}>
              <SalesTab
                worldId={worldId}
                world={world}
                worldStats={worldStats}
              />
            </Tabs.Content>
            <Tabs.Content css={{ flexGrow: 1 }} value={aboutTabValue}>
              <AboutTab description={description} world={world} />
            </Tabs.Content>
          </Box>
        </Tabs.Root>
      );
    }
    if (isWorldTeamMember) {
      return (
        <Body css={{ paddingY: '$6' }}>
          <ContentBlock.Empty>
            <Empty
              heading="Your gallery is waiting"
              subheading="Artists on your allowlist can now list NFTs or launch editions and drops in this gallery. Once something is added, it will automatically appear here."
              image={
                <Image
                  alt=""
                  src="/images/empty/nft@1x.png"
                  srcSet={`/images/empty/nft@2x 2x, /images/empty/nft@3x 3x`}
                />
              }
              actions={
                <Button
                  as="a"
                  // TODO (dashboard-launch): add a new link to the help center for selling in a world
                  href={helpCenterPaths.curatingOnFnd}
                  variant="primary"
                  size={1}
                  icon
                >
                  <span>Seller instructions</span>
                  <ExternalIcon />
                </Button>
              }
            />
          </ContentBlock.Empty>
        </Body>
      );
    } else {
      return (
        <Empty
          heading="There’s nothing here, yet"
          subheading="There are no NFTs in this gallery"
          icon="grid"
        />
      );
    }
  };

  return (
    <Page
      absolute
      headerMode="DARK"
      title={name}
      key={worldId}
      image={getMetaImage()}
    >
      {showModerationBanner && (
        <ModerationBanner
          status={moderationStatus}
          messages={getWorldModerationBannerText({
            isWorldTeamMember,
            moderationFrom,
          })}
        />
      )}
      {isCurrentUserAdmin && (
        <AdminToolsModal
          tools={[
            {
              id: 'moderate-world',
              name: 'Change moderation status',
              ui: (
                <ModerateWorldPane
                  world={world}
                  currentUserPublicKey={currentUserPublicKey}
                />
              ),
            },
          ]}
        />
      )}
      <ReportModal
        publicKey={currentUserPublicKey}
        reportedPublicKey={curator.publicKey}
        pageType="World"
      />
      <WorldHeroContainer
        coverImage={
          coverImage
            ? optimizeAsset(coverImage, {
                w: 2000,
                fit: 'fill',
                q: 70,
              })
            : null
        }
        worldId={worldId}
        slug={slug}
        curator={curator}
        tab={creatorsAllowlistModalState.tab}
        open={creatorsAllowlistModalState.open}
        showModalTab={showModalTab}
        onClose={() => dispatchCreatorsAllowlistModal({ type: 'close' })}
        takeRateInBasisPoints={takeRateInBasisPoints}
      >
        {logo && <WorldLogo size={8} imageUrl={logo} />}
        <Heading
          as="h1"
          css={{
            color: '$white100',
            marginTop: logo ? '$4' : 'unset',
            marginBottom: '$4',
            width: '100%',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
          }}
          size={{ '@initial': 6, '@bp0': 8, '@bp2': 9 }}
          lineHeight={0}
          weight="medium"
        >
          {world.name}
        </Heading>
        <Box>
          <DarkMode>
            <WorldStats
              onCreatorsClick={() => showModalTab('creators')}
              onCollectorsClick={() => showModalTab('collectors')}
              onSalesClick={viewSales}
              stats={worldStats}
            />
          </DarkMode>
        </Box>
      </WorldHeroContainer>
      <Box>{renderTabs()}</Box>
      <WorldManageShowcaseModal />
    </Page>
  );
}

type UseWorldTabsOptions = {
  worldStats: WorldStatsWithCreators;
};

type WorldTab = {
  value: WorldTabValue;
  enabled: boolean;
  label: React.ReactNode;
  isLive?: boolean;
  tooltipContent?: string | null;
};

type EnabledWorldTab = WorldTab & {
  enabled: true;
};

const getWorldTrackTabEvent = (
  tabName: WorldTabValue
): ViewedTabEvent<'world', WorldTabValue> => ({
  name: 'viewed_world_tab',
  tabName,
});

const getLiveTabCount = (count: number) => `${count} live`;

const useWorldTabs = (options: UseWorldTabsOptions) => {
  const { worldStats } = options;

  const analytics = useAnalytics();

  const {
    availableNftCount,
    liveAuctionCount,
    liveDropCount,
    liveEditionCount,
    editionCount,
    dropCount,
    soldNftCount,
    totalSales,
    momentCount,
  } = worldStats;

  const hasLiveAuctions = liveAuctionCount > 0;
  const hasLiveEditions = liveEditionCount > 0;
  const hasLiveDrops = liveDropCount > 0;

  const tabs: WorldTab[] = [
    {
      value: homeTabValue,
      enabled: !isEmptyState(worldStats),
      label: WORLD_TAB_LABELS.home,
    },
    {
      value: momentsTabValue,
      enabled: momentCount > 0,
      label: WORLD_TAB_LABELS.moments,
      isLive: false,
      tooltipContent: null,
    },
    {
      value: listingsTabValue,
      enabled: availableNftCount > 0 || liveAuctionCount > 0,
      label: WORLD_TAB_LABELS.listings,
      isLive: hasLiveAuctions,
      tooltipContent: hasLiveAuctions
        ? getLiveTabCount(liveAuctionCount)
        : null,
    },
    {
      value: editionsTabValue,
      enabled: editionCount !== 0,
      label: WORLD_TAB_LABELS.editions,
      isLive: hasLiveEditions,
      tooltipContent: hasLiveEditions
        ? getLiveTabCount(liveEditionCount)
        : null,
    },
    {
      value: dropsTabValue,
      enabled: dropCount !== 0,
      label: WORLD_TAB_LABELS.drops,
      isLive: hasLiveDrops,
      tooltipContent: hasLiveDrops ? getLiveTabCount(liveDropCount) : null,
    },
    {
      value: salesTabValue,
      enabled: soldNftCount > 0 || totalSales > 0,
      label: WORLD_TAB_LABELS.sales,
    },
    {
      value: aboutTabValue,
      enabled: true,
      label: WORLD_TAB_LABELS.about,
    },
  ];

  const enabledTabs = tabs.filter((tab): tab is EnabledWorldTab => tab.enabled);
  const enabledTabValues = enabledTabs.map((tab) => tab.value);

  // stringified to that it can be used as a useEffect dependency
  const stringifiedEnabledTabValues = enabledTabValues.join('');

  const [activeTabValue, setActiveTabValue] = useState<
    WorldTabValue | undefined
  >(() => enabledTabs[0]?.value);

  // if the active tab is no longer enabled, set the active tab to the first enabled tab
  useEffect(() => {
    if (activeTabValue && !enabledTabValues.includes(activeTabValue)) {
      setActiveTabValue(enabledTabs[0]?.value);
    }
  }, [stringifiedEnabledTabValues]);

  const setValue = (value: WorldTabValue) => {
    setActiveTabValue(value);

    analytics.track(getWorldTrackTabEvent(value));

    const deepLinkValue = deepLinkValueEnum.safeParse(value);
    if (deepLinkValue.success) {
      setDeepLinkUrlParams({ tab: deepLinkValue.data });
    }
  };

  return {
    value: activeTabValue,
    enabledTabs,
    enabledTabValues,
    stringifiedEnabledTabValues,
    setValue,
  };
};

type PageParams = {
  slug: string;
};

export async function getStaticPaths(): Promise<GetStaticPathsResult> {
  return {
    paths: [],
    fallback: 'blocking',
  };
}

export async function getStaticProps(
  context: GetStaticPropsContext<PageParams>
): Promise<GetStaticPropsResult<WorldPageProps>> {
  if (!context.params?.slug) {
    throw new Error('slug param must be set');
  }

  const slug = context.params.slug;

  const exhibitionQuery = await serverQuery(() => getWorldBySlug({ slug }));

  if (!exhibitionQuery.data) {
    return NOT_FOUND;
  }

  const exhibition = exhibitionQuery.data;

  const worldStats = await getWorldStats({
    id: exhibition.id,
  });

  return {
    props: {
      world: exhibition,
      worldStats,
    },
    revalidate: minutesToSeconds(10),
  };
}

const isEmptyState = (stats: WorldStatsWithCreators): boolean => {
  return (
    stats.availableNftCount === 0 &&
    stats.editionCount === 0 &&
    stats.soldNftCount === 0 &&
    stats.dropCount === 0
  );
};
