import { useQuery } from '@tanstack/react-query';

import * as Generated from 'gql/api/queries/world-modal-tab-amounts.generated';

type WorldModalTabAmounts = {
  allowlistCount: number;
  creatorsCount: number;
  collectorsCount: number;
};

type Variables = Generated.ApiWorldModalTabAmountsVariables;

async function getWorldModalTabAmounts(
  variables: Variables
): Promise<WorldModalTabAmounts> {
  const fetchWorldModalTabAmounts =
    Generated.useWorldModalTabAmounts.fetcher(variables);
  const response = await fetchWorldModalTabAmounts();

  return {
    allowlistCount: response.allowlistCount.totalItems,
    collectorsCount: response.collectorsCount.totalItems,
    creatorsCount: response.creatorsCount.totalItems,
  };
}

export default function useWorldModalTabAmounts(variables: Variables) {
  return useQuery({
    queryKey: ['world', 'modalTabCounts', variables],
    queryFn: () => getWorldModalTabAmounts(variables),
  });
}
