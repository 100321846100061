import { styled } from '@f8n-frontend/stitches';
import { match } from 'ts-pattern';

import FrameGrid from 'components/FrameGrid';
import NftGridItem from 'components/NftGridItem';
import WorldGridSection from 'components/WorldGridSection';
import Body from 'components/base/Body';
import Box from 'components/base/Box';
import Button from 'components/base/Button';
import { H2Heading } from 'components/base/Heading';
import Skeleton from 'components/base/Skeleton';
import Leaderboard, {
  LeaderboardCollection,
} from 'components/collections/Leaderboard';
import InfiniteScrollButton from 'components/feed/InfiniteScrollButton';

import { ApiMediaFragment } from 'gql/api/api-fragments.generated';
import {
  ApiWorldLaunchCollections,
  useInfiniteWorldLaunchCollections,
} from 'gql/api/queries/world-launch-collections.generated';
import { useInfiniteWorldSoldNfts } from 'gql/api/queries/world-sold-nfts.generated';
import { formatETHWithSuffix, formatNftCount } from 'utils/formatters';
import { mapApiMediaToPreviewMediaAsset } from 'utils/media-preview';
import { extractNestedPaginatedData, apiPaginator } from 'utils/react-query';

import { World, WorldStatsWithCreators } from 'types/World';

import SalesStatsSection from './SalesStatsSection';

interface SalesTabProps {
  worldId: number;
  worldStats: WorldStatsWithCreators;
  world: World;
}

export default function SalesTab(props: SalesTabProps) {
  const { worldId, worldStats } = props;
  const { mintCount, soldListingCount } = worldStats;

  const { data, isLoading, hasNextPage, fetchNextPage, isFetching } =
    useInfiniteWorldSoldNfts(
      { id: worldId },
      {
        getNextPageParam: (lastPage) => {
          return apiPaginator.getNextPageParam(lastPage.worldSoldNfts);
        },
        initialPageParam: apiPaginator.initialPageParam,
      }
    );

  const { items: soldNfts } = extractNestedPaginatedData(data, 'worldSoldNfts');

  return (
    <Box>
      <Body
        css={{
          marginTop: '$6',
          '@bp1': {
            marginTop: '$8',
          },
        }}
      >
        <SalesStatsSection worldStats={worldStats} />
      </Body>
      <MintsSectionGuard worldId={worldId} mintCount={mintCount} />
      {soldListingCount > 0 && (
        <WorldGridSection liveCount={0} heading="Sold NFTs">
          <FrameGrid.Root maxColumns={5} css={{ maxWidth: '$container' }}>
            {isLoading
              ? Array.from({ length: 5 }).map((_, i) => (
                  <NftGridItem.Skeleton key={i} />
                ))
              : soldNfts.map((nft) => {
                  const mediaAsset = mapApiMediaToPreviewMediaAsset(nft.media);

                  const media = mediaAsset
                    ? NftGridItem.optimizeMedia(mediaAsset, {
                        maxColumns: 5,
                      })
                    : null;

                  return (
                    <NftGridItem.Sold
                      key={`${nft.contractAddress}-${nft.tokenId}`}
                      nft={nft}
                      media={media}
                    />
                  );
                })}
          </FrameGrid.Root>
        </WorldGridSection>
      )}
      <InfiniteScrollButton
        handleNextPage={fetchNextPage}
        isFetching={isFetching}
        hasNextPage={hasNextPage}
      />
    </Box>
  );
}

type MintsSectionGuardProps = {
  worldId: number;
  mintCount: number;
};

function MintsSectionGuard(props: MintsSectionGuardProps) {
  const { worldId, mintCount } = props;

  const query = useInfiniteWorldLaunchCollections(
    {
      worldId,
      perPage: 5,
    },
    {
      getNextPageParam: (lastPage) => {
        return apiPaginator.getNextPageParam(
          lastPage.exhibitionLaunchCollections
        );
      },
      initialPageParam: apiPaginator.initialPageParam,
    }
  );

  // TODO: add loading state, based on worldStat

  if (query.isSuccess) {
    const items = query.data.pages.flatMap(
      (page) => page.exhibitionLaunchCollections.items
    );

    if (items.length === 0) {
      return null;
    }

    return (
      <MintsSectionContainer>
        <Body>
          <MintsSection
            items={items}
            hasMoreItems={query.hasNextPage || false}
            onLoadMore={() => query.fetchNextPage()}
          />
        </Body>
      </MintsSectionContainer>
    );
  }

  return (
    <MintsSectionContainer>
      <Body>
        <MintsSectionSkeleton rowCount={mintCount} />
      </Body>
    </MintsSectionContainer>
  );
}

type MintsSectionProps = {
  items: ApiWorldLaunchCollections['exhibitionLaunchCollections']['items'];
  hasMoreItems: boolean;
  onLoadMore: () => void;
};

function MintsSection(props: MintsSectionProps) {
  return (
    <MintsSectionContent>
      <MintsSectionHeading />
      <MintsSectionLeaderboardTable items={props.items} />
      {props.hasMoreItems && (
        <Button
          css={{ margin: '0 auto', width: 'max-content' }}
          onClick={() => props.onLoadMore()}
        >
          Show more
        </Button>
      )}
    </MintsSectionContent>
  );
}

type MintsSectionSkeletonProps = {
  rowCount: number;
};

function MintsSectionSkeleton(props: MintsSectionSkeletonProps) {
  return (
    <MintsSectionContent>
      <Skeleton.Text>
        <MintsSectionHeading />
      </Skeleton.Text>
      <Leaderboard.Skeleton rowCount={Math.min(props.rowCount, 5)} />
    </MintsSectionContent>
  );
}

const MintsSectionContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
});

const MintsSectionContainer = styled('div', {
  borderBottom: '1px solid $black5',
  paddingY: '$7',

  '@bp1': {
    paddingY: '$8',
  },
});

function MintsSectionHeading() {
  return (
    <H2Heading size={5} weight="medium">
      Mints
    </H2Heading>
  );
}

type MintsSectionLeaderboardTableProps = {
  items: MintsSectionProps['items'];
};

function MintsSectionLeaderboardTable(
  props: MintsSectionLeaderboardTableProps
) {
  return (
    <Leaderboard.Table>
      {props.items.map((item, index) => {
        return (
          <Leaderboard.Item
            key={index}
            ranking={index + 1}
            collection={mapApiWorldLaunchCollectionToLeaderboardItem(item)}
            values={{
              primary: item.totalVolumeInEth
                ? formatETHWithSuffix(item.totalVolumeInEth)
                : '',
              secondary: formatNftCount(item.nftCount),
            }}
          />
        );
      })}
    </Leaderboard.Table>
  );
}

function getStaticMediaUrl(media: ApiMediaFragment): string {
  return match(media)
    .with({ __typename: 'ImageMedia' }, (image) => image.url)
    .with({ __typename: 'VideoMedia' }, (video) => video.staticUrl || '')
    .with({ __typename: 'ModelMedia' }, (model) => model.modelStaticUrl)
    .exhaustive();
}

function mapApiWorldLaunchCollectionToLeaderboardItem(
  item: MintsSectionProps['items'][number]
): LeaderboardCollection {
  const assetUrl = item.media ? getStaticMediaUrl(item.media) : null;

  return {
    assetUrl,
    chainId: item.chainId,
    contractAddress: item.contractAddress,
    creator: item.creator,
    name: item.name,
  };
}
