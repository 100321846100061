import { PRODUCT_BRANDING } from './branding';

export const WORLD_TAB_LABELS = {
  home: 'Home',
  moments: PRODUCT_BRANDING.moment.plural,
  drops: PRODUCT_BRANDING.drop.plural,
  editions: PRODUCT_BRANDING.editionCollection.plural,
  nfts: PRODUCT_BRANDING.nft.plural,
  listings: 'Listings',
  sales: 'Sales',
  about: 'About',
} as const;

export const LAUNCH_WITH = {
  HEADING: 'Launch with a curator',
  DESCRIPTION_MINTABLE:
    'Choose which gallery or exhibition you’d like to sell in. A curator fee will be charged on all mints.',
} as const;
