import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { ApiEditionStatus } from 'gql/api/types-api.generated';

const INITIAL_EDITION_FILTERS = {
  creatorPublicKey: null,
  status: null,
};

type EditionFiltersApi = {
  filters: EditionFilters;
  setFilters(filters: Partial<EditionFilters>): void;
};

type EditionFilters = {
  creatorPublicKey: string | null;
  status: ApiEditionStatus | null;
};

export function useEditionFilters(
  id: string,
  options?: UseQueryOptions<EditionFilters, Error>
): EditionFiltersApi {
  const queryClient = useQueryClient();
  const cacheKey = useEditionFilters.getKey(id);

  const { data: filters = INITIAL_EDITION_FILTERS } = useQuery<
    EditionFilters,
    Error
  >({
    ...options,
    enabled: false,
    initialData: INITIAL_EDITION_FILTERS,
    queryKey: cacheKey,
  });

  const setFilters: EditionFiltersApi['setFilters'] = (nextState) => {
    queryClient.setQueryData<EditionFilters>(
      cacheKey,
      (prevState: EditionFilters) => {
        const filters = { ...prevState, ...nextState };

        let status = prevState.status;
        if (nextState.status === undefined) {
          status = prevState.status;
        } else if (
          nextState.status === null ||
          nextState.status === prevState.status
        ) {
          status = null;
        } else {
          status = nextState.status;
        }

        return {
          ...filters,
          status,
        };
      }
    );
  };

  return {
    filters,
    setFilters,
  };
}

useEditionFilters.getKey = (id: string) => ['EditionFilters', id];
