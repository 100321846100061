import { styled } from '@f8n-frontend/stitches';
import { allPass, always, cond, prop, propEq } from 'ramda';
import { useCallback, useState } from 'react';
import Toggle from 'react-toggle';
import { useLocation } from 'react-use';
import type { Address } from 'viem';

import Icon from 'components/Icon';
import Box from 'components/base/Box';
import Button from 'components/base/Button';
import Flex from 'components/base/Flex';
import Link from 'components/base/Link';
import Modal from 'components/base/Modal';
import Text from 'components/base/Text';
import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import ClipboardCheckIcon from 'assets/icons/clipboard-check.svg';
import ClipboardIcon from 'assets/icons/clipboard-icon.svg';
import TwitterIcon from 'assets/icons/twitter.svg';
import UploadIcon from 'assets/icons/upload-icon.svg';
import useSegmentEvent from 'hooks/analytics/use-segment-event';
import { useSocialVerificationByService } from 'hooks/queries/hasura/social-verification/use-social-verification';
import useCopyText from 'hooks/use-copy-text';
import { ChainId } from 'lib/chains';
import { buildArtworkPath } from 'utils/artwork/artwork';
import { buildCollectionPath } from 'utils/collections';
import {
  isValueInArray,
  maybeAddEllipsis,
  truncateStringCenter,
} from 'utils/helpers';
import { getPath, helpCenterPaths } from 'utils/router';
import { getUserNameOrAddressAndStripAtSymbol } from 'utils/strings';
import { getShareOnTwitterUrl } from 'utils/twitter';
import {
  appendHandle,
  buildArtworkTweet,
  buildCollectionTweet,
  buildWorldTweet,
} from 'utils/twitter-templates';

import { UserLight } from 'types/Account';

type ShareModalProps = {
  creator: UserLight;
  trigger?: React.ReactNode;
} & (
  | {
      shareType: 'artwork';
      artwork: {
        contractAddress: Address;
        tokenId: number;
      };
      chainId: ChainId;
    }
  | {
      shareType: 'collection';
      collection: {
        contractAddress: Address;
      };
      chainId: ChainId;
    }
  | {
      shareType: 'world';
      slug: string;
    }
);

type ShareEventPayload = {
  pageType: 'artwork' | 'collection' | 'world';
  contractAddress?: string;
  tokenId?: number;
  slug?: string;
};

const getEventPayload = (props: ShareModalProps): ShareEventPayload => {
  switch (props.shareType) {
    case 'artwork': {
      return {
        pageType: props.shareType,
        contractAddress: props.artwork.contractAddress,
        tokenId: props.artwork.tokenId,
      };
    }

    case 'collection': {
      return {
        pageType: props.shareType,
        contractAddress: props.collection.contractAddress,
      };
    }

    case 'world': {
      return {
        pageType: props.shareType,
        slug: props.slug,
      };
    }
  }
};

/** @deprecated in favor of modals triggered via modal store */
export default function ShareModal(props: ShareModalProps) {
  const sendSegmentEvent = useSegmentEvent();

  const isShareTypeWithReferralSupport = isValueInArray(props.shareType, [
    'artwork',
    'collection',
  ]);
  const hasReferralOption = isShareTypeWithReferralSupport;

  const handleOpenClick = () => {
    sendSegmentEvent<ShareEventPayload>({
      eventName: 'share_button_clicked',
      payload: getEventPayload(props),
    });
  };

  const defaultTrigger = (
    <Button
      variant="raised"
      icon
      size={{ '@initial': 1, '@bp1': 2 }}
      css={{ alignSelf: 'stretch', height: 'auto', paddingY: '$1' }}
    >
      <UploadIcon />
      <Box>
        <Text weight="semibold" css={{ textAlign: 'left' }}>
          Share
        </Text>
        {hasReferralOption && (
          <Text
            size={0}
            color="dim"
            css={{
              display: 'none',
              textAlign: 'left',
              '@bp1': {
                display: 'block',
              },
            }}
          >
            Earn 1%
          </Text>
        )}
      </Box>
    </Button>
  );

  return (
    <Modal.Root
      onOpenChange={(isOpen) => {
        if (isOpen) {
          handleOpenClick();
        }
      }}
    >
      <Modal.Trigger asChild>{props.trigger || defaultTrigger}</Modal.Trigger>
      <ShareBody {...props} hasReferralOption={hasReferralOption} />
    </Modal.Root>
  );
}

function ShareBody(props: ShareModalProps & { hasReferralOption: boolean }) {
  const { creator, hasReferralOption, shareType } = props;
  const sendSegmentEvent = useSegmentEvent();

  const auth = useAuth();
  const currentUserPublicKey = hasPublicKey(auth) ? auth.publicKey : '';

  const [hasReferral, setHasReferral] = useState(() =>
    shareType === 'world' ? false : Boolean(currentUserPublicKey)
  );
  const location = useLocation();
  const { hasCopied, handleCopy } = useCopyText();
  const isDisconnected = !currentUserPublicKey;

  const { refetch: refetchSocialVerification } = useSocialVerificationByService(
    { publicKey: creator?.publicKey, service: 'TWITTER' },
    { enabled: false }
  );

  const creatorName = getUserNameOrAddressAndStripAtSymbol(creator);

  // this intentionally removes existing query params from the url
  const pageUrl = `${location.origin}${location.pathname}`;

  const rawRefParam = `?ref=${currentUserPublicKey}`;
  const rawShareUrl =
    hasReferral && currentUserPublicKey ? `${pageUrl}${rawRefParam}` : pageUrl;

  const truncatedUrl = maybeAddEllipsis(46, pageUrl);
  const truncatedRefAddress = truncateStringCenter(4, currentUserPublicKey);
  const shareUrl =
    hasReferral && currentUserPublicKey
      ? `${truncatedUrl}?ref=${truncatedRefAddress}`
      : pageUrl;

  const onCopy = () => {
    handleCopy(rawShareUrl);
    sendSegmentEvent<ShareEventPayload>({
      eventName: 'share_url_clicked',
      payload: getEventPayload(props),
    });
  };

  const openTweetLink = useCallback(async () => {
    const res = await refetchSocialVerification();
    const socialVerificationData = res?.data;

    const twitterHandle = appendHandle(socialVerificationData?.username);

    if (shareType === 'artwork') {
      const { artwork } = props;
      const artworkPath = buildArtworkPath({
        artwork: { ...artwork, id: artwork?.tokenId.toString() },
        user: creator,
      });
      const sharePath = hasReferral
        ? [artworkPath, rawRefParam].join('')
        : artworkPath;

      const shareOnTwitterUrl = getShareOnTwitterUrl({
        post: buildArtworkTweet({
          creatorName,
          artworkPath: sharePath,
          twitterUsername: twitterHandle,
        }),
      });

      window.open(shareOnTwitterUrl);
    }

    if (shareType === 'world') {
      const { slug } = props;
      const worldPath = getPath.world.page(slug);

      const shareOnTwitterUrl = getShareOnTwitterUrl({
        post: buildWorldTweet({
          creatorName: twitterHandle || creatorName,
          worldPath,
        }),
      });

      window.open(shareOnTwitterUrl);
    }

    if (shareType === 'collection') {
      const { collection } = props;
      const collectionPath = hasReferral
        ? `${buildCollectionPath(collection)}${rawRefParam}`
        : buildCollectionPath(collection);

      const shareOnTwitterUrl = getShareOnTwitterUrl({
        post: buildCollectionTweet({
          creatorName: twitterHandle || creatorName,
          collectionPath: collectionPath,
        }),
      });

      window.open(shareOnTwitterUrl);
    }
    sendSegmentEvent<ShareEventPayload>({
      eventName: 'share_twitter_clicked',
      payload: getEventPayload(props),
    });
  }, [
    refetchSocialVerification,
    creator,
    creatorName,
    shareType,
    hasReferral,
    rawRefParam,
    sendSegmentEvent,
    props,
  ]);

  const modalCopy = cond([
    [
      allPass([propEq('shareType', 'artwork'), prop('isDisconnected')]),
      always(
        'Connect your wallet to generate a Referral Rewards link. Earn 1% of the sale if the NFT is sold via your link.'
      ),
    ],
    [
      allPass([propEq('shareType', 'artwork')]),
      always(
        'If the NFT is sold via your Referral Rewards link, you will earn 1% of the sale.'
      ),
    ],
    [
      allPass([propEq('shareType', 'collection'), prop('isDisconnected')]),
      always(
        'Connect your wallet to generate a Referral Rewards link. Earn 1% of the sale each time any NFT from the collection is sold via your link.'
      ),
    ],
    [
      allPass([propEq('shareType', 'collection')]),
      always(
        'If any NFT from the collection is sold via your Referral Rewards link, you will earn 1% of each sale.'
      ),
    ],
  ])({
    shareType,
    isDisconnected,
  });

  return (
    <Modal.Content
      footer={
        <Modal.Footer>
          <Button variant="primary" icon onClick={openTweetLink}>
            <TwitterIcon />
            Share
          </Button>
        </Modal.Footer>
      }
    >
      <Box css={{ marginBottom: '$6' }}>
        <Modal.BodyTitle title="Share" />
      </Box>
      <CopyField onClick={onCopy}>
        <CopyFieldText>{shareUrl}</CopyFieldText>
        <IconWrapper>
          <Icon
            icon={hasCopied ? ClipboardCheckIcon : ClipboardIcon}
            width={16}
            height={16}
          />
        </IconWrapper>
      </CopyField>
      {hasReferralOption && (
        <ToggleBox>
          <ToggleContainer>
            <BadgeText>
              <Badge isDisconnected={isDisconnected} />
              <Text size={{ '@initial': 2, '@bp2': 3 }} weight="semibold">
                Referral Rewards
              </Text>
            </BadgeText>
            <Toggle
              disabled={isDisconnected}
              checked={hasReferral}
              onChange={(e) => setHasReferral(e.target.checked)}
            />
          </ToggleContainer>
          <ShareDetails>
            {modalCopy}{' '}
            <Link
              variant="primary"
              href={helpCenterPaths.referralsFaq}
              target="_blank"
              rel="noreferrer"
              hasUnderline
            >
              Learn more
            </Link>
          </ShareDetails>
        </ToggleBox>
      )}
    </Modal.Content>
  );
}

interface BadgeProps {
  isDisconnected: boolean;
}

function Badge(props: BadgeProps) {
  const { isDisconnected } = props;

  if (isDisconnected) {
    return (
      <BadgeContainer css={{ paddingLeft: '5px', paddingRight: '10px' }}>
        <Flex css={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <BadgeDot />
          <Text weight="semibold">Not connected</Text>
        </Flex>
      </BadgeContainer>
    );
  }

  return (
    <BadgeContainer>
      <Text weight="semibold">Earn 1%</Text>
    </BadgeContainer>
  );
}

const ToggleContainer = styled(Flex, {
  alignItems: 'flex-start',
  justifyContent: 'space-between',
});

const ToggleBox = styled('div', {
  marginTop: '$5',
  border: '1px solid $black10',
  borderRadius: '$3',
  padding: '$4',
  '@bp2': {
    padding: '$5',
  },
});

const BadgeText = styled(Flex, {
  flexDirection: 'column',
  alignItems: 'flex-start',
  '@bp2': {
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
});

const BadgeContainer = styled('div', {
  border: '1px solid $black10',
  borderRadius: '$round',
  paddingY: '$1',
  paddingX: '10px',
  fontSize: '11px',
  marginBottom: '$3',
  '@bp2': {
    fontSize: '$0',
    paddingX: '$3',
    marginBottom: 0,
    marginLeft: '$2',
  },
});

const BadgeDot = styled('div', {
  height: 12,
  width: 12,
  backgroundColor: '$red3',
  borderRadius: '$round',
  marginRight: '5px',
});

const IconWrapper = styled('div', {
  marginLeft: 'auto',
  color: '$black30',
  transition: 'color $1 $ease',
});

const CopyField = styled(Flex, {
  cursor: 'pointer',
  backgroundColor: '$black5',
  borderRadius: '$3',
  paddingY: '$5',
  paddingX: '$4',
  alignItems: 'center',
  userSelect: 'none',
  transition: 'background-color $1 $ease',

  '&:hover': {
    backgroundColor: '$black10',
    [`${IconWrapper}`]: {
      color: '$black100',
    },
  },
});

const CopyFieldText = styled(Text, {
  fontWeight: '$medium',
  fontSize: '$1',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const ShareDetails = styled(Text, {
  marginTop: '$1',
  '@bp2': {
    maxWidth: 360,
  },

  defaultVariants: {
    lineHeight: 3,
  },
});

ShareDetails.defaultProps = {
  size: { '@initial': 1, '@bp2': 2 },
};
