import { styled } from '@f8n-frontend/stitches';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { ComponentProps } from '@stitches/react';

import { checkboxAndRadioCss } from 'css/checkboxRadio';

const StyledRadio = styled(RadioGroupPrimitive.Item, {
  ...checkboxAndRadioCss,
  borderRadius: '$round',
});

const StyledIndicator = styled(RadioGroupPrimitive.Indicator, {
  width: '40%',
  height: '40%',
  backgroundColor: '$white100',
  borderRadius: '$round',
});

type RadioProps = ComponentProps<typeof StyledRadio>;

function Radio(props: RadioProps & { name?: string }) {
  return (
    <StyledRadio {...props}>
      <StyledIndicator />
    </StyledRadio>
  );
}

const RadioGroup = {
  Root: RadioGroupPrimitive.Root,
  Radio,
};

export default RadioGroup;
