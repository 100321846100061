import { ExternalIcon } from '@f8n/icons';

import AvatarText from 'components/base/AvatarText';
import Box from 'components/base/Box';
import Button from 'components/base/Button';
import Flex from 'components/base/Flex';
import Text from 'components/base/Text';

import { getContractAddress } from 'lib/addresses';
import { replaceIpfsPath } from 'utils/assets';
import { getBlockExplorerByChainId } from 'utils/block-explorer';

import { WorldOverview } from 'types/World';

import WorldTag from './WorldTag';

type Nft = {
  image: string;
  name: string;
};

export default function WorldNft(props: {
  type: 'owner' | 'world';
  nft: Nft;
  world: WorldOverview;
}) {
  const parsedNft = props.nft;
  const world = props.world;

  const explorer = getBlockExplorerByChainId(world.chainId);

  return (
    <Box css={{ minWidth: 0 }}>
      <Box
        as="img"
        src={replaceIpfsPath(parsedNft.image)}
        alt={parsedNft.name}
        css={{ width: '100%', display: 'block', borderRadius: '$2' }}
      />
      <Flex
        css={{
          paddingTop: '$4',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
          gap: '$4',
        }}
      >
        <Box css={{ minWidth: 0 }}>
          {props.type === 'owner' ? (
            <>
              <Text
                size={1}
                weight="regular"
                css={{ color: '$black60', marginBottom: '$1' }}
              >
                Owned by
              </Text>
              <AvatarText.User
                user={world.curator}
                size={2}
                variant="prefer-username"
              />
            </>
          ) : (
            <WorldTag world={world} type="link" />
          )}
        </Box>
        <Button
          css={{ textWrap: 'nowrap' }}
          as="a"
          href={explorer.nft.getUrl({
            contractAddress: getContractAddress({
              chainId: world.chainId,
              contractName: 'worlds',
            }),
            tokenId: world.id,
          })}
          rel="noreferrer"
          target="_blank"
          size={0}
          icon
        >
          <span>{explorer.nft.copy.viewOnCta}</span>
          <ExternalIcon />
        </Button>
      </Flex>
    </Box>
  );
}
